import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, first, map } from 'rxjs/operators';
import { UserService } from '../user.service';
import { IUser } from './models/IUser';
import { IUserSession } from './models/IUserSession';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn: BehaviorSubject<boolean>;
  private userSession: IUserSession;

  constructor(private http: HttpClient, private userService: UserService) {
    const initialSession = this.getUserSession();
    this.isLoggedIn = new BehaviorSubject(initialSession.isLoggedIn);
    this.triggerRoleChange();
  }

  signIn(username: string, password: string): Observable<{ status?: boolean, userSession?: IUserSession; code?: string }> {
    return this.http.post<{ status?: boolean, userDetails?: IUser; code?: string }>("login", { username: username, password: password })
    //return this.http.post<{ status?: boolean, userDetails?: IUser; code?: string }>("/api/login", { username: username, password: password })
      .pipe(map((response) => {
        if (response.status === true) {
          this.userSession = {
            isLoggedIn: true,
            userDetails: response.userDetails
          }
          sessionStorage.setItem("userSession", JSON.stringify(this.userSession));
          return { userSession: this.userSession };
        } else {
          throw { status: false, code: "INVALID_CREDENTIALS" };
        }
      }),
        catchError(exception => {
          console.warn(exception);
          throw exception.error || { status: false, code: "SYSTEM_ERROR" };
        }));
  }

  signOut(): boolean {
    sessionStorage.removeItem("userSession");
    this.userSession = {
      isLoggedIn: false
    }
    this.isLoggedIn.next(false);
    this.triggerRoleChange();
    return true;
  }

  getUserSession(): IUserSession {
    if (!this.userSession) {
      this.userSession = this.getUserSessionFromStorage();
    }
    return this.userSession;
  }

  getUserSessionFromStorage(): IUserSession {
    const userSession = sessionStorage.getItem("userSession");
    if (userSession) {
      return JSON.parse(userSession);
    }
    return {
      isLoggedIn: false
    }
  }

  triggerRoleChange(): void {
    const role = this.userSession.isLoggedIn === true ? this.userSession.userDetails.role : this.userService.defaultRole;   // Default role is set to customer
    this.userService.changeRole(role);
  }

}
