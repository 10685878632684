import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule, FORMLY_CONFIG  } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PanelWrapperComponent } from './panel-wrapper/panel-wrapper.component';
import { AgGridModule } from 'ag-grid-angular';
import { UserInputComponent } from './user-input/user-input.component';
import { ProductResultComponent } from './product-result/product-result.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { PricesComponent } from './prices/prices.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartModule } from 'angular-highcharts';
import { OverviewChartComponent } from './overview-chart/overview-chart.component';
import { TranslateModule, TranslateLoader, TranslateService  } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerTranslateExtension } from './translate.extension';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SystemToImagePathPipe } from './system-to-image-path.pipe';
import { FadingImageComponent } from './fading-image/fading-image.component';
import { SubSystemSelectionComponent } from './sub-system-selection/sub-system-selection.component';
import { ResultViewComponent } from './result-view/result-view.component';
import { AdminFactorsComponent } from './admin-factors/admin-factors.component';
import { TooltipWrapperComponent } from './tooltip-wrapper/tooltip-wrapper.component';
import { LoginComponent } from './login/login.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ClipboardModule } from 'ngx-clipboard';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  //return new TranslateHttpLoader(http);
}

registerLocaleData(localeDe);
@NgModule({
  declarations: [
    AppComponent,
    PanelWrapperComponent,
    UserInputComponent,
    ProductResultComponent,
    PricesComponent,
    OverviewChartComponent,
    SystemToImagePathPipe,
    FadingImageComponent,
    SubSystemSelectionComponent,
    ResultViewComponent,
    AdminFactorsComponent,
    TooltipWrapperComponent,
    LoginComponent,
    SignInComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ClipboardModule,
    FormlyModule.forRoot({
      wrappers: [
        { name: 'panel', component: PanelWrapperComponent },
        { name: 'bootstrapLabel', component: TooltipWrapperComponent}
      ],

      //validationMessages: [
      //  {name: "required", message: "Bitte einen gültigen Wert eintragen"}
      //  ]
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage : 'en'
    }),
    ModalModule.forRoot(),
    FormlyBootstrapModule,
    NgbModule,
    HttpClientModule,
    NgSelectModule,
    ChartModule,
    AgGridModule.withComponents([])
  ],
  //providers: [{ provide: FORMLY_CONFIG, multi: true, useFactory: registerTranslateExtension, deps: [TranslateService] }, { provide: LOCALE_ID, useValue: 'de-DE' }],
  providers: [{ provide: FORMLY_CONFIG, multi: true, useFactory: registerTranslateExtension, deps: [TranslateService] }, ],
  bootstrap: [AppComponent]
})
export class AppModule { }
