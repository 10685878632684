import { Component, OnInit } from '@angular/core';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { AdminFactorsService } from '../admin-factors.service';
import { AdminFactors } from '../calculationModel/adminFactors';


@Component({
  selector: 'app-admin-factors',
  templateUrl: './admin-factors.component.html',
  styleUrls: ['./admin-factors.component.css']
})
export class AdminFactorsComponent implements OnInit {

  options: FormlyFormOptions = {};

  adminFactors:AdminFactors;

  adminFactorFields: FormlyFieldConfig[] = [
    {
      key: 'lowerOrEqual3',
      type: 'input',
      modelOptions: {
        updateOn: 'blur',
      },
      templateOptions: {
        label: '<= 3m',
        type: 'number',
        required: true,
      },
    },
    {
      key: 'lowerOrEqual6',
      type: 'input',
      modelOptions: {
        updateOn: 'blur',
      },
      templateOptions: {
        label: '<= 6m',
        type: 'number',
        required: true,
      },
    },
    {
      key: 'lowerOrEqual10',
      type: 'input',
      modelOptions: {
        updateOn: 'blur',
      },
      templateOptions: {
        label: '<= 10m',
        type: 'number',
        required: true,
      },
    },
    ];


  constructor(private adminFactorsService:AdminFactorsService) {
    this.adminFactors = new AdminFactors();

    this.adminFactorsService.currentAdminFactors.subscribe(adminFactors =>{

      this.adminFactors.lowerOrEqual10 = adminFactors.lowerOrEqual10;
      this.adminFactors.lowerOrEqual6 = adminFactors.lowerOrEqual6;
      this.adminFactors.lowerOrEqual3 = adminFactors.lowerOrEqual3;
    })
  }

  onAdminFactorsChange(): void {

    const tempAdminFactors = new AdminFactors();

    if (this.adminFactors.lowerOrEqual3) {
      tempAdminFactors.lowerOrEqual3 = this.adminFactors.lowerOrEqual3;
    }

    if (this.adminFactors.lowerOrEqual6) {
      tempAdminFactors.lowerOrEqual6 = this.adminFactors.lowerOrEqual6;
    }

    if (this.adminFactors.lowerOrEqual10) {
      tempAdminFactors.lowerOrEqual10 = this.adminFactors.lowerOrEqual10;
    }

    if(!isNaN(tempAdminFactors.lowerOrEqual10) && !isNaN(tempAdminFactors.lowerOrEqual6) && !isNaN(tempAdminFactors.lowerOrEqual3)){
      this.adminFactorsService.updateAdminFactors(tempAdminFactors)
    }
  }

  ngOnInit(): void {

  }

}
