import { Prices } from "./prices";
import { IPriceTableLine } from "./IPriceTableLine";
import { InputModel } from "./inputModel";
import { IProductPricedetails, IProductPricedetails as IProductPricedetails1 } from "./IProductPricedetails";
import { Product, PricePositionLabel, GabionSystemModel, GabionConstructionMethod, GabionSubsystem } from "./calculationEnums";
import { IPricePosition } from "./IPricePosition";

export class FortracGabionCalculator {
  constructor(private frontArea,
    private inputModel: InputModel,
    private geoGridsSummary: Array<IPriceTableLine>,
    private prices: Prices) {


  }

  getSteelElementsLine():IPricePosition {
    if(this.inputModel.systemModelGabion !== GabionSystemModel.TieRodSystem){
      return null;
    }

    const returnValue = {
      description: this.inputModel.subsystemGabion,
      position: PricePositionLabel.SteelElements,
      quantity: 1,
      price: this.inputModel.subsystemGabion === GabionSubsystem.T1Dach ? this.prices.gabionAnchor.Type1Dach : this.prices.gabionAnchor.Type2EMEEA
    } as IPricePosition;

    return returnValue;
  }

  getGabionBasketLine() : IPricePosition{
    if(this.inputModel.systemModelGabion !== GabionSystemModel.FullGabionFacing){
      return null;
    }

    const returnValue = {
      description: "-",
      position: PricePositionLabel.GabionBasket,
      quantity: 1,
      price: this.prices.gabion._200x100x100
    } as IPricePosition;

    return returnValue;
  };

  getErosionProtection():IPricePosition{
    const quantity = 0.4 + this.inputModel.metricInputValues.verticalLayerDistance;

    const returnValue = {
      description:"Non woven GRK5",
      position: PricePositionLabel.ErosionProtection,
      quantity: quantity,
      price: quantity * this.prices.trickleProtection.NonWovenFabric
    } as IPricePosition;

    return returnValue;
  }

  getSteelLine(): IPricePosition {
    if (this.inputModel.systemModelGabion === GabionSystemModel.FullGabionFacing){
      return null;
    }

    if(this.inputModel.systemModelGabion === GabionSystemModel.TieRodSystem && this.inputModel.constructionMethodGabion === GabionConstructionMethod.WithoutIntegratedFormwork){
      return null;
    }

    const isGalvanized = this.inputModel.systemModelGabion === GabionSystemModel.HalfGabionFacing;

    let price = 0;

    if(isGalvanized){
      price = this.prices.steelLattice.galvanized;
    }else{
      price = this.inputModel.metricInputValues.verticalLayerDistance === 0.5 ? this.prices.steelLattice.steel05 : this.prices.steelLattice.steel06;
    }

    const returnValue = {
      description: isGalvanized ? "Galvanized" : "not galvanized",
      quantity: 1,
      position: PricePositionLabel.Steel,
      price:price
    } as IPricePosition;

    return returnValue;
  }

  getGeoGridLine(): IPricePosition {
    const quantity = this.geoGridsSummary.reduce((sum, current) => sum + current.wrapAroundPlus6Perc, 0) /
      this.frontArea;

    const price = this.geoGridsSummary.reduce((sum, current) => sum + current.wrapAroundTotal, 0) / this.frontArea;

    const returnValue = {
      quantity: quantity,
      description: "Wraparound",
      price: price,
      position: PricePositionLabel.Geogrid
    } as IPricePosition;

    return returnValue;
  }

  calculate(): IProductPricedetails {
    const geogrid = this.getGeoGridLine();
    const steel = this.getSteelLine();
    const erosionProtection = this.getErosionProtection();
    const gabionBasket = this.getGabionBasketLine();
    const steelElements = this.getSteelElementsLine();

    const positions = [geogrid, steel, erosionProtection, gabionBasket, steelElements].filter(x => x !== null);

    const details = {
      product: Product.Gabion,
      isValid: true,
      positions: positions,
      sumPrice: positions.reduce((sum, current)=> sum + current.price, 0)
    } as IProductPricedetails1;

    return details;
  }

}
