import { IConfiguration } from "./configuration";
import { InputModel } from "./inputModel";
import { GabionSystemModel, BlockOptions, NatureSeedingMethod, GabionConstructionMethod, GabionSubsystem, NatureSystemModel, Product
} from "./calculationEnums";
import { UserRoles } from "../user-roles.config";
import { MetricToImpFactors, ImpToMetricFactors } from "./unitSystem";

export class InputModelMapper {
  static getConfig(inputModel: InputModel, id:string, masterConfigurationId:string, calculationCode:string): IConfiguration {
    const toReturn = {
      masterConfigurationId: masterConfigurationId,
      systemModelFortracNature: InputModelMapper.getNatureSystemModelNumber(inputModel.systemModelNature),
      systemModelFortracGabion: InputModelMapper.getGabionSystemModelNumber(inputModel.systemModelGabion),
      systemModelFortracBlock: InputModelMapper.getSystemModelBlockNumber(inputModel.systemModelBlock),
      seedingMethod: InputModelMapper.getSeedingMethodNumber(inputModel.seedingMethod),
      constructionMethodFortracGabion: InputModelMapper.getConstructionMethodGabionNumber(inputModel
        .constructionMethodGabion),
      subSystemFortracGabion: InputModelMapper.getSubSystemGabionNumber(inputModel.subsystemGabion),
      selectedSystem: InputModelMapper.getProductNumber(inputModel.selectedSystem),
      verticalLayerDistance: InputModelMapper.getVerticalLayerNumber(inputModel.verticalLayerDistance),
      slopeAngle: inputModel.slopeAngle,
      height: inputModel.height,
      priceId: inputModel.priceId,
      wallFriction: inputModel.wallFriction,
      frictionAngle: inputModel.frictionAngle,
      unitWeight: inputModel.unitWeight,
      trafficLoad: inputModel.trafficLoad,
      materialPartialSafetyFactor: inputModel.materialPartialSafetyFactor,
      designLife: inputModel.designLife,
      frictionAngleCharacteristics: inputModel.frictionAngleCharacteristic,
      unitWeightCharacteristic: inputModel.unitWeightCharacteristic,
      grainSizeDistribution: inputModel.grainSizeDistribution,
      phValueOfFillingSoil: inputModel.phValueOfFillingSoil,
      minOverburdenHeight: inputModel.minOverburdenHeight,
      uniformVerticalSurcharge: inputModel.uniformVerticalSurcharge,
      widthOfUniformVerticalSurcharge: inputModel.widthOfUniformVerticalSurcharge,
      numberOfGeogrids: inputModel.numberOfGeogrids,
      calculationCode: calculationCode,
      title: inputModel.title,
      companyName: inputModel.companyName,
      emailAddress: inputModel.emailAddress,
      phoneNumber: inputModel.phoneNumber,
      id: id,
      moreInformationNeeded: inputModel.moreInformationNeeded,
      contactName: inputModel.contactName,
      contactLastName: inputModel.contactLastName,
      userComment: inputModel.userComment,
      chartDataJson: inputModel.chartDataJson,
      shouldAllGeoGridsHaveSameLength: inputModel.shouldAllGeoGridsHaveSameLength,
      unitSystem: inputModel.unitSystem,
      contactCountry: inputModel.contactCountry
    } as IConfiguration;

    return toReturn;
  }

  static getDefaultInputModel(language: string = 'en', role: number = UserRoles.customer): InputModel {

    const isImp = language === 'enimp';
    const lengthFactor = isImp ? MetricToImpFactors.Foot : 1;
    const pressureFactor = isImp ? MetricToImpFactors.Pressure : 1;
    const weightFactor = isImp ? MetricToImpFactors.Weight : 1;

      const toReturn = {
      height: 10 * lengthFactor,
      slopeAngle: 90,
      title: '',
      language: language,
      role: role,
      shouldAllGeoGridsHaveSameLength: 0,
      
      systemModelNature: NatureSystemModel.WithoutIntegratedFormWork,
      seedingMethod: NatureSeedingMethod.NotNecessary,
      systemModelGabion: GabionSystemModel.FullGabionFacing,
      constructionMethodGabion: GabionConstructionMethod.WithoutIntegratedFormwork,
      subsystemGabion: GabionSubsystem.T1Dach,
      selectedSystem: Product.Nature,

      systemModelBlock: BlockOptions.HollowBlockFacing,
      priceId: 'a0d710b2-2bc0-4af0-8b31-08d8a20e37ce',
      frictionAngleCharacteristic: 32.5, //ToDo: In DB Default values setzten
      unitWeightCharacteristic: 20 * weightFactor,
      phValueOfFillingSoil: 2, //ToDO: DB
      designLife: 120,

      frictionAngle: 1.25,
      unitWeight: 1.00,
      trafficLoad: 1.30,
      materialPartialSafetyFactor: 1.40,

      //Other default values
      frictionAngleCharacteristicRad: null,
      frictionAngleDesign: null,
      frictionAngleDesignRad: null,
      unitWeightDesign: null,
      grainSizeDistribution: 3,
      productString: null,
      slopeAngleDegreeFirst: null,
      slopeAngleDegreeSecond: null,
      verticalLayerDistance: isImp ? 1.667 : 0.5,
      minOverburdenHeight: 0.3 * lengthFactor,
      wallFriction: 0,
      wallFrictionRad: null,
      numberOfGeogrids: 4,
      uniformVerticalSurcharge: 33 * pressureFactor,
      uniformVerticalSurchargeDesignValue: null,
      effectiveHeight: null,
      widthOfUniformVerticalSurcharge: 15 * lengthFactor,
      angleOfInclinationOfSlipSurfaces: null,
      angleOfInclinationOfSlipSurfacesRad: null,
      influencingDepthOfVerticalSurcharge: null,
      emailAddress: null,
      companyName: null,
      phoneNumber: null,
      moreInformationNeeded: true,
      unitSystem: isImp ? 2: 1,
        petPva: 1
    } as InputModel;

    return toReturn;
  }

  static getInputModel(config: IConfiguration): InputModel {
    const toReturn = {
      isDefault:!config.masterConfigurationId,
      systemModelNature: InputModelMapper.getNatureSystemModelByNumber(config.systemModelFortracNature),
      systemModelGabion: InputModelMapper.getGabionSystemModelByNumber(config.systemModelFortracGabion),
      systemModelBlock: InputModelMapper.getSystemModelBlockByNumber(config.systemModelFortracBlock),
      seedingMethod: InputModelMapper.getSeedingMethodByNumber(config.seedingMethod),
      constructionMethodGabion: InputModelMapper.getConstrcutionMethodGabionByNumber(config.constructionMethodFortracGabion),
      subsystemGabion: InputModelMapper.getSubSystemGabionByNumber(config.subSystemFortracGabion),
      selectedSystem: InputModelMapper.getProductByNumber(config.selectedSystem),
      priceId:config.priceId,
      slopeAngle: config.slopeAngle,
      height: config.height,
      frictionAngleCharacteristic: config.frictionAngleCharacteristics,
      unitWeightCharacteristic: config.unitWeightCharacteristic,
      phValueOfFillingSoil: config.phValueOfFillingSoil,
      designLife: config.designLife,
      frictionAngle: config.frictionAngle,
      unitWeight: config.unitWeight,
      trafficLoad: config.trafficLoad,
      materialPartialSafetyFactor: config.materialPartialSafetyFactor,
      grainSizeDistribution: config.grainSizeDistribution,
      verticalLayerDistance: InputModelMapper.getVerticalLayerDistanceByNumber(config.verticalLayerDistance, config.languageCode),
      minOverburdenHeight: config.minOverburdenHeight,
      wallFriction: config.wallFriction,
      numberOfGeogrids: config.numberOfGeogrids,
      uniformVerticalSurcharge: config.uniformVerticalSurcharge,
      widthOfUniformVerticalSurcharge: config.widthOfUniformVerticalSurcharge,
      title: config.title,
      id: config.id,
      role: UserRoles.hueskerInternal,
      companyName: config.companyName,
      emailAddress: config.emailAddress,
      phoneNumber: config.phoneNumber,
      moreInformationNeeded: config.moreInformationNeeded,
      contactName: config.contactName,
      userComment: config.userComment,
      chartDataJson: config.chartDataJson,
      shouldAllGeoGridsHaveSameLength: config.shouldAllGeoGridsHaveSameLength,
      unitSystem: config.unitSystem,
      petPva: config.petPva
  } as InputModel;

    return toReturn;
  }

  private static gabionSystemModelMapping = [
    { id: 1, value: GabionSystemModel.FullGabionFacing },
    { id: 2, value: GabionSystemModel.HalfGabionFacing },
    { id: 3, value: GabionSystemModel.TieRodSystem}
  ];

  private static seedingMethodMapping = [
    { id: 1, value: NatureSeedingMethod.NotNecessary},
    { id: 2, value: NatureSeedingMethod.Conventional},
    { id: 3, value: NatureSeedingMethod.HydroSeeding}
    ];

  private static systemModelBlockMapping = [
    { id: 1, value: BlockOptions.HollowBlockFacing },
    { id: 2, value: BlockOptions.SolidBlockFacing }
    ];

  private static constructionMethodGabionMapping = [
    { id: 1, value: GabionConstructionMethod.WithoutIntegratedFormwork},
    { id: 2, value: GabionConstructionMethod.IntegratedLostFormwork},
  ];

  private static subSystemGabionMapping = [
    { id: 1, value: GabionSubsystem.T1Dach},
    { id: 2, value: GabionSubsystem.T2EEMEA},
  ];

  private static natureSystemModelMapping = [
    { id: 1, value: NatureSystemModel.WithoutIntegratedFormWork},
    { id: 2, value: NatureSystemModel.IntegratedLostFormwork},
    { id: 3, value: NatureSystemModel.IntegratedCorrosinProtectedFormWork},
    ];

  private static productSettingMapping = [
    { id: 1, value: Product.Nature },
    { id: 2, value: Product.Gabion },
    { id: 3, value: Product.Block },
    { id: 4, value: Product.Panel },

    ];

  private static getProductByNumber(val: number): Product {
    if (!val) {
      return null;
    }

    return this.productSettingMapping.find(x => x.id === val).value;
  }

  private static getVerticalLayerNumber(val: number): number {
    if (val === 1.333 || val === 0.4) {
      return 1;
    }

    if (val === 1.667 || val === 0.5) {
      return 2;
    }

    if (val === 2 || val === 0.6) {
      return 3;
    }
  }

  private static getProductNumber(val: Product): number {
    if (!val) {
      return null;
    }

    return this.productSettingMapping.find(x => x.value == val).id;
  }

  private static getNatureSystemModelByNumber(val: number): NatureSystemModel {
    if (!val) {
      return null;
    }

    return this.natureSystemModelMapping.find(x => x.id === val).value;
  }

  private static getVerticalLayerDistanceByNumber(val: number, lang: string): number {
    const isImp = lang === 'enimp';

    switch (val) {
      case 1:
        return isImp ? 1.333 : 0.4;
      case 2:
        return isImp ? 1.667 : 0.5;
      case 3:
        return isImp ? 2 : 0.6;
    }
  }

  private static getNatureSystemModelNumber(val: NatureSystemModel): number {
    if (!val) {
      return null;
    }

    return this.natureSystemModelMapping.find(x => x.value == val).id;
  }

  private static getSubSystemGabionByNumber(val: number): GabionSubsystem {
    if (!val) {
      return null;
    }

    return this.subSystemGabionMapping.find(x => x.id === val).value;
  }

  private static getSubSystemGabionNumber(val: GabionSubsystem): number {
    if (!val) {
      return null;
    }

    return this.subSystemGabionMapping.find(x => x.value === val).id;
  }

  private static getConstructionMethodGabionNumber(value: GabionConstructionMethod): number {
    if (!value) {
      return null;
    }

    return this.constructionMethodGabionMapping.find(x => x.value === value).id;
  }

  private static getConstrcutionMethodGabionByNumber(value: number): GabionConstructionMethod {
    if (!value) {
      return null;
    }

    return this.constructionMethodGabionMapping.find(x => x.id === value).value;
  }

  private static getSeedingMethodNumber(value: NatureSeedingMethod): number {
    if (!value) {
      return null;
    }

    return this.seedingMethodMapping.find(x => x.value === value).id;
  }

  private static getSeedingMethodByNumber(val: number): NatureSeedingMethod {
    if (!val) {
      return null;
    }

    return this.seedingMethodMapping.find(x => x.id === val).value;
  }

  private static getSystemModelBlockNumber(value: BlockOptions): number {
    if (!value) {
      return null;
    }

    return this.systemModelBlockMapping.find(x => x.value === value).id;
  }

  private static getSystemModelBlockByNumber(val: number): BlockOptions {
    if (!val) {
      return null;
    }

    return this.systemModelBlockMapping.find(x => x.id === val).value;
  }

  private static getGabionSystemModelNumber(value: GabionSystemModel): number {
    if (!value) {
      return null;
    }

    return this.gabionSystemModelMapping.find(x => x.value === value).id;
  }

  private static getGabionSystemModelByNumber(val: number): GabionSystemModel {
    if (!val) {
      return null;
    }

    return this.gabionSystemModelMapping.find(x => x.id === val).value;
  }
}
