import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../authService/auth.service';
import { SignInModel } from './sign-in.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent {
  @ViewChild("signInTemplate", { read: TemplateRef }) signInTemplate: TemplateRef<any>;
  modalRef: BsModalRef;

  signInForm: FormGroup;
  signInModel: SignInModel;
  signInFields: FormlyFieldConfig[];
  formOptions: FormlyFormOptions = {};

  modalOnHideSubscription: Subscription;
  errorMsg: string;
  loading: boolean = false;

  constructor(private modalService: BsModalService, private authService: AuthService, private translate: TranslateService) {
    this.signInForm = new FormGroup({});
    this.signInModel = new SignInModel();
    this.signInFields = this.signInModel.getFieldConfigs(translate);
  }

  show(): void {
    this.modalRef = this.modalService.show(this.signInTemplate, { class: 'modal-lg' });
    // Unsubscribe previous subscription (if any)
    if (this.modalOnHideSubscription) {
      this.modalOnHideSubscription.unsubscribe();
    }
    this.modalOnHideSubscription = this.modalRef.onHide.subscribe(result => {
      this.errorMsg = "";
      this.resetForm();
    })
  }

  hide(): void {
    this.modalRef.hide();
  }

  resetForm(): void {
    this.formOptions.resetModel();
  }

  signInHandler(event: Event): void {
    event && event.preventDefault;
    this.errorMsg = "";
    this.loading = true;
    this.authService.signIn(this.signInModel.username, this.signInModel.password)
      .subscribe(response => {
        this.loading = false;
        this.authService.isLoggedIn.next(true);
        this.authService.triggerRoleChange();
        this.modalRef.hide();
      }, error => {
        this.loading = false;
        this.errorMsg = this.translate.instant('messages.' + error.code);
      });
  }
}
