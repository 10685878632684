import { InputModel } from "./inputModel";
import { IPriceTableLine } from "./IPriceTableLine";
import { IProductPricedetails } from "./IProductPricedetails";
import { IPricePosition } from "./IPricePosition";
import { NatureSystemModel, NatureSeedingMethod, Product, PricePositionLabel } from "./calculationEnums";
import { Prices } from "./prices";
import { PricesService } from "../prices.service";

export class FortracNaturePriceCalculator {
  constructor(private frontArea, private inputModel: InputModel, private geoGridsSummary: Array<IPriceTableLine>, private prices: Prices) {

  }

  getErosionProtectionLine(): IPricePosition {
    const seedingMethod = this.inputModel.seedingMethod;

    const description = seedingMethod === NatureSeedingMethod.NotNecessary
      ? "Non woven GRK5"
      : (seedingMethod === NatureSeedingMethod.HydroSeeding ? "Fortrac 3D 20" : "23.142");

    //const quantity = 1.25 / this.inputModel.verticalLayerDistance;
    const quantity = 0.4 + this.inputModel.verticalLayerDistance;

    let price = 0;

    if (seedingMethod === NatureSeedingMethod.NotNecessary) {
      price = quantity * this.prices.trickleProtection.NonWovenFabric;
    } else if (seedingMethod === NatureSeedingMethod.HydroSeeding) {
      price = quantity * this.prices.trickleProtection.fortrac3D;
    } else {
      price = quantity * this.prices.trickleProtection._23142Gr;
    }

    const returnValue = {
      description: description,
      price: price,
      quantity: quantity,
      position:PricePositionLabel.ErosionProtection
  } as IPricePosition;

    return returnValue;
  }

  private getGeoGridLine(): IPricePosition {
    const isSteelstab = this.inputModel.systemModelNature === NatureSystemModel.IntegratedCorrosinProtectedFormWork;

    const description = isSteelstab 
      ? "Steelstab"
      : "Wraparound";

    const quantity = (isSteelstab
        ? this.geoGridsSummary.reduce((sum, current) => sum + current.steelstabPlus6Perc, 0)
        : this.geoGridsSummary.reduce((sum, current) => sum + current.wrapAroundPlus6Perc, 0)) /
      this.frontArea;

    const price = (isSteelstab
        ? this.geoGridsSummary.reduce((sum, current) => sum + current.steelStabTotal, 0)
        : this.geoGridsSummary.reduce((sum, current) => sum + current.wrapAroundTotal, 0)) /
      this.frontArea;

    const returnValue = {
      description: description,
      quantity: quantity,
      price: price,
      position: PricePositionLabel.Geogrid
    } as IPricePosition;
    
    return returnValue;
  }

  private getSteelLine(): IPricePosition {
    if (this.inputModel.systemModelNature === NatureSystemModel.WithoutIntegratedFormWork) {
      return null;
    }

    const description = this.inputModel.systemModelNature === NatureSystemModel.IntegratedCorrosinProtectedFormWork
      ? "Galvanized"
      : "not galvanized";

    let priceToCalculate = 0;

    if (this.inputModel.systemModelNature === NatureSystemModel.IntegratedCorrosinProtectedFormWork) {
      priceToCalculate = this.prices.steelLattice.galvanized;
    } else {
      if (this.inputModel.verticalLayerDistance === 0.5) {
        priceToCalculate = this.prices.steelLattice.steel05;
      } else {
        priceToCalculate = this.prices.steelLattice.steel06;
      }
    }

    const returnValue = {
      quantity: 1,
      description: description,
      price: priceToCalculate,
      position:PricePositionLabel.Steel
    } as IPricePosition;

    return returnValue;
  }

  calculate(): IProductPricedetails {
    //const frontarea = this.inputModel.height / Math.sin(Math.r)
    const geoGrid = this.getGeoGridLine();
    const steel = this.getSteelLine();
    const erosionProtection = this.getErosionProtectionLine();

    const positions = [geoGrid, steel, erosionProtection].filter(x => x !== null);

    const details = {
      product: Product.Nature,
      isValid: true,
      positions: positions,
      sumPrice: positions.reduce((sum, current) => sum + current.price, 0)
    } as IProductPricedetails;

    return details;
  }
}
