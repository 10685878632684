import { InputModel } from "./inputModel";

export class EarthPressure {
  cos_phi_alpha: number;
  sin_phi_plus_delta_: number;
  cos_alpha_beta: number;
  cos_alpha: number;
  noLabel:number;

  constructor(private inputModel:InputModel) {
    this.cos_phi_alpha = Math.cos(this.inputModel.frictionAngleDesignRad - this.inputModel.slopeAngleDegreeSecond);
    this.sin_phi_plus_delta_ = Math.sin(this.inputModel.frictionAngleDesignRad + this.inputModel.wallFrictionRad) * Math.sin(this.inputModel.frictionAngleCharacteristicRad);
    this.cos_alpha_beta = Math.cos(this.inputModel.slopeAngleDegreeSecond) *
      Math.cos(this.inputModel.slopeAngleDegreeSecond + this.inputModel.wallFrictionRad);
    this.cos_alpha = Math.cos(this.inputModel.slopeAngleDegreeSecond) *
      (1 + Math.sqrt(this.sin_phi_plus_delta_ / this.cos_alpha_beta));
    this.noLabel = Math.pow(this.cos_phi_alpha / this.cos_alpha, 2);
  }

}
