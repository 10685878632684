import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserRoles } from './user-roles.config';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  currentRole: BehaviorSubject<number>;
  readonly defaultRole: number = UserRoles.customer;

  changeRole(newRole: number): void {
    this.currentRole.next(newRole);
  }

  constructor() {
    this.currentRole = new BehaviorSubject(this.defaultRole);
  }
}
