import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ConfigurationService } from "../configuration.service";
import { CompleteResultSet } from "../calculationModel/ICompleteResultSet";
import { ResultLayer } from "../calculationModel/resultLayer";
import { TranslateService } from "@ngx-translate/core";
import { MetricToImpFactors } from "../calculationModel/unitSystem";

@Component({
  selector: 'app-overview-chart',
  templateUrl: './overview-chart.component.html',
  styleUrls: ['./overview-chart.component.css']
})
export class OverviewChartComponent implements OnInit {
  chart: Chart;
  currentSeriesIndexes: number[];
  private layerLabel: string;
  private labelHeight: string;
  private labelLength: string;
  private labelChart: string;
  private labelLoad: string;
  private labelHeightOfWall: string;
  private labelInclinationWallFront: string;


  maxLength: number;
  minLength:number;
  averageLength: number;
  volume: number;
  priceINdication:number;

  private initLang(rs: CompleteResultSet) {
    this.translate.get('input.chartLayerLabel').subscribe((res => {
      this.labelHeight = this.translate.instant('input.chartHeightLabel');
      this.labelChart = this.translate.instant('input.chartLabel');
      this.labelHeight = this.translate.instant('input.chartHeightLabel');
      this.labelLength = this.translate.instant('input.chartLengthLabel');
      this.layerLabel = res;
      this.labelLoad = this.translate.instant('load');
      this.labelHeightOfWall = this.translate.instant('input.heightOfWallFieldLabelSecond');
      this.labelInclinationWallFront = this.translate.instant('input.slopeAngleFieldLabelSecond');
      this.configureChart(rs);
    }));
  }

  constructor(private configurationService: ConfigurationService, public translate: TranslateService) {
    //this.configureChart(null);
    this.initLang(null);

    this.translate.onLangChange.subscribe(x => {
      this.initLang(this.configurationService.currentResultSet.getValue());
    });

    this.configurationService.currentResultSet.subscribe(x => {
      this.configureChart(x);
    });
  }

  private getSeries(rs: CompleteResultSet): any[] {

    if (!rs) {
      return [];
    }
    
    const resultArray = [];

    const isImp = rs.inputModel.language === 'enimp';

    rs.resultLayers.forEach(item => {
      const layer = item as ResultLayer;

      const factor = layer.height / Math.tan(rs.inputModel.slopeAngle * Math.PI / 180);

      const firstPoint = {
        x: 0 + factor,
        y: layer.coordinates.val_1,
        layerIndex: layer.layerIndex,
        height: layer.height,
        seriesType: 'layer',
        totalLength: layer.resultTotalLength,
        unit: isImp ? 'ft' : 'm',
        labelHeight: this.labelHeight,
        labelLength: this.labelLength,
        labelLayer: this.layerLabel,
        labelLoad: this.labelLoad,
        labelHeightOfWall: this.labelHeightOfWall,
        labelInclinationWallFront: this.labelInclinationWallFront
      }
      
      const secondPoint = {
        x: layer.coordinates.val_3,
        y: layer.coordinates.val_1,
        layerIndex: layer.layerIndex,
        height: layer.height,
        seriesType: 'layer',
        totalLength: layer.resultTotalLength,
        unit: isImp ? 'ft' : 'm',
        labelHeight: this.labelHeight,
        labelLength: this.labelLength,
        labelLayer: this.layerLabel,
        labelLoad: this.labelLoad,
        labelHeightOfWall: this.labelHeightOfWall,
        labelInclinationWallFront: this.labelInclinationWallFront
      };

      const newSerie = {
        //name: "Layer",
        name: this.layerLabel,
        data: [firstPoint, secondPoint],
        color: layer.productLine.color,
    };

      resultArray.push(newSerie);

    });

    const embankmentSeries = {
      name: 'Embankment',
      color: '#000000',
      type: 'line',
      data: [
        {
          x: -2,
          y: 0,
          height: rs.inputModel.height,
          inclination: rs.inputModel.slopeAngle,
          seriesType: 'embankment',
          unit: isImp ? 'ft' : 'm',
          labelHeight: this.labelHeight,
          labelLength: this.labelLength,
          labelLayer: this.layerLabel,
          labelLoad: this.labelLoad,
          labelHeightOfWall: this.labelHeightOfWall,
          labelInclinationWallFront: this.labelInclinationWallFront
        },
        {
          x: 0,
          y: 0,
          height: rs.inputModel.height,
          inclination: rs.inputModel.slopeAngle,
          seriesType: 'embankment',
          unit: isImp ? 'ft' : 'm',
          labelHeight: this.labelHeight,
          labelLength: this.labelLength,
          labelLayer: this.layerLabel,
          labelLoad: this.labelLoad,
          labelHeightOfWall: this.labelHeightOfWall,
          labelInclinationWallFront: this.labelInclinationWallFront
  }, {
          x: rs.inputModel.height / Math.tan(rs.inputModel.slopeAngle * Math.PI / 180),
          y: rs.inputModel.height,
          height: rs.inputModel.height,
          inclination: rs.inputModel.slopeAngle,
          seriesType: 'embankment',
          unit: isImp ? 'ft' : 'm',
          labelHeight: this.labelHeight,
          labelLength: this.labelLength,
          labelLayer: this.layerLabel,
          labelLoad: this.labelLoad,
          labelHeightOfWall: this.labelHeightOfWall,
          labelInclinationWallFront: this.labelInclinationWallFront
        }, {
          x: isImp ? 100 : 30,
          y: rs.inputModel.height,
          height: rs.inputModel.height,
          inclination: rs.inputModel.slopeAngle,
          seriesType: 'embankment',
          unit: isImp ? 'ft' : 'm',
          labelHeight: this.labelHeight,
          labelLength: this.labelLength,
          labelLayer: this.layerLabel,
          labelLoad: this.labelLoad,
          labelHeightOfWall: this.labelHeightOfWall,
          labelInclinationWallFront: this.labelInclinationWallFront
        }]
    };

    const firstX = 0.5
    + rs.inputModel.height / Math.tan(rs.inputModel.slopeAngle * Math.PI / 180);

    const loadSeries = {
      name: 'Load',
      color: '#6495ED',
      type: 'line',
      data: [{
        x: firstX,
        y: rs.inputModel.height,
        trafficLoad: rs.inputModel.uniformVerticalSurcharge,
        seriesType: 'load',
        unit: isImp ? 'psi' : 'kN/m²',
        labelHeight: this.labelHeight,
        labelLength: this.labelLength,
        labelLayer: this.layerLabel,
        labelLoad: this.labelLoad,
        labelHeightOfWall: this.labelHeightOfWall,
        labelInclinationWallFront: this.labelInclinationWallFront
      }, {
          x: 0.5 + rs.inputModel.height / Math.tan(rs.inputModel.slopeAngle * Math.PI / 180),
        y: rs.inputModel.height,
          trafficLoad: rs.inputModel.uniformVerticalSurcharge,
        seriesType: 'load',
        unit: isImp ? 'psi' : 'kN/m²',
        labelHeight: this.labelHeight,
        labelLength: this.labelLength,
        labelLayer: this.layerLabel,
        labelLoad: this.labelLoad,
        labelHeightOfWall: this.labelHeightOfWall,
        labelInclinationWallFront: this.labelInclinationWallFront
        }, {
          x: rs.inputModel.widthOfUniformVerticalSurcharge + rs.inputModel.unitWeight + firstX,
        y: rs.inputModel.height,
          trafficLoad: rs.inputModel.uniformVerticalSurcharge,
        seriesType: 'load',
        unit: isImp ? 'psi' : 'kN/m²',
        labelHeight: this.labelHeight,
        labelLength: this.labelLength,
        labelLayer: this.layerLabel,
        labelLoad: this.labelLoad,
        labelHeightOfWall: this.labelHeightOfWall,
        labelInclinationWallFront: this.labelInclinationWallFront
        }, {
          x: rs.inputModel.widthOfUniformVerticalSurcharge + rs.inputModel.unitWeight + firstX,
          y: rs.inputModel.height,
          trafficLoad: rs.inputModel.uniformVerticalSurcharge,
        seriesType: 'load',
        unit: isImp ? 'psi' : 'kN/m²',
        labelHeight: this.labelHeight,
        labelLength: this.labelLength,
        labelLayer: this.layerLabel,
        labelLoad: this.labelLoad,
        labelHeightOfWall: this.labelHeightOfWall,
        labelInclinationWallFront: this.labelInclinationWallFront
      }]
    }

    resultArray.push(loadSeries);
    resultArray.push(embankmentSeries);

    return resultArray;
  }

  private configureChart(rs: CompleteResultSet): void {


    const chart = new Chart({
      chart: {
        //height: 90 + '%',
        //width: 700,
        type: 'line'
      },
      legend: {
        enabled: false
      },
      title: {
        text: this.labelChart
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function () {

          const labelHeight = (<any>this).point.labelHeight;
          //const typeOfSeries =
          const type = (<any>this).point.seriesType;

          if (type === 'layer') {
            return `<b>${(<any>this).point.labelLayer}:</b> ${(<any>this).point.layerIndex}<br/><b>${(<any>this).point.labelLength}:</b> ${(<any>this).point.totalLength.toFixed(2)}  ${(<any>this).point.unit}<br/><b>${labelHeight}:</b> ${(<any>this).point.height.toFixed(2)} ${(<any>this).point.unit}`;
          }
            
          if (type === 'load') {
            return `<b>${(<any>this).point.labelLoad}:</b> ${(<any>this).point.trafficLoad.toFixed(0)} ${(<any>this).point.unit}`;
          }

          if (type === 'embankment') {
            return `<b>${(<any>this).point.labelHeightOfWall}:</b> ${(<any>this).point.height.toFixed(0)} ${(<any>this).point.unit}<br/><b>${(<any>this).point.labelInclinationWallFront}: </b>${(<any>this).point.inclination.toFixed(0)} °`;
          }

          return `<b>${type}</b>`;
        }
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
        }
      },
      yAxis: {
        title: {
          text: this.labelHeight
        },
        type: 'linear',
        min: 0,
        max: rs?.inputModel?.language === 'enimp' ? 40 : 12
      },
      xAxis: {
        title: {
          text: this.labelLength
        },
        type: 'linear',
        min: -2,
        max: rs?.inputModel?.language === 'enimp' ? 40 : 12
      },
      series: this.getSeries(rs)
    });

    //(<any>chart).options.yAxis.max = (!!rs?.inputModel?.height) ? rs.inputModel.height + 2 : 15;

    this.chart = chart;
    this.maxLength = rs ? rs.maxLength : 0;
    this.minLength = rs ? rs.minLength : 0;
    this.averageLength = rs ? rs.averageLength : 0;
    this.volume = rs ? rs.volume : 0;

    const copyStr = JSON.stringify((<any>this.chart).options);
    
    const copy = JSON.parse(copyStr);
    (<any>copy).title.text = null;

    this.configurationService.currentChartDataJson = JSON.stringify((copy));
    this.configurationService.currentEmbankmentvalues = {
      maxLength: this.maxLength,
      minLength: this.minLength,
      averageLength: this.averageLength,
      volume: this.volume,
    }

    if (rs && rs.productPriceDetails) {
      const priceIndication = rs.productPriceDetails.find(x => x.product === rs.inputModel.selectedSystem).sumPrice;
      
      this.configurationService.currentPriceIndication = priceIndication;
    } else {
      this.configurationService.currentPriceIndication = null;
    }



    //console.log(JSON.stringify(this.chart.options));
  }

  ngOnInit(): void {
  }

}
