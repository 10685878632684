import { NatureSystemModel, GabionSystemModel, BlockOptions } from "./calculationEnums";

export class SubSystemMapper {
  static getNatureSubsystemId(natureSubsystem: NatureSystemModel): number {
    if (natureSubsystem === NatureSystemModel.IntegratedCorrosinProtectedFormWork) {
      return 1;
    } else if (natureSubsystem === NatureSystemModel.WithoutIntegratedFormWork) {
      return 2;
    }
    else if (natureSubsystem === NatureSystemModel.IntegratedLostFormwork) {
      return 3;
    }
    return null;
  }

  static getNatureSubsystem(id: number): NatureSystemModel {
    if (id === 1) {
      return NatureSystemModel.IntegratedCorrosinProtectedFormWork
    } else if (id === 2) {
      return NatureSystemModel.WithoutIntegratedFormWork;
    } else if (id === 3) {
      return NatureSystemModel.IntegratedLostFormwork;
    }
    return null;
  }

  static getGabionSubsystemId(gabionSubsystem: GabionSystemModel) :number {
    if (gabionSubsystem === GabionSystemModel.TieRodSystem) {
      return 4;
    } else if (gabionSubsystem === GabionSystemModel.HalfGabionFacing) {
      return 5;
    } else if (gabionSubsystem === GabionSystemModel.FullGabionFacing) {
      return 6;
    }
    return null;
  }

  static getGabionSubsystem(id: number): GabionSystemModel {
    if (id === 4) {
      return GabionSystemModel.TieRodSystem;
    } else if (id === 5) {
      return GabionSystemModel.HalfGabionFacing;
    } else if (id === 6) {
      return GabionSystemModel.FullGabionFacing;
    }
  }

  static getBlockSubsystemId(subsystem: BlockOptions): number {
    if (subsystem === BlockOptions.HollowBlockFacing) {
      return 7;
    } else if (subsystem === BlockOptions.SolidBlockFacing) {
      return 8;
    }
    return null;
  }

  static getBlockSubsystem(id: number): BlockOptions {
    if (id === 7) {
      return BlockOptions.HollowBlockFacing;
    } else if (id === 8) {
      return BlockOptions.SolidBlockFacing;
    }
    return null;
  }
}
