import { IPriceTableLine } from "./IPriceTableLine";
import { ResultLayer } from "./resultLayer";
import { InputModel } from "./inputModel";
import { IProductPricedetails } from "./IProductPricedetails";
import { IPricePosition } from "./IPricePosition";
import { FortracNaturePriceCalculator } from "./fortracNaturePriceCalculator";
import { Prices } from "./prices";
import { FortracGabionCalculator } from "./fortracGabionCalculator";
import { FortracBlockCalculator } from "./fortracBlockCalculator";
import { FortracPanelCalculator } from "./fortracPanelCalculator";

export class PriceCalculator {
  resultTable: Array<IPriceTableLine>;
  frontArea:number;

  groupBy(list: Array<any>, keyGetter:any) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };

  constructor(private inputModel: InputModel, private resultLayers: Array<ResultLayer>, private prices:Prices) {

  }

  private calculateFrontArea() {
    //const radians = this.inputModel.slopeAngle * (Math.PI / 180);
    this.frontArea = this.inputModel.height;  // Math.sin(radians);
  }

  calculate(prices: Prices): IProductPricedetails[] {
    console.log("Calculate prices");

    const layersWithResult = this.resultLayers.filter(x => !!x.productLine);
    const groupedByProduct = this.groupBy(layersWithResult, layer => layer.productLine.label);
    let pricesTable = [] as IPriceTableLine[];

    

    groupedByProduct.forEach(prodGroup => {
      const noLayers = prodGroup.length;
      const frictionConnec = prodGroup.reduce((total, current) => total + current.resultTotalLength, 0);
      const wrapAround = (this.inputModel.verticalLayerDistance + 1.5) * noLayers + frictionConnec;
      const steelStab = noLayers + frictionConnec;
      const price = prodGroup[0].productLine.price;

      const line = {
        noLayers: noLayers,
        frictionConnec: frictionConnec,
        wrapAround: wrapAround,
        steelstab: steelStab,

        frictionConnecPlus6Perc: frictionConnec * 1.06,
        wrapAroundPlus6Perc: wrapAround * 1.06,
        steelstabPlus6Perc: steelStab * 1.06,

        frictionConnecTotal: frictionConnec * 1.06 * price,
        wrapAroundTotal: wrapAround * 1.06 * price,
        steelStabTotal: steelStab * 1.06 * price

    } as IPriceTableLine;

      pricesTable.push(line);
    });

    this.calculateFrontArea();

    //const priceList =  this.inputModel.priceId


    const nature = new FortracNaturePriceCalculator(this.frontArea, this.inputModel, pricesTable, prices);
    const naturePrice = nature.calculate();

    const gabion = new FortracGabionCalculator(this.frontArea, this.inputModel, pricesTable, prices);
    const gabionPrice = gabion.calculate();

    const block = new FortracBlockCalculator(this.frontArea, this.inputModel, pricesTable, prices);
    const blockPrice = block.calculate();

    const panel = new FortracPanelCalculator(this.frontArea, this.inputModel, pricesTable, prices);
    const panelPrice = panel.calculate();

    return [naturePrice, gabionPrice, blockPrice, panelPrice];
  }
}
