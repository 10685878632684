

<div [formGroup]="form">

  <div class="row">
    <div class="col-3 form-inline form-group">
      <ng-select formControlName="selectedCalculation" style="width: 300px;" [items]="availablePrices" bindLabel="name" [searchable]="false" (change)="onSelectedCalculationChanged($event)">

      </ng-select>
      <button class="btn btn-danger" (click)="deleteSelectedCalculation()">{{ 'input.pricelistDeleteButton' | translate }}</button>

    </div>

    <div class="col-3" [formGroup]="newCalculation">
      <div class="form-inline form-group">
        <input id="calcNameId" minlength="5" type="text" class="form-control" formControlName="calcName" />
        <button class="btn btn-primary" (click)="createNewCalculation()" [disabled]="isNewCalculationButtonBusy">{{ 'CreateNewPriceList' | translate }}</button>
      </div>
    </div>

    <div class="col-3" [formGroup]="newCalculation">
      <div class="form-inline form-group">
        <button type="submit" class="btn btn-primary submit-button" (click)="submit()" [disabled]="!hasCalculationValueChanged">Update</button>
      </div>
    </div>
  </div>

      <div class="row">

        <div class="col-3">
          <div class="card">
            <h3 class="card-header">{{ 'input.pricelistFortracPricesLabel' | translate }}</h3>
            <div class="card-body">
              <formly-form [model]="selectedCalculation" [fields]="fortracProducts" [options]="options" [form]="editPricesFrom" (modelChange)="onPriceChange()"></formly-form>
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="card">
            <h3 class="card-header">{{ 'input.pricelistTrickleProtectionLabel' | translate }}</h3>
            <div class="card-body">
              <formly-form [model]="selectedCalculation" [fields]="trickleAndSteelLattice" [options]="options" [form]="editPricesFrom" (modelChange)="onPriceChange()"></formly-form>
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="card">
            <h3 class="card-header">Gabions</h3>
            <div class="card-body">
              <formly-form [model]="selectedCalculation" [fields]="gabions" [options]="options" [form]="editPricesFrom" (modelChange)="onPriceChange()"></formly-form>
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="card">
            <h3 class="card-header">Block & Panel</h3>
            <div class="card-body">
              <formly-form [model]="selectedCalculation" [fields]="blockAndPanel" [options]="options" [form]="editPricesFrom" (modelChange)="onPriceChange()"></formly-form>
            </div>
          </div>
        </div>

      </div>



      <!--  -->

    </div>
