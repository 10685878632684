import { Pipe, PipeTransform } from '@angular/core';
import { Product, NatureSystemModel, GabionSystemModel, BlockOptions } from "./calculationModel/calculationEnums";
import IntegratedCorrosinProtectedFormWork = NatureSystemModel.IntegratedCorrosinProtectedFormWork;

@Pipe({
  name: 'systemToImagePath',
  pure: true
})
export class SystemToImagePathPipe implements PipeTransform {

  transform(value: any): string {
    console.log(value);
    if (!value || !value.system) {
      return "";
    }
    
    
    const prefix = "./assets/";

    switch (value.system) {
      case Product.Nature.toString():
        if (!value.subSystem) {
          return prefix + "Fortrac Natur.png";
        }

        switch (value.subSystem) {
          case NatureSystemModel.IntegratedCorrosinProtectedFormWork.toString():
            return prefix + "Fortrac Natur Integrated Corrosion-protected formwork.png";
          case NatureSystemModel.IntegratedLostFormwork.toString():
            return prefix + "Fortrac Natur with integrated lost formwork.png";
          case NatureSystemModel.WithoutIntegratedFormWork.toString():
            return prefix + "Fortrac Natur without integrated formwork.png";
        }
        
        break;
      case Product.Block.toString():
        if(!value.subSystem){
          return prefix + "Fortrac Block.png";
        }

        switch(value.subSystem){
          case BlockOptions.HollowBlockFacing.toString():
            return prefix + "Fortrac Block_Hochkammerstein.png";
          case BlockOptions.SolidBlockFacing.toString():
            return prefix + "Fortrac Block_Vollstein.png";
        }

        break;
      case Product.Gabion.toString():
        if(!value.subSystem){
          return prefix + "Fortrac Gabion.png";
        }

        switch(value.subSystem){
          case GabionSystemModel.FullGabionFacing.toString():
            return prefix + "Fortrac Gabion_Voll.png";
          case GabionSystemModel.HalfGabionFacing.toString():
            return prefix + "Fortrac Gabion_Halb.png";
          case GabionSystemModel.TieRodSystem.toString():
            return prefix + "Fortrac Gabion_Ankerstab.png";
        }
      case Product.Panel.toString():
        return prefix + "Fortrac Panel passive 6.png";
        //return prefix + "Fortrac Panel.png";
    }

    return "";
  }

}
