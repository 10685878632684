(function () {
  var globals = {
    isUndefined: function (o) {
      return (o === undefined);
    },

    isNull: function (o) {
      return (o === null);
    },

    isNullOrUndefined: function (o) {
      return (o === null) || (o === undefined);
    },

    isValue: function (o) {
      return (o !== null) && (o !== undefined);
    }
  };

  var started = false;
  var startCallbacks = [];

  function onStartup(cb) {
    startCallbacks ? startCallbacks.push(cb) : setTimeout(cb, 0);
  }
  function startup() {
    if (startCallbacks) {
      var callbacks = startCallbacks;
      startCallbacks = null;
      for (var i = 0, l = callbacks.length; i < l; i++) {
        callbacks[i]();
      }
    }
  }
  if (document.addEventListener) {
    document.readyState == 'complete' ? startup() : document.addEventListener('DOMContentLoaded', startup, false);
  }
  else if (window.attachEvent) {
    window.attachEvent('onload', function () {
      startup();
    });
  }

  var ss = window.ss;
  if (!ss) {
    window.ss = ss = {
      init: onStartup,
      ready: onStartup
    };
  }
  for (var n in globals) {
    ss[n] = globals[n];
  }
})();

/**
 * Helper function for adding properties with Object.defineProperty
 * Copied from example on: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
 */
function withValue(value) {
  var d = withValue.d || (
    withValue.d = {
      enumerable: false,
      writable: false,
      configurable: false,
      value: null
    }
  );
  d.value = value;
  return d;
}

Object.__typeName = 'Object';
Object.__baseType = null;

Object.clearKeys = function Object$clearKeys(d) {
  for (var n in d) {
    delete d[n];
  }
}

Object.keyExists = function Object$keyExists(d, key) {
  return d[key] !== undefined;
}

if (!Object.keys) {
  Object.keys = function Object$keys(d) {
    var keys = [];
    for (var n in d) {
      keys.push(n);
    }
    return keys;
  }

  Object.getKeyCount = function Object$getKeyCount(d) {
    var count = 0;
    for (var n in d) {
      count++;
    }
    return count;
  }
}
else {
  Object.getKeyCount = function Object$getKeyCount(d) {
    return Object.keys(d).length;
  }
}

Boolean.__typeName = 'Boolean';

Boolean.parse = function Boolean$parse(s) {
  return (s.toLowerCase() == 'true');
}

Number.__typeName = 'Number';

Number.parse = function Number$parse(s) {
  if (!s || !s.length) {
    return 0;
  }
  if ((s.indexOf('.') >= 0) || (s.indexOf('e') >= 0) ||
    s.endsWith('f') || s.endsWith('F')) {
    return parseFloat(s);
  }
  return parseInt(s, 10);
}

Number.prototype.format = function Number$format(format) {
  if (ss.isNullOrUndefined(format) || (format.length == 0) || (format == 'i')) {
    return this.toString();
  }
  return this._netFormat(format, false);
}

Number.prototype.localeFormat = function Number$format(format) {
  if (ss.isNullOrUndefined(format) || (format.length == 0) || (format == 'i')) {
    return this.toLocaleString();
  }
  return this._netFormat(format, true);
}

Number._commaFormat = function Number$_commaFormat(number, groups, decimal, comma) {
  var decimalPart = null;
  var decimalIndex = number.indexOf(decimal);
  if (decimalIndex > 0) {
    decimalPart = number.substr(decimalIndex);
    number = number.substr(0, decimalIndex);
  }

  var negative = number.startsWith('-');
  if (negative) {
    number = number.substr(1);
  }

  var groupIndex = 0;
  var groupSize = groups[groupIndex];
  if (number.length < groupSize) {
    return decimalPart ? number + decimalPart : number;
  }

  var index = number.length;
  var s = '';
  var done = false;
  while (!done) {
    var length = groupSize;
    var startIndex = index - length;
    if (startIndex < 0) {
      groupSize += startIndex;
      length += startIndex;
      startIndex = 0;
      done = true;
    }
    if (!length) {
      break;
    }

    var part = number.substr(startIndex, length);
    if (s.length) {
      s = part + comma + s;
    }
    else {
      s = part;
    }
    index -= length;

    if (groupIndex < groups.length - 1) {
      groupIndex++;
      groupSize = groups[groupIndex];
    }
  }

  if (negative) {
    s = '-' + s;
  }
  return decimalPart ? s + decimalPart : s;
}

Number.prototype._netFormat = function Number$_netFormat(format, useLocale) {
  var nf = useLocale ? ss.CultureInfo.CurrentCulture.numberFormat : ss.CultureInfo.InvariantCulture.numberFormat;

  var s = '';
  var precision = -1;

  if (format.length > 1) {
    precision = parseInt(format.substr(1));
  }

  var fs = format.charAt(0);
  switch (fs) {
    case 'd': case 'D':
      s = parseInt(Math.abs(this)).toString();
      if (precision != -1) {
        s = s.padLeft(precision, '0');
      }
      if (this < 0) {
        s = '-' + s;
      }
      break;
    case 'x': case 'X':
      s = parseInt(Math.abs(this)).toString(16);
      if (fs == 'X') {
        s = s.toUpperCase();
      }
      if (precision != -1) {
        s = s.padLeft(precision, '0');
      }
      break;
    case 'e': case 'E':
      if (precision == -1) {
        s = this.toExponential();
      }
      else {
        s = this.toExponential(precision);
      }
      if (fs == 'E') {
        s = s.toUpperCase();
      }
      break;
    case 'f': case 'F':
    case 'n': case 'N':
      if (precision == -1) {
        precision = nf.numberDecimalDigits;
      }
      s = this.toFixed(precision).toString();
      if (precision && (nf.numberDecimalSeparator != '.')) {
        var index = s.indexOf('.');
        s = s.substr(0, index) + nf.numberDecimalSeparator + s.substr(index + 1);
      }
      if ((fs == 'n') || (fs == 'N')) {
        s = Number._commaFormat(s, nf.numberGroupSizes, nf.numberDecimalSeparator, nf.numberGroupSeparator);
      }
      break;
    case 'c': case 'C':
      if (precision == -1) {
        precision = nf.currencyDecimalDigits;
      }
      s = Math.abs(this).toFixed(precision).toString();
      if (precision && (nf.currencyDecimalSeparator != '.')) {
        var index = s.indexOf('.');
        s = s.substr(0, index) + nf.currencyDecimalSeparator + s.substr(index + 1);
      }
      s = Number._commaFormat(s, nf.currencyGroupSizes, nf.currencyDecimalSeparator, nf.currencyGroupSeparator);
      if (this < 0) {
        s = String.format(nf.currencyNegativePattern, s);
      }
      else {
        s = String.format(nf.currencyPositivePattern, s);
      }
      break;
    case 'p': case 'P':
      if (precision == -1) {
        precision = nf.percentDecimalDigits;
      }
      s = (Math.abs(this) * 100.0).toFixed(precision).toString();
      if (precision && (nf.percentDecimalSeparator != '.')) {
        var index = s.indexOf('.');
        s = s.substr(0, index) + nf.percentDecimalSeparator + s.substr(index + 1);
      }
      s = Number._commaFormat(s, nf.percentGroupSizes, nf.percentDecimalSeparator, nf.percentGroupSeparator);
      if (this < 0) {
        s = String.format(nf.percentNegativePattern, s);
      }
      else {
        s = String.format(nf.percentPositivePattern, s);
      }
      break;
  }

  return s;
}

String.__typeName = 'String';
String.Empty = '';

String.compare = function String$compare(s1, s2, ignoreCase) {
  if (ignoreCase) {
    if (s1) {
      s1 = s1.toUpperCase();
    }
    if (s2) {
      s2 = s2.toUpperCase();
    }
  }
  s1 = s1 || '';
  s2 = s2 || '';

  if (s1 == s2) {
    return 0;
  }
  if (s1 < s2) {
    return -1;
  }
  return 1;
}

String.prototype.compareTo = function String$compareTo(s, ignoreCase) {
  return String.compare(this, s, ignoreCase);
}

String.concat = function String$concat() {
  if (arguments.length === 2) {
    return arguments[0] + arguments[1];
  }
  return Array.prototype.join.call(arguments, '');
}

String.prototype.endsWith = function String$endsWith(suffix) {
  if (!suffix.length) {
    return true;
  }
  if (suffix.length > this.length) {
    return false;
  }
  return (this.substr(this.length - suffix.length) == suffix);
}

String.equals = function String$equals1(s1, s2, ignoreCase) {
  return String.compare(s1, s2, ignoreCase) == 0;
}

String._format = function String$_format(format, values, useLocale) {
  if (!String._formatRE) {
    String._formatRE = /(\{[^\}^\{]+\})/g;
  }

  return format.replace(String._formatRE,
    function (str, m) {
      var index = parseInt(m.substr(1));
      var value = values[index + 1];
      if (ss.isNullOrUndefined(value)) {
        return '';
      }
      if (value.format) {
        var formatSpec = null;
        var formatIndex = m.indexOf(':');
        if (formatIndex > 0) {
          formatSpec = m.substring(formatIndex + 1, m.length - 1);
        }
        return useLocale ? value.localeFormat(formatSpec) : value.format(formatSpec);
      }
      else {
        return useLocale ? value.toLocaleString() : value.toString();
      }
    });
}

String.format = function String$format(format) {
  return String._format(format, arguments, /* useLocale */ false);
}

String.fromChar = function String$fromChar(ch, count) {
  var s = ch;
  for (var i = 1; i < count; i++) {
    s += ch;
  }
  return s;
}

String.prototype.htmlDecode = function String$htmlDecode() {
  var div = document.createElement('div');
  div.innerHTML = this;
  return div.textContent || div.innerText;
}

String.prototype.htmlEncode = function String$htmlEncode() {
  var div = document.createElement('div');
  div.appendChild(document.createTextNode(this));
  return div.innerHTML.replace(/\"/g, '&quot;');
}

String.prototype.indexOfAny = function String$indexOfAny(chars, startIndex, count) {
  var length = this.length;
  if (!length) {
    return -1;
  }

  startIndex = startIndex || 0;
  count = count || length;

  var endIndex = startIndex + count - 1;
  if (endIndex >= length) {
    endIndex = length - 1;
  }

  for (var i = startIndex; i <= endIndex; i++) {
    if (chars.indexOf(this.charAt(i)) >= 0) {
      return i;
    }
  }
  return -1;
}

String.prototype.insert = function String$insert(index, value) {
  if (!value) {
    return this.valueOf();
  }
  if (!index) {
    return value + this;
  }
  var s1 = this.substr(0, index);
  var s2 = this.substr(index);
  return s1 + value + s2;
}

String.isNullOrEmpty = function String$isNullOrEmpty(s) {
  return !s || !s.length;
}

String.isNullOrWhiteSpace = function String$isNullOrWhiteSpace(s) {
  return String.isNullOrEmpty(s) || s.trim() === "";
}

String.prototype.lastIndexOfAny = function String$lastIndexOfAny(chars, startIndex, count) {
  var length = this.length;
  if (!length) {
    return -1;
  }

  startIndex = startIndex || length - 1;
  count = count || length;

  var endIndex = startIndex - count + 1;
  if (endIndex < 0) {
    endIndex = 0;
  }

  for (var i = startIndex; i >= endIndex; i--) {
    if (chars.indexOf(this.charAt(i)) >= 0) {
      return i;
    }
  }
  return -1;
}

String.localeFormat = function String$localeFormat(format) {
  return String._format(format, arguments, /* useLocale */ true);
}

String.prototype.padLeft = function String$padLeft(totalWidth, ch) {
  if (this.length < totalWidth) {
    ch = ch || ' ';
    return String.fromChar(ch, totalWidth - this.length) + this;
  }
  return this.valueOf();
}

String.prototype.padRight = function String$padRight(totalWidth, ch) {
  if (this.length < totalWidth) {
    ch = ch || ' ';
    return this + String.fromChar(ch, totalWidth - this.length);
  }
  return this.valueOf();
}

String.prototype.remove = function String$remove(index, count) {
  if (!count || ((index + count) > this.length)) {
    return this.substr(0, index);
  }
  return this.substr(0, index) + this.substr(index + count);
}

String.prototype.replaceAll = function String$replaceAll(oldValue, newValue) {
  newValue = newValue || '';
  return this.split(oldValue).join(newValue);
}

String.prototype.startsWith = function String$startsWith(prefix) {
  if (!prefix.length) {
    return true;
  }
  if (prefix.length > this.length) {
    return false;
  }
  return (this.substr(0, prefix.length) == prefix);
}

if (!String.prototype.trim) {
  String.prototype.trim = function String$trim() {
    return this.trimEnd().trimStart();
  }
}

String.prototype.trimEnd = function String$trimEnd() {
  return this.replace(/\s*$/, '');
}

String.prototype.trimStart = function String$trimStart() {
  return this.replace(/^\s*/, '');
}

Array.__typeName = 'Array';
Array.__interfaces = [ss.IEnumerable];

Object.defineProperty(Array.prototype, 'add', withValue(function Array$add(item) {
  this[this.length] = item;
}));

Object.defineProperty(Array.prototype, 'addRange', withValue(function Array$addRange(items) {
  this.push.apply(this, items);
}));

Object.defineProperty(Array.prototype, 'aggregate', withValue(function Array$aggregate(seed, callback, instance) {
  var length = this.length;
  for (var i = 0; i < length; i++) {
    if (i in this) {
      seed = callback.call(instance, seed, this[i], i, this);
    }
  }
  return seed;
}));

Object.defineProperty(Array.prototype, 'clear', withValue(function Array$clear() {
  this.length = 0;
}));

Object.defineProperty(Array.prototype, 'clone', withValue(function Array$clone() {
  if (this.length === 1) {
    return [this[0]];
  }
  else {
    return Array.apply(null, this);
  }
}));

Object.defineProperty(Array.prototype, 'contains', withValue(function Array$contains(item) {
  var index = this.indexOf(item);
  return (index >= 0);
}));

Object.defineProperty(Array.prototype, 'dequeue', withValue(function Array$dequeue() {
  return this.shift();
}));

Object.defineProperty(Array.prototype, 'enqueue', withValue(function Array$enqueue(item) {
  this._queue = true;
  this.push(item);
}));

Object.defineProperty(Array.prototype, 'peek', withValue(function Array$peek() {
  if (this.length) {
    var index = this._queue ? 0 : this.length - 1;
    return this[index];
  }
  return null;
}));

if (!Array.prototype.every) {
  Object.defineProperty(Array.prototype, 'every', withValue(function Array$every(callback, instance) {
    var length = this.length;
    for (var i = 0; i < length; i++) {
      if (i in this && !callback.call(instance, this[i], i, this)) {
        return false;
      }
    }
    return true;
  }));
}

Object.defineProperty(Array.prototype, 'extract', withValue(function Array$extract(index, count) {
  if (!count) {
    return this.slice(index);
  }
  return this.slice(index, index + count);
}));

if (!Array.prototype.filter) {
  Object.defineProperty(Array.prototype, 'filter', withValue(function Array$filter(callback, instance) {
    var length = this.length;
    var filtered = [];
    for (var i = 0; i < length; i++) {
      if (i in this) {
        var val = this[i];
        if (callback.call(instance, val, i, this)) {
          filtered.push(val);
        }
      }
    }
    return filtered;
  }));
}

if (!Array.prototype.forEach) {
  Object.defineProperty(Array.prototype, 'forEach', withValue(function Array$forEach(callback, instance) {
    var length = this.length;
    for (var i = 0; i < length; i++) {
      if (i in this) {
        callback.call(instance, this[i], i, this);
      }
    }
  }));
}

Object.defineProperty(Array.prototype, 'getEnumerator', withValue(function Array$getEnumerator() {
  return new ss.ArrayEnumerator(this);
}));

Object.defineProperty(Array.prototype, 'groupBy', withValue(function Array$groupBy(callback, instance) {
  var length = this.length;
  var groups = [];
  var keys = {};
  for (var i = 0; i < length; i++) {
    if (i in this) {
      var key = callback.call(instance, this[i], i);
      if (String.isNullOrEmpty(key)) {
        continue;
      }
      var items = keys[key];
      if (!items) {
        items = [];
        items.key = key;

        keys[key] = items;
        groups.add(items);
      }
      items.add(this[i]);
    }
  }
  return groups;
}));

Object.defineProperty(Array.prototype, 'index', withValue(function Array$index(callback, instance) {
  var length = this.length;
  var items = {};
  for (var i = 0; i < length; i++) {
    if (i in this) {
      var key = callback.call(instance, this[i], i);
      if (String.isNullOrEmpty(key)) {
        continue;
      }
      items[key] = this[i];
    }
  }
  return items;
}));

if (!Array.prototype.indexOf) {
  Object.defineProperty(Array.prototype, 'indexOf', withValue(function Array$indexOf(item, startIndex) {
    startIndex = startIndex || 0;
    var length = this.length;
    if (length) {
      for (var index = startIndex; index < length; index++) {
        if (this[index] === item) {
          return index;
        }
      }
    }
    return -1;
  }));
}

Object.defineProperty(Array.prototype, 'insert', withValue(function Array$insert(index, item) {
  this.splice(index, 0, item);
}));

Object.defineProperty(Array.prototype, 'insertRange', withValue(function Array$insertRange(index, items) {
  if (index === 0) {
    this.unshift.apply(this, items);
  }
  else {
    for (var i = 0; i < items.length; i++) {
      this.splice(index + i, 0, items[i]);
    }
  }
}));

if (!Array.prototype.map) {
  Object.defineProperty(Array.prototype, 'map', withValue(function Array$map(callback, instance) {
    var length = this.length;
    var mapped = new Array(length);
    for (var i = 0; i < length; i++) {
      if (i in this) {
        mapped[i] = callback.call(instance, this[i], i, this);
      }
    }
    return mapped;
  }));
}

Array.parse = function Array$parse(s) {
  return eval('(' + s + ')');
}

Object.defineProperty(Array.prototype, 'remove', withValue(function Array$remove(item) {
  var index = this.indexOf(item);
  if (index >= 0) {
    this.splice(index, 1);
    return true;
  }
  return false;
}));

Object.defineProperty(Array.prototype, 'removeAt', withValue(function Array$removeAt(index) {
  this.splice(index, 1);
}));

Object.defineProperty(Array.prototype, 'removeRange', withValue(function Array$removeRange(index, count) {
  return this.splice(index, count);
}));

if (!Array.prototype.some) {
  Object.defineProperty(Array.prototype, 'some', withValue(function Array$some(callback, instance) {
    var length = this.length;
    for (var i = 0; i < length; i++) {
      if (i in this && callback.call(instance, this[i], i, this)) {
        return true;
      }
    }
    return false;
  }));
}

Array.toArray = function Array$toArray(obj) {
  return Array.prototype.slice.call(obj);
}

RegExp.__typeName = 'RegExp';

RegExp.parse = function RegExp$parse(s) {
  if (s.startsWith('/')) {
    var endSlashIndex = s.lastIndexOf('/');
    if (endSlashIndex > 1) {
      var expression = s.substring(1, endSlashIndex);
      var flags = s.substr(endSlashIndex + 1);
      return new RegExp(expression, flags);
    }
  }

  return null;
}

Date.__typeName = 'Date';

Date.empty = null;

Date.get_now = function Date$get_now() {
  return new Date();
}

Date.get_today = function Date$get_today() {
  var d = new Date();
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

Date.isEmpty = function Date$isEmpty(d) {
  return (d === null) || (d.valueOf() === 0);
}

Date.prototype.format = function Date$format(format) {
  if (ss.isNullOrUndefined(format) || (format.length == 0) || (format == 'i')) {
    return this.toString();
  }
  if (format == 'id') {
    return this.toDateString();
  }
  if (format == 'it') {
    return this.toTimeString();
  }

  return this._netFormat(format, false);
}

Date.prototype.localeFormat = function Date$localeFormat(format) {
  if (ss.isNullOrUndefined(format) || (format.length == 0) || (format == 'i')) {
    return this.toLocaleString();
  }
  if (format == 'id') {
    return this.toLocaleDateString();
  }
  if (format == 'it') {
    return this.toLocaleTimeString();
  }

  return this._netFormat(format, true);
}

Date.prototype._netFormat = function Date$_netFormat(format, useLocale) {
  var dt = this;
  var dtf = useLocale ? ss.CultureInfo.CurrentCulture.dateFormat : ss.CultureInfo.InvariantCulture.dateFormat;

  if (format.length == 1) {
    switch (format) {
      case 'f': format = dtf.longDatePattern + ' ' + dtf.shortTimePattern; break;
      case 'F': format = dtf.dateTimePattern; break;

      case 'd': format = dtf.shortDatePattern; break;
      case 'D': format = dtf.longDatePattern; break;

      case 't': format = dtf.shortTimePattern; break;
      case 'T': format = dtf.longTimePattern; break;

      case 'g': format = dtf.shortDatePattern + ' ' + dtf.shortTimePattern; break;
      case 'G': format = dtf.shortDatePattern + ' ' + dtf.longTimePattern; break;

      case 'R': case 'r':
        dtf = ss.CultureInfo.InvariantCulture.dateFormat;
        format = dtf.gmtDateTimePattern;
        break;
      case 'u': format = dtf.universalDateTimePattern; break;
      case 'U':
        format = dtf.dateTimePattern;
        dt = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(),
          dt.getUTCHours(), dt.getUTCMinutes(), dt.getUTCSeconds(), dt.getUTCMilliseconds());
        break;

      case 's': format = dtf.sortableDateTimePattern; break;
    }
  }

  if (format.charAt(0) == '%') {
    format = format.substr(1);
  }

  if (!Date._formatRE) {
    Date._formatRE = /'.*?[^\\]'|dddd|ddd|dd|d|MMMM|MMM|MM|M|yyyy|yy|y|hh|h|HH|H|mm|m|ss|s|tt|t|fff|ff|f|zzz|zz|z/g;
  }

  var re = Date._formatRE;
  var sb = new ss.StringBuilder();

  re.lastIndex = 0;
  while (true) {
    var index = re.lastIndex;
    var match = re.exec(format);

    sb.append(format.slice(index, match ? match.index : format.length));
    if (!match) {
      break;
    }

    var fs = match[0];
    var part = fs;
    switch (fs) {
      case 'dddd':
        part = dtf.dayNames[dt.getDay()];
        break;
      case 'ddd':
        part = dtf.shortDayNames[dt.getDay()];
        break;
      case 'dd':
        part = dt.getDate().toString().padLeft(2, '0');
        break;
      case 'd':
        part = dt.getDate();
        break;
      case 'MMMM':
        part = dtf.monthNames[dt.getMonth()];
        break;
      case 'MMM':
        part = dtf.shortMonthNames[dt.getMonth()];
        break;
      case 'MM':
        part = (dt.getMonth() + 1).toString().padLeft(2, '0');
        break;
      case 'M':
        part = (dt.getMonth() + 1);
        break;
      case 'yyyy':
        part = dt.getFullYear();
        break;
      case 'yy':
        part = (dt.getFullYear() % 100).toString().padLeft(2, '0');
        break;
      case 'y':
        part = (dt.getFullYear() % 100);
        break;
      case 'h': case 'hh':
        part = dt.getHours() % 12;
        if (!part) {
          part = '12';
        }
        else if (fs == 'hh') {
          part = part.toString().padLeft(2, '0');
        }
        break;
      case 'HH':
        part = dt.getHours().toString().padLeft(2, '0');
        break;
      case 'H':
        part = dt.getHours();
        break;
      case 'mm':
        part = dt.getMinutes().toString().padLeft(2, '0');
        break;
      case 'm':
        part = dt.getMinutes();
        break;
      case 'ss':
        part = dt.getSeconds().toString().padLeft(2, '0');
        break;
      case 's':
        part = dt.getSeconds();
        break;
      case 't': case 'tt':
        part = (dt.getHours() < 12) ? dtf.amDesignator : dtf.pmDesignator;
        if (fs == 't') {
          part = part.charAt(0);
        }
        break;
      case 'fff':
        part = dt.getMilliseconds().toString().padLeft(3, '0');
        break;
      case 'ff':
        part = dt.getMilliseconds().toString().padLeft(3).substr(0, 2);
        break;
      case 'f':
        part = dt.getMilliseconds().toString().padLeft(3).charAt(0);
        break;
      case 'z':
        part = dt.getTimezoneOffset() / 60;
        part = ((part >= 0) ? '-' : '+') + Math.floor(Math.abs(part));
        break;
      case 'zz': case 'zzz':
        part = dt.getTimezoneOffset() / 60;
        part = ((part >= 0) ? '-' : '+') + Math.floor(Math.abs(part)).toString().padLeft(2, '0');
        if (fs == 'zzz') {
          part += dtf.timeSeparator + Math.abs(dt.getTimezoneOffset() % 60).toString().padLeft(2, '0');
        }
        break;
      default:
        if (part.charAt(0) == '\'') {
          part = part.substr(1, part.length - 2).replace(/\\'/g, '\'');
        }
        break;
    }
    sb.append(part);
  }

  return sb.toString();
}

Date.parseDate = function Date$parse(s) {
  var t = Date.parse(s);
  return isNaN(t) ? t : new Date(t);
}

Error.__typeName = 'Error';

Error.prototype.popStackFrame = function Error$popStackFrame() {
  if (ss.isNullOrUndefined(this.stack) ||
    ss.isNullOrUndefined(this.fileName) ||
    ss.isNullOrUndefined(this.lineNumber)) {
    return;
  }

  var stackFrames = this.stack.split('\n');
  var currentFrame = stackFrames[0];
  var pattern = this.fileName + ':' + this.lineNumber;
  while (!ss.isNullOrUndefined(currentFrame) &&
    currentFrame.indexOf(pattern) === -1) {
    stackFrames.shift();
    currentFrame = stackFrames[0];
  }

  var nextFrame = stackFrames[1];
  if (isNullOrUndefined(nextFrame)) {
    return;
  }

  var nextFrameParts = nextFrame.match(/@(.*):(\d+)$/);
  if (ss.isNullOrUndefined(nextFrameParts)) {
    return;
  }

  stackFrames.shift();
  this.stack = stackFrames.join("\n");
  this.fileName = nextFrameParts[1];
  this.lineNumber = parseInt(nextFrameParts[2]);
}

Error.createError = function Error$createError(message, errorInfo, innerException) {
  var e = new Error(message);
  if (errorInfo) {
    for (var v in errorInfo) {
      e[v] = errorInfo[v];
    }
  }
  if (innerException) {
    e.innerException = innerException;
  }

  e.popStackFrame();
  return e;
}

ss.Debug = window.Debug || function () { };
ss.Debug.__typeName = 'Debug';

if (!ss.Debug.writeln) {
  ss.Debug.writeln = function Debug$writeln(text) {
    if (window.console) {
      if (window.console.debug) {
        window.console.debug(text);
        return;
      }
      else if (window.console.log) {
        window.console.log(text);
        return;
      }
    }
    else if (window.opera &&
      window.opera.postError) {
      window.opera.postError(text);
      return;
    }
  }
}

ss.Debug._fail = function Debug$_fail(message) {
  ss.Debug.writeln(message);
  eval('debugger;');
}

ss.Debug.assert = function Debug$assert(condition, message) {
  if (!condition) {
    message = 'Assert failed: ' + message;
    if (confirm(message + '\r\n\r\nBreak into debugger?')) {
      ss.Debug._fail(message);
    }
  }
}

ss.Debug.fail = function Debug$fail(message) {
  ss.Debug._fail(message);
}

window.Type = Function;
Type.__typeName = 'Type';

window.__Namespace = function (name) {
  this.__typeName = name;
}
__Namespace.prototype = {
  __namespace: true,
  getName: function () {
    return this.__typeName;
  }
}

Type.registerNamespace = function Type$registerNamespace(name) {
  if (!window.__namespaces) {
    window.__namespaces = {};
  }
  if (!window.__rootNamespaces) {
    window.__rootNamespaces = [];
  }

  if (window.__namespaces[name]) {
    return;
  }

  var ns = window;
  var nameParts = name.split('.');

  for (var i = 0; i < nameParts.length; i++) {
    var part = nameParts[i];
    var nso = ns[part];
    if (!nso) {
      ns[part] = nso = new __Namespace(nameParts.slice(0, i + 1).join('.'));
      if (i == 0) {
        window.__rootNamespaces.add(nso);
      }
    }
    ns = nso;
  }

  window.__namespaces[name] = ns;
}

Type.prototype.registerClass = function Type$registerClass(name, baseType, interfaceType) {
  this.prototype.constructor = this;
  this.__typeName = name;
  this.__class = true;
  this.__baseType = baseType || Object;
  if (baseType) {
    this.__basePrototypePending = true;
  }

  if (interfaceType) {
    this.__interfaces = [];
    for (var i = 2; i < arguments.length; i++) {
      interfaceType = arguments[i];
      this.__interfaces.add(interfaceType);
    }
  }
}

Type.prototype.registerInterface = function Type$createInterface(name) {
  this.__typeName = name;
  this.__interface = true;
}

Type.prototype.registerEnum = function Type$createEnum(name, flags) {
  for (var field in this.prototype) {
    this[field] = this.prototype[field];
  }

  this.__typeName = name;
  this.__enum = true;
  if (flags) {
    this.__flags = true;
  }
}

Type.prototype.setupBase = function Type$setupBase() {
  if (this.__basePrototypePending) {
    var baseType = this.__baseType;
    if (baseType.__basePrototypePending) {
      baseType.setupBase();
    }

    for (var memberName in baseType.prototype) {
      var memberValue = baseType.prototype[memberName];
      if (!this.prototype[memberName]) {
        this.prototype[memberName] = memberValue;
      }
    }

    delete this.__basePrototypePending;
  }
}

if (!Type.prototype.resolveInheritance) {
  Type.prototype.resolveInheritance = Type.prototype.setupBase;
}

Type.prototype.initializeBase = function Type$initializeBase(instance, args) {
  if (this.__basePrototypePending) {
    this.setupBase();
  }

  if (!args) {
    this.__baseType.apply(instance);
  }
  else {
    this.__baseType.apply(instance, args);
  }
}

Type.prototype.callBaseMethod = function Type$callBaseMethod(instance, name, args) {
  var baseMethod = this.__baseType.prototype[name];
  if (!args) {
    return baseMethod.apply(instance);
  }
  else {
    return baseMethod.apply(instance, args);
  }
}

Type.prototype.get_baseType = function Type$get_baseType() {
  return this.__baseType || null;
}

Type.prototype.get_fullName = function Type$get_fullName() {
  return this.__typeName;
}

Type.prototype.get_name = function Type$get_name() {
  var fullName = this.__typeName;
  var nsIndex = fullName.lastIndexOf('.');
  if (nsIndex > 0) {
    return fullName.substr(nsIndex + 1);
  }
  return fullName;
}

Type.prototype.getInterfaces = function Type$getInterfaces() {
  return this.__interfaces;
}

Type.prototype.isInstanceOfType = function Type$isInstanceOfType(instance) {
  if (ss.isNullOrUndefined(instance)) {
    return false;
  }
  if ((this == Object) || (instance instanceof this)) {
    return true;
  }

  var type = Type.getInstanceType(instance);
  return this.isAssignableFrom(type);
}

Type.prototype.isAssignableFrom = function Type$isAssignableFrom(type) {
  if ((this == Object) || (this == type)) {
    return true;
  }
  if (this.__class) {
    var baseType = type.__baseType;
    while (baseType) {
      if (this == baseType) {
        return true;
      }
      baseType = baseType.__baseType;
    }
  }
  else if (this.__interface) {
    var interfaces = type.__interfaces;
    if (interfaces && interfaces.contains(this)) {
      return true;
    }

    var baseType = type.__baseType;
    while (baseType) {
      interfaces = baseType.__interfaces;
      if (interfaces && interfaces.contains(this)) {
        return true;
      }
      baseType = baseType.__baseType;
    }
  }
  return false;
}

Type.isClass = function Type$isClass(type) {
  return (type.__class == true);
}

Type.isEnum = function Type$isEnum(type) {
  return (type.__enum == true);
}

Type.isFlags = function Type$isFlags(type) {
  return ((type.__enum == true) && (type.__flags == true));
}

Type.isInterface = function Type$isInterface(type) {
  return (type.__interface == true);
}

Type.isNamespace = function Type$isNamespace(object) {
  return (object.__namespace == true);
}

Type.canCast = function Type$canCast(instance, type) {
  return type.isInstanceOfType(instance);
}

Type.safeCast = function Type$safeCast(instance, type) {
  if (type.isInstanceOfType(instance)) {
    return instance;
  }
  return null;
}

Type.getInstanceType = function Type$getInstanceType(instance) {
  var ctor = null;
  try {
    ctor = instance.constructor;
  }
  catch (ex) {
  }
  if (!ctor || !ctor.__typeName) {
    ctor = Object;
  }
  return ctor;
}

Type.getType = function Type$getType(typeName) {
  if (!typeName) {
    return null;
  }

  if (!Type.__typeCache) {
    Type.__typeCache = {};
  }

  var type = Type.__typeCache[typeName];
  if (!type) {
    type = eval(typeName);
    Type.__typeCache[typeName] = type;
  }
  return type;
}

Type.parse = function Type$parse(typeName) {
  return Type.getType(typeName);
}

ss.Delegate = function Delegate$() {
}
ss.Delegate.registerClass('Delegate');

ss.Delegate.empty = function () { }

ss.Delegate._contains = function Delegate$_contains(targets, object, method) {
  for (var i = 0; i < targets.length; i += 2) {
    if (targets[i] === object && targets[i + 1] === method) {
      return true;
    }
  }
  return false;
}

ss.Delegate._create = function Delegate$_create(targets) {
  var delegate = function () {
    if (targets.length == 2) {
      return targets[1].apply(targets[0], arguments);
    }
    else {
      var clone = targets.clone();
      for (var i = 0; i < clone.length; i += 2) {
        if (ss.Delegate._contains(targets, clone[i], clone[i + 1])) {
          clone[i + 1].apply(clone[i], arguments);
        }
      }
      return null;
    }
  };
  delegate._targets = targets;

  return delegate;
}

ss.Delegate.create = function Delegate$create(object, method) {
  if (!object) {
    return method;
  }
  return ss.Delegate._create([object, method]);
}

ss.Delegate.combine = function Delegate$combine(delegate1, delegate2) {
  if (!delegate1) {
    if (!delegate2._targets) {
      return ss.Delegate.create(null, delegate2);
    }
    return delegate2;
  }
  if (!delegate2) {
    if (!delegate1._targets) {
      return ss.Delegate.create(null, delegate1);
    }
    return delegate1;
  }

  var targets1 = delegate1._targets ? delegate1._targets : [null, delegate1];
  var targets2 = delegate2._targets ? delegate2._targets : [null, delegate2];

  return ss.Delegate._create(targets1.concat(targets2));
}

ss.Delegate.remove = function Delegate$remove(delegate1, delegate2) {
  if (!delegate1 || (delegate1 === delegate2)) {
    return null;
  }
  if (!delegate2) {
    return delegate1;
  }

  var targets = delegate1._targets;
  var object = null;
  var method;
  if (delegate2._targets) {
    object = delegate2._targets[0];
    method = delegate2._targets[1];
  }
  else {
    method = delegate2;
  }

  for (var i = 0; i < targets.length; i += 2) {
    if ((targets[i] === object) && (targets[i + 1] === method)) {
      if (targets.length == 2) {
        return null;
      }
      targets.splice(i, 2);
      return ss.Delegate._create(targets);
    }
  }

  return delegate1;
}

ss.Delegate.createExport = function Delegate$createExport(delegate, multiUse, name) {
  name = name || '__' + (new Date()).valueOf();

  window[name] = multiUse ? delegate : function () {
    try { delete window[name]; } catch (e) { window[name] = undefined; }
    delegate.apply(null, arguments);
  };

  return name;
}

ss.Delegate.deleteExport = function Delegate$deleteExport(name) {
  delete window[name];
}

ss.Delegate.clearExport = function Delegate$clearExport(name) {
  window[name] = ss.Delegate.empty;
}

ss.CultureInfo = function CultureInfo$(name, numberFormat, dateFormat) {
  this.name = name;
  this.numberFormat = numberFormat;
  this.dateFormat = dateFormat;
}
ss.CultureInfo.registerClass('CultureInfo');

ss.CultureInfo.InvariantCulture = new ss.CultureInfo('en-US',
  {
    naNSymbol: 'NaN',
    negativeSign: '-',
    positiveSign: '+',
    negativeInfinityText: '-Infinity',
    positiveInfinityText: 'Infinity',

    percentSymbol: '%',
    percentGroupSizes: [3],
    percentDecimalDigits: 2,
    percentDecimalSeparator: '.',
    percentGroupSeparator: ',',
    percentPositivePattern: '{0} %',
    percentNegativePattern: '-{0} %',

    currencySymbol: '$',
    currencyGroupSizes: [3],
    currencyDecimalDigits: 2,
    currencyDecimalSeparator: '.',
    currencyGroupSeparator: ',',
    currencyNegativePattern: '(${0})',
    currencyPositivePattern: '${0}',

    numberGroupSizes: [3],
    numberDecimalDigits: 2,
    numberDecimalSeparator: '.',
    numberGroupSeparator: ','
  },
  {
    amDesignator: 'AM',
    pmDesignator: 'PM',

    dateSeparator: '/',
    timeSeparator: ':',

    gmtDateTimePattern: 'ddd, dd MMM yyyy HH:mm:ss \'GMT\'',
    universalDateTimePattern: 'yyyy-MM-dd HH:mm:ssZ',
    sortableDateTimePattern: 'yyyy-MM-ddTHH:mm:ss',
    dateTimePattern: 'dddd, MMMM dd, yyyy h:mm:ss tt',

    longDatePattern: 'dddd, MMMM dd, yyyy',
    shortDatePattern: 'M/d/yyyy',

    longTimePattern: 'h:mm:ss tt',
    shortTimePattern: 'h:mm tt',

    firstDayOfWeek: 0,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortDayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    minimizedDayNames: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],

    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ''],
    shortMonthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', '']
  });
ss.CultureInfo.CurrentCulture = ss.CultureInfo.InvariantCulture;

ss.IEnumerator = function IEnumerator$() { };
ss.IEnumerator.prototype = {
  get_current: null,
  moveNext: null,
  reset: null
}

ss.IEnumerator.getEnumerator = function ss_IEnumerator$getEnumerator(enumerable) {
  if (enumerable) {
    return enumerable.getEnumerator ? enumerable.getEnumerator() : new ss.ArrayEnumerator(enumerable);
  }
  return null;
}

ss.IEnumerator.registerInterface('IEnumerator');

ss.IEnumerable = function IEnumerable$() { };
ss.IEnumerable.prototype = {
  getEnumerator: null
}
ss.IEnumerable.registerInterface('IEnumerable');

ss.ArrayEnumerator = function ArrayEnumerator$(array) {
  this._array = array;
  this._index = -1;
  this.current = null;
}
ss.ArrayEnumerator.prototype = {
  moveNext: function ArrayEnumerator$moveNext() {
    this._index++;
    this.current = this._array[this._index];
    return (this._index < this._array.length);
  },
  reset: function ArrayEnumerator$reset() {
    this._index = -1;
    this.current = null;
  }
}

ss.ArrayEnumerator.registerClass('ArrayEnumerator', null, ss.IEnumerator);

ss.IDisposable = function IDisposable$() { };
ss.IDisposable.prototype = {
  dispose: null
}
ss.IDisposable.registerInterface('IDisposable');

ss.StringBuilder = function StringBuilder$(s) {
  this._parts = ss.isNullOrUndefined(s) || s === '' ? [] : [s];
  this.isEmpty = this._parts.length == 0;
}
ss.StringBuilder.prototype = {
  append: function StringBuilder$append(s) {
    if (!ss.isNullOrUndefined(s) && s !== '') {
      this._parts.add(s);
      this.isEmpty = false;
    }
    return this;
  },

  appendLine: function StringBuilder$appendLine(s) {
    this.append(s);
    this.append('\r\n');
    this.isEmpty = false;
    return this;
  },

  clear: function StringBuilder$clear() {
    this._parts = [];
    this.isEmpty = true;
  },

  toString: function StringBuilder$toString(s) {
    return this._parts.join(s || '');
  }
};

ss.StringBuilder.registerClass('StringBuilder');

ss.EventArgs = function EventArgs$() {
}
ss.EventArgs.registerClass('EventArgs');

ss.EventArgs.Empty = new ss.EventArgs();

if (!window.XMLHttpRequest) {
  window.XMLHttpRequest = function () {
    var progIDs = ['Msxml2.XMLHTTP', 'Microsoft.XMLHTTP'];

    for (var i = 0; i < progIDs.length; i++) {
      try {
        var xmlHttp = new ActiveXObject(progIDs[i]);
        return xmlHttp;
      }
      catch (ex) {
      }
    }

    return null;
  }
}

ss.parseXml = function (markup) {
  try {
    if (DOMParser) {
      var domParser = new DOMParser();
      return domParser.parseFromString(markup, 'text/xml');
    }
    else {
      var progIDs = ['Msxml2.DOMDocument.3.0', 'Msxml2.DOMDocument'];

      for (var i = 0; i < progIDs.length; i++) {
        var xmlDOM = new ActiveXObject(progIDs[i]);
        xmlDOM.async = false;
        xmlDOM.loadXML(markup);
        xmlDOM.setProperty('SelectionLanguage', 'XPath');

        return xmlDOM;
      }
    }
  }
  catch (ex) {
  }

  return null;
}

ss.CancelEventArgs = function CancelEventArgs$() {
  ss.CancelEventArgs.initializeBase(this);
  this.cancel = false;
}
ss.CancelEventArgs.registerClass('CancelEventArgs', ss.EventArgs);

ss.Tuple = function (first, second, third) {
  this.first = first;
  this.second = second;
  if (arguments.length == 3) {
    this.third = third;
  }
}
ss.Tuple.registerClass('Tuple');

ss.Observable = function (v) {
  this._v = v;
  this._observers = null;
}
ss.Observable.prototype = {

  getValue: function () {
    this._observers = ss.Observable._captureObservers(this._observers);
    return this._v;
  },
  setValue: function (v) {
    if (this._v !== v) {
      this._v = v;

      var observers = this._observers;
      if (observers) {
        this._observers = null;
        ss.Observable._invalidateObservers(observers);
      }
    }
  }
};

ss.Observable._observerStack = [];
ss.Observable._observerRegistration = {
  dispose: function () {
    ss.Observable._observerStack.pop();
  }
}
ss.Observable.registerObserver = function (o) {
  ss.Observable._observerStack.push(o);
  return ss.Observable._observerRegistration;
}
ss.Observable._captureObservers = function (observers) {
  var registeredObservers = ss.Observable._observerStack;
  var observerCount = registeredObservers.length;

  if (observerCount) {
    observers = observers || [];
    for (var i = 0; i < observerCount; i++) {
      var observer = registeredObservers[i];
      if (!observers.contains(observer)) {
        observers.push(observer);
      }
    }
    return observers;
  }
  return null;
}
ss.Observable._invalidateObservers = function (observers) {
  for (var i = 0, len = observers.length; i < len; i++) {
    observers[i].invalidateObserver();
  }
}

ss.Observable.registerClass('Observable');


ss.ObservableCollection = function (items) {
  this._items = items || [];
  this._observers = null;
}
ss.ObservableCollection.prototype = {

  get_item: function (index) {
    this._observers = ss.Observable._captureObservers(this._observers);
    return this._items[index];
  },
  set_item: function (index, item) {
    this._items[index] = item;
    this._updated();
  },
  get_length: function () {
    this._observers = ss.Observable._captureObservers(this._observers);
    return this._items.length;
  },
  add: function (item) {
    this._items.push(item);
    this._updated();
  },
  clear: function () {
    this._items.clear();
    this._updated();
  },
  contains: function (item) {
    return this._items.contains(item);
  },
  getEnumerator: function () {
    this._observers = ss.Observable._captureObservers(this._observers);
    return this._items.getEnumerator();
  },
  indexOf: function (item) {
    return this._items.indexOf(item);
  },
  insert: function (index, item) {
    this._items.insert(index, item);
    this._updated();
  },
  remove: function (item) {
    if (this._items.remove(item)) {
      this._updated();
      return true;
    }
    return false;
  },
  removeAt: function (index) {
    this._items.removeAt(index);
    this._updated();
  },
  toArray: function () {
    return this._items;
  },
  _updated: function () {
    var observers = this._observers;
    if (observers) {
      this._observers = null;
      ss.Observable._invalidateObservers(observers);
    }
  }
}
ss.ObservableCollection.registerClass('ObservableCollection', null, ss.IEnumerable);

ss.Task = function (result) {
  this._continuations = ss.isValue(result) ?
    (this.status = 'done', null) :
    (this.status = 'pending', []);
  this.result = result;
  this.error = null;
}
ss.Task.prototype = {
  get_completed: function () {
    return this.status != 'pending';
  },
  continueWith: function (continuation) {
    if (this._continuations) {
      this._continuations.push(continuation);
    }
    else {
      var self = this;
      setTimeout(function () { continuation(self); }, 0);
    }
    return this;
  },
  done: function (callback) {
    return this.continueWith(function (t) {
      if (t.status == 'done') {
        callback(t.result);
      }
    });
  },
  fail: function (callback) {
    return this.continueWith(function (t) {
      if (t.status == 'failed') {
        callback(t.error);
      }
    });
  },
  then: function (doneCallback, failCallback) {
    return this.continueWith(function (t) {
      t.status == 'done' ? doneCallback(t.result) : failCallback(t.error);
    });
  },
  _update: function (result, error) {
    if (this.status == 'pending') {
      if (error) {
        this.error = error;
        this.status = 'failed';
      }
      else {
        this.result = result;
        this.status = 'done';
      }

      var continuations = this._continuations;
      this._continuations = null;

      for (var i = 0, c = continuations.length; i < c; i++) {
        continuations[i](this);
      }
    }
  }
};
ss.Task._join = function (tasks, any) {
  tasks = Array.toArray(tasks);
  ss.Debug.assert(tasks.length > 1);

  var count = tasks.length;

  var interval = 0;
  if ((count > 1) && (typeof tasks[0] == 'number')) {
    interval = tasks[0];
    tasks = tasks.slice(1);
    count--;
  }

  var joinTask = new ss.Task();
  var seen = 0;

  function continuation(t) {
    if (joinTask.status == 'pending') {
      seen++;
      if (any) {
        joinTask._update(t);
      }
      else if (seen == count) {
        joinTask._update(true);
      }
    }
  }

  function timeout() {
    if (joinTask.status == 'pending') {
      if (any) {
        joinTask._update(null);
      }
      else {
        joinTask._update(false);
      }
    }
  }

  if (interval != 0) {
    setTimeout(timeout, interval);
  }

  for (var i = 0; i < count; i++) {
    tasks[i].continueWith(continuation);
  }

  return joinTask;
}
ss.Task.all = function () {
  return ss.Task._join(arguments, false);
}
ss.Task.any = function () {
  return ss.Task._join(arguments, true);
}
ss.Task.delay = function (timeout) {
  var timerTask = new ss.Task();

  setTimeout(function () {
    timerTask._update(true);
  }, timeout);

  return timerTask;
}


ss.Deferred = function (result) {
  this.task = new ss.Task(result);
}
ss.Deferred.prototype = {
  resolve: function (result) {
    this.task._update(result);
  },
  reject: function (error) {
    this.task._update(null, error || new Error());
  }
};

ss.Deferred.registerClass('Deferred');
ss.Task.registerClass('Task');

ss.IApplication = function () { };
ss.IApplication.registerInterface('IApplication');

ss.IContainer = function () { };
ss.IContainer.registerInterface('IContainer');

ss.IObjectFactory = function () { };
ss.IObjectFactory.registerInterface('IObjectFactory');

ss.IEventManager = function () { };
ss.IEventManager.registerInterface('IEventManager');

ss.IInitializable = function () { };
ss.IInitializable.registerInterface('IInitializable');


// numpy javascript equivalent
// https://github.com/fernandezajp/PyExtJS

(function () {

  /**
   * Helper function for adding properties with Object.defineProperty
   * Copied from example on: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
   */
  function withValue(value) {
    var d = withValue.d || (
      withValue.d = {
        enumerable: false,
        writable: true,
        configurable: false,
        value: null
      }
    );
    d.value = value;
    return d;
  }

  window.numpy = function numpy() {
  }
  numpy.linspace = function numpy$linspace(start, stop, num) {
    if (num == null) {
      num = 50;
    }
    var tmp = [];
    var step = (stop - start) / (num - 1);
    var i = start;
    for (var c = 0; c < num - 1; c++) {
      tmp.add(i);
      i = i + step;
    }
    tmp.add(stop);
    var array = new Array(tmp.length);
    for (var c = 0; c < tmp.length; c++) {
      array[c] = tmp[c];
    }
    return array;
  }
  numpy.logspace = function numpy$logspace(start, stop, num, endpoint, numericBase) {
    if (endpoint == null) {
      endpoint = true;
    }
    if (numericBase == null) {
      numericBase = 10;
    }
    var y = numpy.linspace(start, stop, num);
    return numpy.power(numericBase, y);
  }
  numpy.power = function numpy$power(bases, exponents) {
    var basesType = Type.getInstanceType(bases).get_name();
    var exponentsType = Type.getInstanceType(exponents).get_name();
    if (basesType === 'Array' && exponentsType === 'Array') {
      var basesArray = bases;
      var ret = new Array(basesArray.length);
      var baseArray = exponents;
      for (var i = 0; i < basesArray.length; i++) {
        ret[i] = Math.pow(basesArray[i], baseArray[i]);
      }
      return ret;
    }
    if (basesType === 'Array' && exponentsType === 'Number') {
      var basesArray = bases;
      var ret = new Array(basesArray.length);
      var bbase = exponents;
      for (var i = 0; i < basesArray.length; i++) {
        ret[i] = Math.pow(basesArray[i], bbase);
      }
      return ret;
    }
    if (basesType === 'Number' && exponentsType === 'Number') {
      var bbase = bases;
      var exponent = exponents;
      return Math.pow(bbase, exponent);
    }
    if (basesType === 'Number' && exponentsType === 'Array') {
      var bbase = bases;
      var exponentsArray = exponents;
      var ret = new Array(exponentsArray.length);
      for (var i = 0; i < exponentsArray.length; i++) {
        ret[i] = Math.pow(bbase, exponentsArray[i]);
      }
      return ret;
    }
    return 0;
  }
  numpy.exp = function numpy$exp(x) {
    var type = Type.getInstanceType(x).get_name();
    switch (type) {
      case 'Array':
        var inputarray = x;
        var response = new Array(inputarray.length);
        for (var i = 0; i < inputarray.length; i++) {
          response[i] = Math.exp(inputarray[i]);
        }
        return response;
      default:
        var input = x;
        return Math.exp(input);
    }
  }
  numpy.arange = function numpy$arange(start, stop, step) {
    var tmp = [];
    if (stop == null && step == null) {
      for (var i = 0; i < start; i++) {
        tmp.add(i);
      }
      var opt1 = new Array(tmp.length);
      for (var i = 0; i < tmp.length; i++) {
        opt1[i] = tmp[i];
      }
      return opt1;
    }
    if (step == null) {
      for (var i = start; i < stop; i++) {
        tmp.add(i);
      }
      var opt2 = new Array(tmp.length);
      for (var i = 0; i < tmp.length; i++) {
        opt2[i] = tmp[i];
      }
      return opt2;
    }
    for (var i = start; i < stop; i = i + step) {
      tmp.add(i);
    }
    var opt3 = new Array(tmp.length);
    for (var i = 0; i < tmp.length; i++) {
      opt3[i] = tmp[i];
    }
    return opt3;
  }
  numpy.zeros = function numpy$zeros(shape) {
    var type = Type.getInstanceType(shape).get_name();
    switch (type) {
      case 'Array':
        var shapeArray = shape;
        var shapeObjectArray = shape;
        var mult = 1;
        for (var i = 0; i < shapeArray.length; i++) {
          mult *= shapeArray[i];
        }
        var retArray = new Array(mult);
        for (var i = 0; i < mult; i++) {
          retArray[i] = 0;
        }
        return numpy._grouparray(numpy._inversearray(shapeObjectArray), retArray);
      default:
        var input = shape;
        var ret = new Array(input);
        for (var i = 0; i < input; i++) {
          ret[i] = 0;
        }
        return ret;
    }
  }
  numpy.ones = function numpy$ones(shape) {
    var type = Type.getInstanceType(shape).get_name();
    switch (type) {
      case 'Array':
        var shapeArray = shape;
        var shapeObjectArray = shape;
        var mult = 1;
        for (var i = 0; i < shapeArray.length; i++) {
          mult *= shapeArray[i];
        }
        var retArray = new Array(mult);
        for (var i = 0; i < mult; i++) {
          retArray[i] = 1;
        }
        return numpy._grouparray(numpy._inversearray(shapeObjectArray), retArray);
      default:
        var input = shape;
        var ret = new Array(input);
        for (var i = 0; i < input; i++) {
          ret[i] = 1;
        }
        return ret;
    }
  }
  numpy.polyfit = function numpy$polyfit(x, y, deg) {
    var data = new Array(x.length);
    for (var i = 0; i < x.length; i++) {
      data[i] = new PolyRegression.Pair(x[i], y[i]);
    }
    var polysolve = new PolyRegression.Matrix();
    var coefs = polysolve.computeCoefficients(data, deg);
    var left = 0;
    var right = coefs.length - 1;
    while (left < right) {
      var temp = coefs[left];
      coefs[left] = coefs[right];
      coefs[right] = temp;
      left++;
      right--;
    }
    return coefs;
  }
  numpy.array = function numpy$array(a) {
    return a;
  }
  numpy.getShape = function numpy$getShape(a) {
    /// <param name="a" type="Array" elementType="Object">
    /// </param>
    /// <returns type="Array" elementType="Number" elementInteger="true"></returns>
    var obj = a.shape;
    return Array.toArray(obj);
  }
  numpy.reshape = function numpy$reshape(a, shape) {
    var assign = true;
    if (this.constructor.name !== 'Function') {
      if (Type.getInstanceType(a).get_name() === 'Array') {
        shape = Array.toArray(a);
      }
      else {
        if (arguments.length > 0) {
          shape = Array.toArray(arguments);
        }
      }
      a = this;
      assign = false;
    }
    var array = Array.toArray(a);
    var objlist = [];
    numpy._plainarray(objlist, array);
    var plain = new Array(objlist.length);
    for (var i = 0; i < objlist.length; i++) {
      plain[i] = objlist[i];
    }
    var fixedshape = numpy._inversearray(Array.toArray(shape));
    var response = numpy._grouparray(Array.toArray(fixedshape), plain);
    if (assign) {
      a.clear();
      for (var i = 0; i < response.length; i++) {
        a.push(response[i]);
      }
    }
    return response;
  }
  numpy._isAligned = function numpy$_isAligned(a, b) {
    /// <param name="a" type="Array" elementType="Object">
    /// </param>
    /// <param name="b" type="Array" elementType="Object">
    /// </param>
    /// <returns type="Boolean"></returns>
    var A_shape = numpy.getShape(a);
    var B_shape = numpy.getShape(b);
    if (A_shape.length !== B_shape.length) {
      return false;
    }
    for (var i = A_shape.length - 1; i > 0; i = i - 2) {
      if (!i) {
        if (A_shape[i] !== B_shape[i]) {
          return false;
        }
      }
      if (A_shape[i] !== B_shape[i - 1]) {
        return false;
      }
    }
    return true;
  }
  numpy.ravel = function numpy$ravel(a) {
    var array = null;
    if (a == null) {
      array = this;
    }
    else {
      array = a;
    }
    var objlist = [];
    numpy._plainarray(objlist, array);
    var plain = new Array(objlist.length);
    for (var i = 0; i < objlist.length; i++) {
      plain[i] = objlist[i];
    }
    return plain;
  }
  numpy.gettype = function numpy$gettype(obj) {
    var paramType = Type.getInstanceType(obj).get_name();
    switch (paramType) {
      case 'Number':
        return paramType;
      case 'Array':
        var array = obj;
        var objlist = [];
        numpy._plainarray(objlist, array);
        return Type.getInstanceType(objlist[0]).get_name();
      default:
        return 'undefined';
    }
  }
  numpy._inversearray = function numpy$_inversearray(array) {
    var newarray = new Array(array.length);
    var j = array.length - 1;
    for (var i = 0; i < array.length; i++) {
      newarray[i] = array[j--];
    }
    return newarray;
  }
  numpy._plainarray = function numpy$_plainarray(list, a) {
    var $enum1 = ss.IEnumerator.getEnumerator(a);
    while ($enum1.moveNext()) {
      var item = $enum1.current;
      if (Type.getInstanceType(item).get_name() === 'Array') {
        numpy._plainarray(list, item);
      }
      else {
        list.add(item);
      }
    }
  }
  numpy._grouparray = function numpy$_grouparray(group, array) {
    if (group.length > 1) {
      var objlist = [];
      var size = group[0];
      for (var i = 0; i < array.length;) {
        var tmp = new Array(size);
        for (var j = 0; j < size; j++) {
          tmp[j] = array[i++];
        }
        objlist.add(tmp);
      }
      var newgroupcount = new Array(group.length - 1);
      for (var i = 1; i < group.length; i++) {
        newgroupcount[i - 1] = group[i];
      }
      var newgroup = new Array(objlist.length);
      for (var i = 0; i < newgroup.length; i++) {
        newgroup[i] = objlist[i];
      }
      return numpy._grouparray(newgroupcount, newgroup);
    }
    else {
      var size = group[0];
      var newgroup = new Array(size);
      for (var i = 0; i < size; i++) {
        newgroup[i] = array[i];
      }
      return newgroup;
    }
  }
  numpy.getrandom = function numpy$getrandom(size) {
    if (size == null) {
      return Math.random();
    }
    var paramType = Type.getInstanceType(size).get_name();
    switch (paramType) {
      case 'Number':
        var singlesize = size;
        var retsinglearray = new Array(singlesize);
        for (var i = 0; i < singlesize; i++) {
          retsinglearray[i] = Math.random();
        }
        return retsinglearray;
        break;
      case 'Array':
        var sizeArray = size;
        var mult = 1;
        for (var i = 0; i < sizeArray.length; i++) {
          mult *= sizeArray[i];
        }
        var ret = new Array(mult);
        for (var i = 0; i < mult; i++) {
          ret[i] = Math.random();
        }
        return numpy._grouparray(sizeArray, ret);
        break;
    }
    return 0;
  }
  numpy.dot = function numpy$dot(a, b) {
    if (b == null) {
      b = a;
      a = this;
    }
    if (Type.getInstanceType(a).get_name() === 'Number' && Type.getInstanceType(b).get_name() === 'Array') {
      var b_ndim = numpy.getShape(b).length;
      if (b_ndim === 2) {
        var b_rows = numpy.getShape(b)[0];
        var b_cols = numpy.getShape(b)[1];
        var result = numpy.ones([b_rows, b_cols]);
        for (var br = 0; br < b_rows; br++) {
          for (var bc = 0; bc < b_cols; bc++) {
            result[br][bc] = ((Array.toArray((b)[br]))[bc]) * a;
          }
        }
        return result;
      }
    }
    if (Type.getInstanceType(a).get_name() === 'Array' && Type.getInstanceType(b).get_name() === 'Number') {
      var a_ndim = numpy.getShape(a).length;
      if (a_ndim === 2) {
        var a_rows = numpy.getShape(a)[0];
        var a_cols = numpy.getShape(a)[1];
        var result = numpy.ones([a_rows, a_cols]);
        for (var ar = 0; ar < a_rows; ar++) {
          for (var ac = 0; ac < a_cols; ac++) {
            result[ar][ac] = ((Array.toArray((a)[ar]))[ac]) * b;
          }
        }
        return result;
      }
    }
    if (Type.getInstanceType(a).get_name() === 'Array' && Type.getInstanceType(b).get_name() === 'Array') {
      var a_ndim = numpy.getShape(a).length;
      var b_ndim = numpy.getShape(b).length;
      if ((a_ndim === 2) && (b_ndim === 2)) {
        return numpy._dotMatrix(a, b);
      }
      else {
        return null;
      }
    }
    return null;
  }
  numpy.add = function numpy$add(a, b) {
    if (b == null) {
      b = a;
      a = this;
    }
    if (Type.getInstanceType(a).get_name() === 'Array' && Type.getInstanceType(b).get_name() === 'Array') {
      var a_ndim = numpy.getShape(a).length;
      var b_ndim = numpy.getShape(b).length;
      if ((a_ndim === 2) && (b_ndim === 2)) {
        return numpy._addMatrix(a, b);
      }
    }
    return null;
  }
  numpy._tupleCombination = function numpy$_tupleCombination(array) {
    var cardinality = 1;
    for (var i = 0; i < array.length; i++) {
      cardinality *= array[i];
    }
    var result = new Array(cardinality);
    for (var i = 0; i < result.length; i++) {
      result[i] = new Array(array.length);
    }
    for (var j = array.length - 1; j >= 0; j--) {
      var each = 1;
      for (var e = j + 1; e < array.length; e++) {
        each *= array[e];
      }
      var step = 0;
      var val = 0;
      for (var i = 0; i < cardinality; i++) {
        step++;
        var arrayValue = (Type.safeCast(result[i], Object));
        arrayValue[j] = val;
        if (step === each) {
          val = (val + 1) % array[j];
          step = 0;
        }
      }
    }
    return result;
  }
  numpy._getMatrixFromArray = function numpy$_getMatrixFromArray(matrix, positions, shape, numElements) {
    var m_shape = numpy.getShape(matrix);
    var Nx = m_shape[0];
    var Ny = m_shape[1];
    var plainarray = matrix.ravel();
    var response = numpy.zeros(numElements);
    var value = 0;
    for (var i = 0; i < positions.length; i++) {
      var tmp = 1;
      for (var j = i + 1; j < shape.length; j++) {
        tmp *= shape[j];
      }
      tmp *= numElements * positions[i];
      value += tmp;
    }
    var pos = 0;
    for (var i = value; i < numElements + value; i++) {
      response[pos++] = plainarray[i];
    }
    return response;
  }
  numpy._dotMatrix = function numpy$_dotMatrix(a, b) {
    if (numpy._isAligned(a, b)) {
      var nDimA = a.ndim;
      var nDimB = b.ndim;
      var a_rows = numpy.getShape(a)[0];
      var a_cols = numpy.getShape(a)[1];
      var b_rows = numpy.getShape(b)[0];
      var b_cols = numpy.getShape(b)[1];
      var c = numpy.zeros([a_rows, b_cols + 1]);
      var matrixC = numpy.reshape(c, [a_rows, b_cols]);
      for (var ar = 0; ar < a_rows; ar++) {
        for (var bc = 0; bc < b_cols; bc++) {
          var value = 0;
          for (var ac = 0; ac < a_cols; ac++) {
            var A_ar_ac = (Array.toArray(a[ar]))[ac];
            var B_ac_ar = (Array.toArray(b[ac]))[bc];
            value += A_ar_ac * B_ac_ar;
          }
          matrixC[ar][bc] = value;
        }
      }
      return matrixC;
    }
    return null;
  }
  numpy._addMatrix = function numpy$_addMatrix(a, b) {
    if (numpy._isAligned(a, b)) {
      var nDimA = a.ndim;
      var nDimB = b.ndim;
      var a_rows = numpy.getShape(a)[0];
      var a_cols = numpy.getShape(a)[1];
      var b_rows = numpy.getShape(b)[0];
      var b_cols = numpy.getShape(b)[1];
      var zeros = numpy.zeros([a_rows, b_cols + 1]);
      var matrixC = numpy.reshape(zeros, [a_rows, b_cols]);
      for (var r = 0; r < a_rows; r++) {
        for (var c = 0; c < b_cols; c++) {
          var A_ar_ac = (Array.toArray(a[r]))[c];
          var B_br_bc = (Array.toArray(b[r]))[c];
          matrixC[r][c] = A_ar_ac + B_br_bc;
        }
      }
      return matrixC;
    }
    return null;
  }
  numpy.concatenate = function numpy$concatenate(pparams) {
    if (arguments.length > 1) {
      var join = [];
      for (var i = 0; i < arguments.length; i++) {
        var obj = arguments[i];
        switch (Type.getInstanceType(obj).get_name()) {
          case 'Array':
            var objArray = obj;
            for (var j = 0; j < objArray.length; j++) {
              join.add(objArray[j]);
            }
            break;
          default:
            join.add(obj);
            break;
        }
      }
      return join;
    }
    return pparams;
  }
  numpy.sin = function numpy$sin(value) {
    var type = Type.getInstanceType(value).get_name();
    switch (type) {
      case 'Array':
        var shape = numpy.getShape(value);
        var data = value.ravel();
        for (var i = 0; i < data.length; i++) {
          data[i] = Math.sin(data[i]);
        }
        return numpy.reshape(data, shape);
      default:
        return Math.sin(value);
    }
  }
  numpy.cos = function numpy$cos(value) {
    var type = Type.getInstanceType(value).get_name();
    switch (type) {
      case 'Array':
        var shape = numpy.getShape(value);
        var data = value.ravel();
        for (var i = 0; i < data.length; i++) {
          data[i] = Math.cos(data[i]);
        }
        return numpy.reshape(data, shape);
      default:
        return Math.cos(value);
    }
  }
  numpy.tan = function numpy$tan(value) {
    var type = Type.getInstanceType(value).get_name();
    switch (type) {
      case 'Array':
        var shape = numpy.getShape(value);
        var data = value.ravel();
        for (var i = 0; i < data.length; i++) {
          data[i] = Math.tan(data[i]);
        }
        return numpy.reshape(data, shape);
      default:
        return Math.tan(value);
    }
  }
  numpy.arcsin = function numpy$arcsin(value) {
    var type = Type.getInstanceType(value).get_name();
    switch (type) {
      case 'Array':
        var shape = numpy.getShape(value);
        var data = value.ravel();
        for (var i = 0; i < data.length; i++) {
          data[i] = Math.asin(data[i]);
        }
        return numpy.reshape(data, shape);
      default:
        return Math.asin(value);
    }
  }
  numpy.arccos = function numpy$arccos(value) {
    var type = Type.getInstanceType(value).get_name();
    switch (type) {
      case 'Array':
        var shape = numpy.getShape(value);
        var data = value.ravel();
        for (var i = 0; i < data.length; i++) {
          data[i] = Math.acos(data[i]);
        }
        return numpy.reshape(data, shape);
      default:
        return Math.acos(value);
    }
  }
  numpy.arctan = function numpy$arctan(value) {
    var type = Type.getInstanceType(value).get_name();
    switch (type) {
      case 'Array':
        var shape = numpy.getShape(value);
        var data = value.ravel();
        for (var i = 0; i < data.length; i++) {
          data[i] = Math.atan(data[i]);
        }
        return numpy.reshape(data, shape);
      default:
        return Math.atan(value);
    }
  }
  numpy.hypot = function numpy$hypot(x, y) {
    var xtype = Type.getInstanceType(x).get_name();
    var ytype = Type.getInstanceType(y).get_name();
    if ((xtype === 'Number') && (ytype === 'Number')) {
      return Math.sqrt((x) * (x) + (y) * (y));
    }
    if ((xtype === 'Number') && (ytype === 'Array')) {
      var yshape = numpy.getShape(y);
      var data = y.ravel();
      for (var i = 0; i < data.length; i++) {
        data[i] = Math.sqrt((x) * (x) + (data[i]) * (data[i]));
      }
      return numpy.reshape(data, yshape);
    }
    if ((xtype === 'Array') && (ytype === 'Number')) {
      var xshape = numpy.getShape(x);
      var data = x.ravel();
      for (var i = 0; i < data.length; i++) {
        data[i] = Math.sqrt((data[i]) * (data[i]) + (y) * (y));
      }
      return numpy.reshape(data, xshape);
    }
    if ((xtype === 'Array') && (ytype === 'Array')) {
      var xshape = numpy.getShape(x);
      var xdata = x.ravel();
      var yshape = numpy.getShape(y);
      var ydata = y.ravel();
      var data = numpy.arange(xdata.length);
      for (var i = 0; i < xdata.length; i++) {
        data[i] = Math.sqrt((xdata[i]) * (xdata[i]) + (ydata[i]) * (ydata[i]));
      }
      return numpy.reshape(Array.toArray(data), xshape);
    }
    return null;
  }
  numpy.arctan2 = function numpy$arctan2(x, y) {
    var xtype = Type.getInstanceType(x).get_name();
    var ytype = Type.getInstanceType(y).get_name();
    if ((xtype === 'Number') && (ytype === 'Number')) {
      return Math.atan2(x, y);
    }
    if ((xtype === 'Number') && (ytype === 'Array')) {
      var yshape = numpy.getShape(y);
      var data = y.ravel();
      for (var i = 0; i < data.length; i++) {
        data[i] = Math.atan2(x, data[i]);
      }
      return numpy.reshape(data, yshape);
    }
    if ((xtype === 'Array') && (ytype === 'Number')) {
      var xshape = numpy.getShape(x);
      var data = x.ravel();
      for (var i = 0; i < data.length; i++) {
        data[i] = Math.atan2(data[i], y);
      }
      return numpy.reshape(data, xshape);
    }
    if ((xtype === 'Array') && (ytype === 'Array')) {
      var xshape = numpy.getShape(x);
      var xdata = x.ravel();
      var yshape = numpy.getShape(y);
      var ydata = y.ravel();
      var data = numpy.arange(xdata.length);
      for (var i = 0; i < xdata.length; i++) {
        data[i] = Math.atan2(xdata[i], ydata[i]);
      }
      return numpy.reshape(Array.toArray(data), xshape);
    }
    return null;
  }
  numpy.degrees = function numpy$degrees(value) {
    var type = Type.getInstanceType(value).get_name();
    switch (type) {
      case 'Array':
        var shape = numpy.getShape(value);
        var data = value.ravel();
        for (var i = 0; i < data.length; i++) {
          data[i] = (data[i]) * (180 / Math.PI);
        }
        return numpy.reshape(data, shape);
      default:
        return (value) * (180 / Math.PI);
    }
  }
  numpy.radians = function numpy$radians(value) {
    var type = Type.getInstanceType(value).get_name();
    switch (type) {
      case 'Array':
        var shape = numpy.getShape(value);
        var data = value.ravel();
        for (var i = 0; i < data.length; i++) {
          data[i] = Math.PI * (data[i]) / 180;
        }
        return numpy.reshape(data, shape);
      default:
        return (value) * (180 / Math.PI);
    }
  }
  numpy.rad2deg = function numpy$rad2deg(value) {
    var type = Type.getInstanceType(value).get_name();
    switch (type) {
      case 'Array':
        var shape = numpy.getShape(value);
        var data = value.ravel();
        for (var i = 0; i < data.length; i++) {
          data[i] = (data[i]) * (180 / Math.PI);
        }
        return numpy.reshape(data, shape);
      default:
        return (value) * (180 / Math.PI);
    }
  }
  numpy.deg2rad = function numpy$deg2rad(value) {
    var type = Type.getInstanceType(value).get_name();
    switch (type) {
      case 'Array':
        var shape = numpy.getShape(value);
        var data = value.ravel();
        for (var i = 0; i < data.length; i++) {
          data[i] = Math.PI * (data[i]) / 180;
        }
        return numpy.reshape(data, shape);
      default:
        return (value) * (180 / Math.PI);
    }
  }

  numpy.registerClass('numpy');
  Object.defineProperty(Array.prototype, "size", {
    get: function () {
      __$$tmP = this.shape;

      var thesize = 1;
      for (var i = 0; i < __$$tmP.length; i++)
        thesize *= __$$tmP[i];
      return thesize;
    }
  });
  Object.defineProperty(Array.prototype, "shape", {
    get: function () {
      __$$tmP = this;
      var dim = [];
      for (; ;) {
        dim.push(__$$tmP.length);

        if (Array.isArray(__$$tmP[0])) {
          __$$tmP = __$$tmP[0];
        } else {
          break;
        }
      }
      return dim;
    }
  });
  Object.defineProperty(Array.prototype, "strides", {
    get: function () {
      var shp = this.shape;
      var dim = [];
      for (var i = 1; i < shp.length; i++) {
        var acum = 1;
        for (var j = i; j < shp.length; j++) {
          acum *= shp[j];
        }
        dim.push(acum);
      }
      dim.push(1);
      return dim;
    }
  });
  Object.defineProperty(Array.prototype, "ndim", {
    get: function () {
      __$$tmP = this;
      return __$$tmP.shape.length;
    }
  });
  Object.defineProperty(Array.prototype, "T", {
    get: function () {
      return this.transpose();
    }
  });
  Object.defineProperty(Array.prototype, 'resize', withValue(function numpy$_resize(shape) {
    a = this;
    a = numpy.reshape(a, shape);
    this.clear();
    for (var i = 0; i < a.length; i++)
      this.push(a[i]);
    return a;
  }));
  Object.defineProperty(Array.prototype, 'transpose', withValue(function numpy$_transpose() {
    var _data = this.ravel();
    var _dest = _data.clone();

    var recipient = new Array(this.size);
    var sh = this.shape.reverse();
    var dstStride = this.strides.reverse();

    generatelist(recipient, sh);
    transport(_data, _dest, recipient, dstStride);

    _dest = _dest.reshape(sh);
    return _dest;
  }));
  Object.defineProperty(Array.prototype, 'flatten', withValue(function numpy$_flatten() {
    return this.ravel();
  }));
  function generatelist(recipient, sh) {
    var start = new Array(sh.length);
    var size = sh.length;

    for (var i = 0; i < sh.length; i++) {
      start[i] = 0;
    }

    for (var i = 0; i < recipient.length; i++) {
      recipient[i] = new Array(sh.length);
      for (var j = 0; j < sh.length; j++)
        recipient[i][j] = start[j];

      increment(start, sh);
    }
  };
  function increment(start, sh) {
    for (var i = sh.length - 1; i >= 0; i--) {
      if (start[i] < sh[i] - 1) {
        start[i]++;
        return;
      }
      start[i] = 0;
    }
  };
  function transport(data, dest, recipient, dstStride) {
    for (var i = 0; i < recipient.length; i++) {
      var position;
      var positionArray = recipient[i];
      for (var j = 0; j < dstStride.length; j++)
        positionArray[j] = recipient[i][j] * dstStride[j];
      var position = positionArray.reduce(function (a, b) { return a + b; }, 0);
      dest[i] = data[position];
    }
  };
  window.np = numpy;
  numpy.pi = Math.PI;
  numpy.range = numpy.arange;
  Object.defineProperty(Array.prototype, 'exp', withValue(numpy.exp));
  Object.defineProperty(Array.prototype, 'reshape', withValue(numpy.reshape));
  Object.defineProperty(Array.prototype, 'ravel', withValue(numpy.ravel));
  Object.defineProperty(Array.prototype, 'dtype', withValue(numpy.dtype));
  Number.prototype.dtype = numpy.dtype;
  Object.defineProperty(Array.prototype, 'dot', withValue(numpy.dot));
  numpy.random = numpy.getrandom;
  numpy.random.random = numpy.getrandom;
  window.ndarray = Array;
})();

// javascript utility
// https://github.com/fernandezajp/PyExtJS

(function () {

  Type.registerNamespace('PolyRegression');

  PolyRegression.Matrix = function PolyRegression_Matrix() {
  }
  PolyRegression.Matrix.prototype = {
    _pairs: null,

    computeCoefficients: function PolyRegression_Matrix$computeCoefficients(data, degree) {
      degree += 1;
      var n = data.length;
      var r, c;
      var rs = 2 * degree - 1;
      var m = new Array(degree);
      for (var i = 0; i < degree; i++) {
        var mm = new Array(degree + 1);
        for (var j = 0; j <= degree; j++) {
          mm[j] = 0;
        }
        m[i] = mm;
      }
      var mpc = new Array(rs);
      for (var i = 0; i < rs; i++) {
        mpc[i] = 0;
      }
      mpc[0] = n;
      for (var i = 0; i < data.length; i++) {
        var pr = data[i];
        for (r = 1; r < rs; r++) {
          mpc[r] += Math.pow(pr.get_x(), r);
        }
        m[0][degree] += pr.get_y();
        for (r = 1; r < degree; r++) {
          m[r][degree] += Math.pow(pr.get_x(), r) * pr.get_y();
        }
      }
      for (r = 0; r < degree; r++) {
        for (c = 0; c < degree; c++) {
          m[r][c] = mpc[r + c];
        }
      }
      this._echelonize(m);
      var terms = new Array(degree);
      for (var i = 0; i < m.length; i++) {
        var mc = m[i];
        terms[i] = mc[degree];
      }
      this._pairs = terms;
      return terms;
    },

    eval: function PolyRegression_Matrix$eval(x) {
      var y = 0;
      var power = 0;
      for (var i = 0; i < this._pairs.length; i++) {
        y += this._pairs[i] * Math.pow(x, power++);
      }
      return y;
    },

    _echelonize: function PolyRegression_Matrix$_echelonize(A) {
      var n = A.length;
      var m = A[0].length;
      var i = 0;
      var j = 0;
      var k;
      var swap;
      while (i < n && j < m) {
        k = i;
        while (k < n && !A[k][j]) {
          k++;
        }
        if (k < n) {
          if (k !== i) {
            swap = A[i];
            A[i] = A[k];
            A[k] = swap;
          }
          if (A[i][j] !== 1) {
            this._divide(A, i, j, m);
          }
          this._eliminate(A, i, j, n, m);
          i++;
        }
        j++;
      }
    },

    _divide: function PolyRegression_Matrix$_divide(A, i, j, m) {
      for (var q = j + 1; q < m; q++) {
        A[i][q] /= A[i][j];
      }
      A[i][j] = 1;
    },

    _eliminate: function PolyRegression_Matrix$_eliminate(A, i, j, n, m) {
      for (var k = 0; k < n; k++) {
        if (k !== i && !!A[k][j]) {
          for (var q = j + 1; q < m; q++) {
            A[k][q] -= A[k][j] * A[i][q];
          }
          A[k][j] = 0;
        }
      }
    }
  }

  PolyRegression.Pair = function PolyRegression_Pair(x, y) {
    this._x = x;
    this._y = y;
  }
  PolyRegression.Pair.prototype = {
    _x: 0,

    get_x: function PolyRegression_Pair$get_x() {
      return this._x;
    },
    set_x: function PolyRegression_Pair$set_x(value) {
      this._x = value;
      return value;
    },

    _y: 0,

    get_y: function PolyRegression_Pair$get_y() {
      return this._y;
    },
    set_y: function PolyRegression_Pair$set_y(value) {
      this._y = value;
      return value;
    }
  }


  PolyRegression.Matrix.registerClass('PolyRegression.Matrix');
  PolyRegression.Pair.registerClass('PolyRegression.Pair');
})();
