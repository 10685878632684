import { InputModel } from "./inputModel";
import { ResultLayer } from "./resultLayer";
import { KreqTables } from "./kreqTables";
import { DesignValues } from "./designValues";
import { EarthPressure } from "./earthPressure";
import { BlockResult } from "./blockResult";
import { MeanValue } from "./meanValue";
import { ProductsTable } from "./productsTables";
import { ResultCoordinates } from "./resultCoordinates";
import { Prices } from "./prices";
import { AdminFactorsService } from "../admin-factors.service";
import { ImpToMetricFactors, MetricToImpFactors } from "./unitSystem";

export class Calculator {
  //layers: Array<ResultLayer>;
  private kreqTables: KreqTables;
  //designValues: DesignValues;
  meanValues: Array<MeanValue>;
  //productsTable: ProductsTable;
  //inputModel:InputModel;

  constructor(private inputModel: InputModel, private adminFactorsService: AdminFactorsService) {
    //this.inputModel = { ...inputModel };

    //if (inputModel.language === 'enimp') {
    //  this.inputModel.height *= ImpToMetricFactors.Meter;
    //  this.inputModel.unitWeightCharacteristic *= ImpToMetricFactors.Pressure;
    //  this.inputModel.verticalLayerDistance *= ImpToMetricFactors.Meter;
    //  this.inputModel.minOverburdenHeight *= ImpToMetricFactors.Meter;
    //  this.inputModel.uniformVerticalSurcharge *= ImpToMetricFactors.Pressure;
    //  this.inputModel.widthOfUniformVerticalSurcharge *= ImpToMetricFactors.Meter;
    //}

  }



  calculate(prices: Prices) : ResultLayer[] {

    const inputModelToCalculate = this.inputModel;

    //const lengthFactor = this.inputModel.language === "enimp" ? MetricToImpFactors.Foot : 1;

    const earthPressure = new EarthPressure(inputModelToCalculate);

    this.meanValues = [];
    this.kreqTables = new KreqTables(inputModelToCalculate);
    
    const reqReinforcementLengthAtTheBase = this.kreqTables.interpolatedY * 1.3 * inputModelToCalculate.effectiveHeight;
    const maxRequiredStressAtTheBaseOfSlope_Q =
      earthPressure.noLabel * inputModelToCalculate.unitWeightDesign * inputModelToCalculate.effectiveHeight;
    const designValues = {
      requiredEarthPressure: earthPressure.noLabel,
      minLengthOverallStabilityInclSafetyFactor: this.kreqTables.interpolatedY * 1.3,
      minLengthDirectSlicing: 0.46,
      requiredReinforcementLenghtAtTheBase: reqReinforcementLengthAtTheBase,
      maxRequiredStressAtTheBaseOfSlope_Q: maxRequiredStressAtTheBaseOfSlope_Q,
      normalizedRequiredStressPerLayer_Q: maxRequiredStressAtTheBaseOfSlope_Q / inputModelToCalculate.metricInputValues.height * inputModelToCalculate.metricInputValues.verticalLayerDistance,
      maxRequiredStressAtTheBaseOfSlope: earthPressure.noLabel * inputModelToCalculate.unitWeightDesign * inputModelToCalculate.metricInputValues.height,
      normalizedRequiredStressPerLayer: (earthPressure.noLabel * inputModelToCalculate.unitWeightDesign * inputModelToCalculate.metricInputValues.height) / inputModelToCalculate.metricInputValues.height * inputModelToCalculate.metricInputValues.verticalLayerDistance,
      shouldAllLayersHaveSameLength: inputModelToCalculate.shouldAllGeoGridsHaveSameLength == 1,

    } as DesignValues;

    const layers = this.generateLayers(designValues, inputModelToCalculate);
    this.adjustLayers(inputModelToCalculate, designValues, layers);
    this.adjustBlockResults(layers, inputModelToCalculate);

    const productsTable = new ProductsTable(inputModelToCalculate, prices);
    this.setProductsResultLengthAndCoordinates(inputModelToCalculate, layers, productsTable);

    return layers;
  }

  private setProductsResultLengthAndCoordinates(inputModel: InputModel, layers: ResultLayer[], products: ProductsTable) {
    const resultLayers = layers.filter(x => x.lFinal > 0 && x.overburden >= inputModel.metricInputValues.minOverburdenHeight).map(layer => layer);

    const firstLayer = resultLayers.length < 2 ? null : resultLayers[1];

    if (!firstLayer) {
      return;
    }

    const lengthFactor = inputModel.language === 'enimp' ? MetricToImpFactors.Foot : 1;
    
    for (let j = 0; j < resultLayers.length; j++) {
      const layer = resultLayers[j];
      const prevLayer = j > 0 ? resultLayers[j - 1] : null;

      //const isNeutralOrAcidAndNotPET = (inputModel.phValueOfFillingSoil === 1 || inputModel.phValueOfFillingSoil === 2) && inputModel.petPva === 1;
      //const isNeutralOrAcidAndNotPET = inputModel.petPva === 1;
      const rreq_final = layer.rreq_final;

      if (inputModel.petPva === 1) {
        for (let i = 5; i > -1; i--) {
          const product = products.calculatedProducts[i];
          if (product.designStrength >= rreq_final) {
            layer.productLine = product;
            break;
          }
        }
      } else {
        for (let i = 11; i > 5; i--) {
          const product = products.calculatedProducts[i];
          if (product && product.designStrength >= rreq_final) {
            layer.productLine = product;
            break;
          }
        }
      }

      if (layer.productLine) {
        layer.resultLength = layer.lFinalRounded;
        layer.resultTotalLength = inputModel.metricInputValues.uniformVerticalSurcharge >= (33) ? layer.lFinalRounded + (0.5) : layer.lFinalRounded;

        const val1 = layer.height;
        //const val2 = !prevLayer ? 0 : firstLayer.height + prevLayer.coordinates.val_2;
        const val2 = !prevLayer ? 0 : firstLayer.height / Math.tan(inputModel.slopeAngle / 180 * Math.PI) + prevLayer.coordinates.val_2;
        const val3 = val2 + layer.resultTotalLength;

        layer.coordinates = {
          val_1: val1 * lengthFactor,
          val_2: val2 * lengthFactor,
          val_3: val3 * lengthFactor
        } as ResultCoordinates;

        layer.height *= lengthFactor;
        layer.effectiveHeight *= lengthFactor;
        layer.lFinal *= lengthFactor;
        layer.lFinalRounded *= lengthFactor;
        layer.length *= lengthFactor;
        layer.lengthDerived *= lengthFactor;
        layer.overburden *= lengthFactor;
        layer.resultTotalLength *= lengthFactor;
      }

      
    }

  }

  private ceilInExcelWay(value: number): number {
    const valueToCeil = Math.abs(value);

    if (value < 0) {
      return Math.ceil(valueToCeil) * -1;
    }
    return Math.ceil(valueToCeil);
  }

  private adjustBlockResults(layers:ResultLayer[], inputModel:InputModel) {
    for (let i = 0; i < layers.length; i++) {
      const prevLayer = i > 0 ? layers[i - 1] : null;

      const layer = layers[i];

      if (layer.blockResult.id === 5) {

      }

      const meanValue = this.meanValues.find(x => x.blockId === layer.blockResult.id);

      if (meanValue !== undefined) {
        layer.rreq_final = meanValue.meanValue;
        layer.blockResult.secondValue = layer.lengthDerived;
        layer.blockResult.meanValueStrength = meanValue.meanValue;
      }

      if (prevLayer && prevLayer.blockResult.id !== layer.blockResult.id) {
        const blockLayers =
          layers.filter(x => x.blockResult.id === prevLayer.blockResult.id && x.blockResult.value >= 0);

        const secondMeanValue = blockLayers.reduce((prevValue, currentValue) => prevValue + currentValue.blockResult.secondValue, 0) / blockLayers.length;
        const lFinalRounded = secondMeanValue <= 3 ? (inputModel.metricInputValues.uniformVerticalSurcharge >= 25 ? 3.5 : 3) : (Math.ceil(Math.abs(secondMeanValue) / 5 * 10) / 10) * 5;

        blockLayers.forEach(x => {
          x.blockResult.secondMeanValue = secondMeanValue;
          x.lFinal = secondMeanValue;
          x.lFinalRounded = lFinalRounded
        });
      }

    }
  }

  private adjustLayers(inputModel:InputModel, designValues: DesignValues, layers:ResultLayer[]) {
    for (let i = 0; i < layers.length; i++) {
      const currentLayer = layers[i];
      const prevLayer = i === 0 ? null : layers[i - 1];

      if (currentLayer.layerIndex === 19) {

      }

      if (prevLayer === null) {
        currentLayer.rreq_g_q = designValues.maxRequiredStressAtTheBaseOfSlope_Q;
        currentLayer.rreq_g = designValues.maxRequiredStressAtTheBaseOfSlope;
      } else {
        currentLayer.rreq_g_q = prevLayer.rreq_g_q - designValues.normalizedRequiredStressPerLayer_Q;
        currentLayer.rreq_g = prevLayer.rreq_g - designValues.normalizedRequiredStressPerLayer;
      }

      const rreqValue = currentLayer.overburden <= inputModel.influencingDepthOfVerticalSurcharge ? currentLayer.rreq_g_q : currentLayer.rreq_g;

      currentLayer.rreq = Math.round(rreqValue) === 0 ? 0 : this.ceilInExcelWay(rreqValue);

      const blockId = Math.ceil(currentLayer.layerIndex / inputModel.numberOfGeogrids);

      currentLayer.blockResult = {
        id: blockId,
        value:currentLayer.rreq
      } as BlockResult;

      if (prevLayer && currentLayer.blockResult.id !== prevLayer.blockResult.id) {
        const blockResults = layers.filter(x => x.blockResult && x.blockResult.id === prevLayer.blockResult.id && x.blockResult.value > 0)
          .map(x => x.blockResult);
        const meanValue = this.calculateMean(blockResults);

      }
    }
  }

  private calculateMean(blockLayers: Array<BlockResult>) : MeanValue{
    if (blockLayers === null || blockLayers.length < 1) {
      return null;
    }

    const blockSum = blockLayers.reduce((prevValue, currentValue) => prevValue + currentValue.value, 0);
    const meanValue = {
      blockId: blockLayers[0].id,
      meanValue : blockSum / blockLayers.length
    } as MeanValue;

    this.meanValues.push(meanValue);

    return meanValue;
  }

  private generateLayers(designValues: DesignValues, inputModel:InputModel) : ResultLayer[]{
    let firstLayerLength = 0;
    
    const lenghtReductionFactorFU = inputModel.metricInputValues.height <= (3) ? this.adminFactorsService.currentAdminFactors.value.lowerOrEqual3 : inputModel.metricInputValues.height <= (6) ? this.adminFactorsService.currentAdminFactors.value.lowerOrEqual6 : this.adminFactorsService.currentAdminFactors.value.lowerOrEqual10;
    const layers = [...Array(39).keys()].map(x => {
      const layerIndex = x + 1;
      const effectiveHeight = inputModel.effectiveHeight - (x * inputModel.metricInputValues.verticalLayerDistance);

      let length = effectiveHeight * designValues.minLengthOverallStabilityInclSafetyFactor * lenghtReductionFactorFU;

      if (designValues.shouldAllLayersHaveSameLength) {
        if (x == 0) {
          firstLayerLength = length;
        } else {
          length = firstLayerLength;
        }
      }

      const height = inputModel.metricInputValues.verticalLayerDistance * x;
      let lengthDerive = (Math.ceil(Math.abs(length) / 5 * 10) / 10) * 5;

      if (length < 0) {
        lengthDerive = lengthDerive * -1;
      }

      return {
        layerIndex: layerIndex,
        overburden: inputModel.metricInputValues.height - (x * inputModel.metricInputValues.verticalLayerDistance),
        effectiveHeight: effectiveHeight,
        length: effectiveHeight * designValues.minLengthOverallStabilityInclSafetyFactor,
        lengthDerived: lengthDerive,
        height: height,
      } as ResultLayer;
    });

    return layers;
  }
}

