// tslint:disable-block

import { KreqTableLine } from "./kreqTableLine";
import "./numpyJs";
import { RegressionHelpValueLine } from "./regressionHelpValueLine";
import { InputModel } from "./inputModel";

export class KreqTables {
  kreq_lines: Array<KreqTableLine>;
  lr_lines: Array<KreqTableLine>;
  regression_helpvalues: Array<RegressionHelpValueLine>;
  regression_poly25:Array<number>;
  regression_poly35: Array<number>;
  interpolatedY:number;

  constructor(private inputModel:InputModel) {
    this.regression_helpvalues = [...Array(13).keys()].map(index => {
      const x = 30 + index * 5;
      return {
        x: x,
        x2 : Math.pow(x, 2),
        x3 : Math.pow(x, 3),
        x4 : Math.pow(x, 4),
    } as RegressionHelpValueLine;
    });

    this.kreq_lines = [
      { beta: 30, jd_25: 0.04, jd_var: null, jd_30: 0.000, jd_35: 0.000 } as KreqTableLine,
      { beta: 35, jd_25: 0.07, jd_var: null, jd_30: 0.023, jd_35: -0.001 } as KreqTableLine,
      { beta: 40, jd_25: 0.10, jd_var: null, jd_30: 0.050, jd_35: 0.020 } as KreqTableLine,
      { beta: 45, jd_25: 0.13, jd_var: null, jd_30: 0.077, jd_35: 0.040 } as KreqTableLine,
      { beta: 50, jd_25: 0.155, jd_var: null, jd_30: 0.100, jd_35: 0.062 } as KreqTableLine,
      { beta: 55, jd_25: 0.180, jd_var: null, jd_30: 0.125, jd_35: 0.080 } as KreqTableLine,
      { beta: 60, jd_25: 0.205, jd_var: null, jd_30: 0.145, jd_35: 0.100 } as KreqTableLine,
      { beta: 65, jd_25: 0.230, jd_var: null, jd_30: 0.170, jd_35: 0.125 } as KreqTableLine,
      { beta: 70, jd_25: 0.255, jd_var: null, jd_30: 0.195, jd_35: 0.147 } as KreqTableLine,
      { beta: 75, jd_25: 0.285, jd_var: null, jd_30: 0.225, jd_35: 0.170 } as KreqTableLine,
      { beta: 80, jd_25: 0.315, jd_var: null, jd_30: 0.255, jd_35: 0.195 } as KreqTableLine,
      { beta: 85, jd_25: 0.350, jd_var: null, jd_30: 0.290, jd_35: 0.230 } as KreqTableLine,
      { beta: 90, jd_25: 0.405, jd_var: null, jd_30: 0.330, jd_35: 0.280 } as KreqTableLine
    ];

    this.kreq_lines.forEach(line => {
      line.jd_var = (this.inputModel.frictionAngleCharacteristic - 30) / (25 - 30) * (line.jd_25 - line.jd_30) + line.jd_30;
      line.jd_325 = (line.jd_30 + line.jd_35) / 2;
    });

    //this.lr_lines = [
    //  { beta: 30, jd_25: 0.850, jd_var: null, jd_30: 0.000, jd_35: 0.000 } as KreqTableLine,
    //  { beta: 35, jd_25: 0.860, jd_var: null, jd_30: 0.400, jd_35: 0.000 } as KreqTableLine,
    //  { beta: 40, jd_25: 0.850, jd_var: null, jd_30: 0.490, jd_35: 0.270 } as KreqTableLine,
    //  { beta: 45, jd_25: 0.820, jd_var: null, jd_30: 0.520, jd_35: 0.350 } as KreqTableLine,
    //  { beta: 50, jd_25: 0.760, jd_var: null, jd_30: 0.505, jd_35: 0.380 } as KreqTableLine,
    //  { beta: 55, jd_25: 0.720, jd_var: null, jd_30: 0.505, jd_35: 0.400 } as KreqTableLine,
    //  { beta: 60, jd_25: 0.680, jd_var: null, jd_30: 0.505, jd_35: 0.410 } as KreqTableLine,
    //  { beta: 65, jd_25: 0.640, jd_var: null, jd_30: 0.505, jd_35: 0.415 } as KreqTableLine,
    //  { beta: 70, jd_25: 0.640, jd_var: null, jd_30: 0.515, jd_35: 0.425 } as KreqTableLine,
    //  { beta: 75, jd_25: 0.650, jd_var: null, jd_30: 0.530, jd_35: 0.440 } as KreqTableLine,
    //  { beta: 80, jd_25: 0.670, jd_var: null, jd_30: 0.560, jd_35: 0.460 } as KreqTableLine,
    //  { beta: 85, jd_25: 0.690, jd_var: null, jd_30: 0.580, jd_35: 0.480 } as KreqTableLine,
    //  { beta: 90, jd_25: 0.690, jd_var: null, jd_30: 0.590, jd_35: 0.500 } as KreqTableLine,
    //];

    //this.lr_lines.forEach(line => {
    //  line.jd_var = (this.inputModel.frictionAngleDesign - 25) / (35 - 25) * (line.jd_35 - line.jd_25) + line.jd_25;
    //  line.jd_325 = (line.jd_30 + line.jd_35) / 2;
    //});

    let fuckLR = 0;

    if (this.inputModel.frictionAngleCharacteristic < 30) {
      fuckLR = 0.7 - 0.032 * (this.inputModel.frictionAngleCharacteristic - 25);
    } else if (this.inputModel.frictionAngleCharacteristic < 35) {
      fuckLR = 0.54 - 0.024 * (this.inputModel.frictionAngleCharacteristic - 30);
    } else  {
      fuckLR = 0.42 - 0.005 * (this.inputModel.frictionAngleCharacteristic - 35);
    }

    //const x = this.lr_lines.map(x => x.beta);
    //const y = this.lr_lines.map(x => x.jd_25);
    //this.regression_poly25 = (<any>window).numpy.polyfit(x, y, 4);

    //const y25 = this.regression_poly25[0] * Math.pow(this.inputModel.slopeAngle, 4) +
    //  this.regression_poly25[1] * Math.pow(this.inputModel.slopeAngle, 3) +
    //  this.regression_poly25[2] * Math.pow(this.inputModel.slopeAngle, 2) +
    //  this.regression_poly25[3] * this.inputModel.slopeAngle +
    //  this.regression_poly25[4];

    //const y35Vals = this.lr_lines.map(x => x.jd_35);

    //this.regression_poly35 = (<any>window).numpy.polyfit(x, y35Vals, 4);

    //const y35 = this.regression_poly35[0] * Math.pow(this.inputModel.slopeAngle, 4) +
    //  this.regression_poly35[1] * Math.pow(this.inputModel.slopeAngle, 3) +
    //  this.regression_poly35[2] * Math.pow(this.inputModel.slopeAngle, 2) +
    //  this.regression_poly35[3] * this.inputModel.slopeAngle +
    //  this.regression_poly35[4];

    //const trendpol = (<any>window).numpy.polyfit([25,35], [y25, y35], 1);
    this.interpolatedY = fuckLR;
    //this.interpolatedY = trendpol[0] * this.inputModel.frictionAngleDesign + trendpol[1];
  }
}
