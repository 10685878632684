import { InputModel } from "./inputModel";
import { IPriceTableLine } from "./IPriceTableLine";
import { Prices } from "./prices";
import { IProductPricedetails } from "./IProductPricedetails";
import { Product, PricePositionLabel } from "./calculationEnums";
import { IPricePosition } from "./IPricePosition";

export class FortracPanelCalculator {
  constructor(private frontArea, private inputModel: InputModel, private geoGridsSummary: Array<IPriceTableLine>, private prices: Prices) {

  }

  private getGeoGridLine(): IPricePosition {
    const quantity = this.geoGridsSummary.reduce((sum, current) => sum + current.wrapAroundPlus6Perc, 0) /
      this.frontArea;
    const price = this.geoGridsSummary.reduce((sum, current) => sum + current.wrapAroundTotal, 0) /
      this.frontArea;

    const returnValue = {
      description: "Wraparound",
      quantity: quantity,
      price: price,
      position: PricePositionLabel.Geogrid
    } as IPricePosition;

    return returnValue;
  }

  private getErosionProtection(): IPricePosition {
    const quantity = 0.4 + this.inputModel.verticalLayerDistance;
    const price = quantity * this.prices.trickleProtection.NonWovenFabric;

    const returnValue = {
      quantity: quantity,
      price: price,
      description: "Non woven GRK5",
      position: PricePositionLabel.ErosionProtection
    } as IPricePosition;

    return returnValue;
  }

  calculate(): IProductPricedetails {
    const details = {
      product: Product.Panel,
    } as IProductPricedetails;

    //if (this.inputModel.verticalLayerDistance !== 0.4) {
    //  details.errorMessage = "Please change vertical distance to 0.4";
    //  details.isValid = false;
    //  details.positions = [];

    //  return details;
    //}

    const geogrid = this.getGeoGridLine();
    const erosionProtection = this.getErosionProtection();

    const panel = {
      quantity: 1,
      price: this.prices.panel.passive,
      description: "Concrete panels",
      position: PricePositionLabel.ConcretePanels
    } as IPricePosition;

    details.isValid = true;
    details.positions = [geogrid, erosionProtection, panel];
    details.sumPrice = details.positions.reduce((sum, current) => sum + current.price, 0);

    return details;
  }
}
