import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { InputModel } from "../calculationModel/inputModel";
import { Product } from "../calculationModel/calculationEnums";
import { IProductPricedetails } from "../calculationModel/IProductPricedetails";
import { UserRoles } from '../user-roles.config';

@Component({
  selector: 'app-result-view',
  templateUrl: './result-view.component.html',
  styleUrls: ['./result-view.component.css']
})
export class ResultViewComponent implements OnInit {

  constructor() { }

  @Input() inputModel: InputModel;
  @Input() productPrices: IProductPricedetails[];
  @Output() showSubSystemSelectionRequested = new EventEmitter<any>();

  userRoles = UserRoles;

  getSubsystemName(): string {
    switch (this.inputModel.selectedSystem) {
    case Product.Block:
      return this.inputModel.systemModelBlock.toString();
    case Product.Gabion:
      return this.inputModel.systemModelGabion.toString();
    case Product.Nature:
      return this.inputModel.systemModelNature.toString();
    }
    return null;
  }

  ngOnInit(): void {
  }

}
