import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Prices } from "./calculationModel/prices";
import { Observable, BehaviorSubject  } from 'rxjs';
import { tap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PricesService {
  prices: BehaviorSubject<Prices[]>;


  //private pricesUrl = 'api/prices';
  private pricesUrl = 'prices';

  private getLineByPrice(prices: Prices): any {
    const element = {
      id: prices.id,
      name: prices.name,
      isDefault: prices.isDefault,
      blockInorBlock: prices.block.inorBloc,
      blockAllanBlock: prices.block.allanBlock,
      trickleProtectionFortrac3D: prices.trickleProtection.fortrac3D,
      trickleProtection23142Gr: prices.trickleProtection._23142Gr,
      trickleProtectionNonWovenFabric: prices.trickleProtection.NonWovenFabric,
      steelLatticeSteel05: prices.steelLattice.steel05,
      steelLatticeSteel06: prices.steelLattice.steel06,
      steelLatticeSteelGalvanized: prices.steelLattice.galvanized,
      gabion: prices.gabion._200x100x100,
      gabionAnchorType1DACH: prices.gabionAnchor.Type1Dach,
      gabionAnchorType2EMEEA: prices.gabionAnchor.Type2EMEEA,
      panelPassive: prices.panel.passive,

      fortrac35MDT: prices.fortrac.MDT35,
      fortrac55MDT: prices.fortrac.MDT55,
      fortrac80MDT: prices.fortrac.MDT80,
      fortrac110MDT: prices.fortrac.MDT110,
      fortrac150MDT: prices.fortrac.MDT150,
      fortrac200MDT: prices.fortrac.MDT200,
      fortrac35T: prices.fortrac.T35,
      fortrac55T: prices.fortrac.T55,
      fortrac80T: prices.fortrac.T80,
      fortrac110T: prices.fortrac.T110,
      fortrac150T: prices.fortrac.T150,
      fortrac200T: prices.fortrac.T200,
    }

    return element;
  }

  private getPriceByLine(element: any): Prices {
    const prices = new Prices();
    prices.id = element.id;
    prices.name = element.name;
    prices.isDefault = element.isDefault;
    prices.block.inorBloc = element.blockInorBlock;
    prices.block.allanBlock = element.blockAllanBlock;
    prices.trickleProtection.fortrac3D = element.trickleProtectionFortrac3D;
    prices.trickleProtection._23142Gr = element.trickleProtection23142Gr;
    prices.trickleProtection.NonWovenFabric = element.trickleProtectionNonWovenFabric;
    prices.steelLattice.steel05 = element.steelLatticeSteel05;
    prices.steelLattice.steel06 = element.steelLatticeSteel06;
    prices.steelLattice.galvanized = element.steelLatticeSteelGalvanized;
    prices.gabion._200x100x100 = element.gabion;
    prices.gabionAnchor.Type1Dach = element.gabionAnchorType1DACH;
    prices.gabionAnchor.Type2EMEEA = element.gabionAnchorType2EMEEA;
    prices.panel.passive = element.panelPassive;

    prices.fortrac.MDT35 = element.fortrac35MDT;
    prices.fortrac.MDT55 = element.fortrac55MDT;
    prices.fortrac.MDT80 = element.fortrac80MDT;
    prices.fortrac.MDT110 = element.fortrac110MDT;
    prices.fortrac.MDT150 = element.fortrac150MDT;
    prices.fortrac.MDT200 = element.fortrac200MDT;

    prices.fortrac.T35 = element.fortrac35T;
    prices.fortrac.T55 = element.fortrac55T;
    prices.fortrac.T80 = element.fortrac80T;
    prices.fortrac.T110 = element.fortrac110T;
    prices.fortrac.T150 = element.fortrac150T;
    prices.fortrac.T200 = element.fortrac200T;

    return prices;
  }

  //getPrices(): Observable<Prices[]> {
  getPriceById(id: any): Observable<any> {

    const url = this.pricesUrl + "/" + id;

    return this.http.get(url).pipe(tap(res => {

      }),
      map(res => {
        if (!res) {
          return null;
        }
        const price = this.getPriceByLine(res);

        return price;
      }));
  }

  isNameExisting(name: string): Observable<any> {
    return this.http.get(this.pricesUrl + "/createNewCalc/" + name).pipe(map(res => {
      return res;
    }));
  }

  refreshPrices(): void {
    this.http.get(this.pricesUrl).pipe(tap(res => {

      }),
      map(res => {
        if (!res) {
          return [];
        }

        const resultArray = (<any>res);
        const allPrices = resultArray.map(element => {
          return this.getPriceByLine(element);
        });

        return allPrices;
      })).subscribe(prices => {
      this.prices.next(prices);
    });
  }

  updatePrice(prices: Prices, callbackOnSuccess: Function): void {
    const putUrl = `${this.pricesUrl}/${prices.id}`;

    const body = this.getLineByPrice(prices);

    this.http.put(putUrl, body)
      .pipe()
      .subscribe(res => {
        this.refreshPrices();

        if (callbackOnSuccess) {
          callbackOnSuccess();
        }
      });

  }

  constructor(
    private http: HttpClient) {

    this.prices = new BehaviorSubject([]);
    this.refreshPrices();
  }
}
