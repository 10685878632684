import { ProductsTableLine } from "./productsTableLine";
import { InputModel } from "./inputModel";
import { ReductionFactorTables } from "./reductionFactorTables";
import { Prices } from "./prices";
import { MetricToImpFactors } from "./unitSystem";

export class ProductsTable {
  calculatedProducts: Array<ProductsTableLine>;
  private reductionFactorTables:ReductionFactorTables;

  constructor(private inputModel: InputModel, prices: Prices) {
    this.reductionFactorTables = new ReductionFactorTables();

    this.calculatedProducts = [
      //{ label: "Fortrac® 300 T", shortTermStrength: 300, A1: this.getAValue(1, "A1"), A2: this.getAValue(1, "A2"), A3: this.getAValue(1, "A3"), A4 : this.getAValue(1, "A4"), A5: this.getAValue(1, "A5"), price: 6  } as ProductsTableLine,
      { label: "Fortrac® 200 T", shortTermStrength: 200, A1: this.getAValue(2, "A1"), A2: this.getAValue(2, "A2"), A3: this.getAValue(2, "A3"), A4: this.getAValue(2, "A4"), A5: this.getAValue(2, "A5"), price: prices.fortrac.T200, color: '#004225' } as ProductsTableLine,
      { label: "Fortrac® 150 T", shortTermStrength: 150, A1: this.getAValue(3, "A1"), A2: this.getAValue(3, "A2"), A3: this.getAValue(3, "A3"), A4: this.getAValue(3, "A4"), A5: this.getAValue(3, "A5"), price: prices.fortrac.T150, color: '#00563f' } as ProductsTableLine,
      { label: "Fortrac® 110 T", shortTermStrength: 110, A1: this.getAValue(4, "A1"), A2: this.getAValue(4, "A2"), A3: this.getAValue(4, "A3"), A4: this.getAValue(4, "A4"), A5: this.getAValue(4, "A5"), price: prices.fortrac.T110, color: '#006600'  } as ProductsTableLine,
      { label: "Fortrac® 80 T", shortTermStrength: 80, A1: this.getAValue(5, "A1"), A2: this.getAValue(5, "A2"), A3: this.getAValue(5, "A3"), A4: this.getAValue(5, "A4"), A5: this.getAValue(5, "A5"), price: prices.fortrac.T80, color: '#087830' } as ProductsTableLine,
      { label: "Fortrac® 55 T", shortTermStrength: 55, A1: this.getAValue(6, "A1"), A2: this.getAValue(6, "A2"), A3: this.getAValue(6, "A3"), A4: this.getAValue(6, "A4"), A5: this.getAValue(6, "A5"), price: prices.fortrac.T55, color: '#2a8000' } as ProductsTableLine,
      { label: "Fortrac® 35 T", shortTermStrength: 35, A1: this.getAValue(7, "A1"), A2: this.getAValue(7, "A2"), A3: this.getAValue(7, "A3"), A4: this.getAValue(7, "A4"), A5: this.getAValue(7, "A5"), price: prices.fortrac.T35, color: '#568203' } as ProductsTableLine,

      //{ label: "Fortrac® R 300/30-30 MPT", shortTermStrength: 300, A1: this.getAValue(8, "A1"), A2: this.getAValue(8, "A2"), A3: this.getAValue(8, "A3"), A4: this.getAValue(8, "A4"), A5: this.getAValue(8, "A5"), price: 3.5 } as ProductsTableLine,
      { label: "Fortrac® 200 MDT", shortTermStrength: 200, A1: this.getAValue(9, "A1"), A2: this.getAValue(9, "A2"), A3: this.getAValue(9, "A3"), A4: this.getAValue(9, "A4"), A5: this.getAValue(9, "A5"), price: prices.fortrac.MDT200, color: '#004225' } as ProductsTableLine,
      { label: "Fortrac® 150 MDT", shortTermStrength: 150, A1: this.getAValue(10, "A1"), A2: this.getAValue(10, "A2"), A3: this.getAValue(10, "A3"), A4: this.getAValue(10, "A4"), A5: this.getAValue(10, "A5"), price: prices.fortrac.MDT150, color: '#00563f' } as ProductsTableLine,
      { label: "Fortrac® 110 MDT", shortTermStrength: 110, A1: this.getAValue(11, "A1"), A2: this.getAValue(11, "A2"), A3: this.getAValue(11, "A3"), A4: this.getAValue(11, "A4"), A5: this.getAValue(11, "A5"), price: prices.fortrac.MDT110, color: '#006600' } as ProductsTableLine,
      { label: "Fortrac® 80 MDT", shortTermStrength: 80, A1: this.getAValue(12, "A1"), A2: this.getAValue(12, "A2"), A3: this.getAValue(12, "A3"), A4: this.getAValue(12, "A4"), A5: this.getAValue(12, "A5"), price: prices.fortrac.MDT80, color: '#087830' } as ProductsTableLine,
      { label: "Fortrac® 55 MDT", shortTermStrength: 55, A1: this.getAValue(13, "A1"), A2: this.getAValue(13, "A2"), A3: this.getAValue(13, "A3"), A4: this.getAValue(13, "A4"), A5: this.getAValue(13, "A5"), price: prices.fortrac.MDT55, color: '#2a8000' } as ProductsTableLine,
      { label: "Fortrac® 35 MDT", shortTermStrength: 35, A1: this.getAValue(14, "A1"), A2: this.getAValue(14, "A2"), A3: this.getAValue(14, "A3"), A4: this.getAValue(14, "A4"), A5: this.getAValue(14, "A5"), price: prices.fortrac.MDT35, color: '#568203' } as ProductsTableLine,
    ];

    this.calculateDesignStrengths();
  }
  
  private calculateDesignStrengths() {
    this.calculatedProducts.forEach(x => {
      if (x.A1 !== 0) {
        x.designStrength = x.shortTermStrength /
          (x.A1 * x.A2 * x.A3 * x.A4 * x.A5 * this.inputModel.materialPartialSafetyFactor);
      }
    });
  }

  private getAValue(productId: number, aValueType:string) :number {
    const line = this.reductionFactorTables.tableLines.find(x => x.id === productId);

    switch (aValueType) {
      case 'A1':
        switch (this.inputModel.designLife) {
          case 1:
            return line.A1_1year;
          case 10:
            return line.A1_10year;
          case 60:
            return line.A1_60year;
          case 120:
            return line.A1_120year;
        }
        break;
      case 'A2':
        switch (this.inputModel.grainSizeDistribution) {
          case 1:
            return line.A2_FineGrain;
          case 2:
            return line.A2_MixGrain;
          case 3:
            return line.A2_CoarseGrain;
        }
        break;
      case 'A3':
        return 1;
      case 'A4':
        if (this.inputModel.petPva === 2) {
          return line.A4_Alkaline;
        }

        switch (this.inputModel.phValueOfFillingSoil) {
          case 1:
            return line.A4_Acid;
          case 2:
            return line.A4_Neutral;
          case 3:
            return line.A4_Alkaline;
        }
        break;
      case 'A5':
        return 1;
    }

    return 0;
  }

  //private getA2Value(productId: number): number {
  //  return 0;
  //}

  //private getA3Value(productId: number): number {
  //  return 0;
  //}

  //private getA4Value(productId: number): number {
  //  return 0;
  //}

  //private getA5Value(productId: number): number {
  //  return 0;
  //}
}
