export enum UnitSystem {
  Metric = 1,
  Imperial = 2
}

export enum UnitType {
  Length = 1,
  Area = 2,
  Volume = 3,
  Pressure = 4
}

export enum MetricToImpFactors {
  Foot = 3.2843,
  SquareFoot = 10.7643,
  CubicFoot = 35.3147,
  Pressure = 0.1451,
  Weight = 6.366
}

export enum ImpToMetricFactors {
  Meter = 0.3045,
  SquareMeter = 0.0929,
  CubicMeter = 0.0283,
  Pressure = 6.8940,
  Weight = 0.15709
}
