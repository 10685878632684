<form [formGroup]="form">
  <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false" (navChange)="onNavChange($event)">
    <li [ngbNavItem]="1">
      <a ngbNavLink>{{'input.tabGeneralLabel' | translate}}</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col-5">
            <div class="card">
              <!--<h3 class="card-header">Allgemein</h3>-->
              <div class="card-body">

                <formly-form [form]="inputMainFieldsForm" [fields]="inputMainFields" [model]="inputModel" (modelChange)="modelChange($event)"></formly-form>

                <button *ngIf="isCreateButtonVisible" [disabled]="isCreatingButtonProcessing" class="btn btn-primary" (click)="createNewCalculation(template)">
                  <span *ngIf="isCreatingButtonProcessing" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  {{!!inputModel.id ? ('input.saveDialogUpdateButtonLabel' | translate) : ('input.sendButtonLabel' | translate)}}
                </button>

                <div style="margin-top: 16px; margin-left: -16px;" class="col-10">
                  <div class="w-12 d-inline-block">
                    <label for="code-entry">{{'code' | translate}}</label>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" [formControl]="currentCalculationCode" id="code-entry" />
                      <div class="input-group-append">
                        <button [disabled]="codeLoaded" class="btn btn-outline-secondary" (click)="load()" type="button">{{'load' | translate}}</button>
                        <button [disabled]="!codeLoaded" class="btn btn-outline-secondary" (click)="reset()" type="button">{{'reset' | translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--<button class="btn btn-primary" (click)="showSubsystemSelection(templatesubsystem)">Show SubsystemSelection</button>-->
              </div>
            </div>
          </div>



          <div class="col-5">
            <!--<app-result-view [inputModel]="inputModel"></app-result-view>-->
            <div class="card">
              <div class="card-body">
                <div *ngIf="inputModel.role === userRoles.customer" style="text-align: center">
                  <img width="480" [src]="selectedSystemAndSubSystem | systemToImagePath" />
                  <div *ngIf="inputModel.selectedSystem !== 'Fortrac Panel'">
                    <p><b>{{subSystemName | translate}} </b><button style="margin-left: 12px;" class="btn btn-secondary" (click)="showSubsystemSelection(templatesubsystem)">{{ 'Change' | translate }}</button></p>
                  </div>
                </div>
                <app-overview-chart></app-overview-chart>
              </div>

              <div *ngIf="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin || inputModel.role === userRoles.partner">
                <h3 class="card-header">{{'productLabel' | translate}}</h3>
                <app-product-result [productToSelect]="selectedProductInTable" (productSelected)="productSelected($event)" [productPrices]="productPrices" [showPrices]="inputModel.role !== userRoles.partner"></app-product-result>
              </div>


            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>{{'input.tabSoilParameters' | translate }}</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col- col-5">
            <div class="card">
              <div class="card-body">
                <formly-form [form]="inputSoilParameterForm" [fields]="inputSoilParameter" [model]="inputModel" (modelChange)="modelChange($event)"></formly-form>
              </div>
            </div>
          </div>

          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div *ngIf="inputModel.role === userRoles.customer" style="text-align: center">
                  <img width="480" [src]="selectedSystemAndSubSystem | systemToImagePath" />
                  <div *ngIf="inputModel.selectedSystem !== 'Fortrac Panel'">
                    <p><b>{{subSystemName | translate}} </b><button style="margin-left: 12px;" class="btn btn-secondary" (click)="showSubsystemSelection(templatesubsystem)">{{ 'Change' | translate }}</button></p>
                  </div>
                </div>

                <app-overview-chart></app-overview-chart>
              </div>

              <div *ngIf="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin || inputModel.role === userRoles.partner">
                <h3 class="card-header">{{'productLabel' | translate}}</h3>
                <app-product-result [productToSelect]="selectedProductInTable" (productSelected)="productSelected($event)" [productPrices]="productPrices" [showPrices]="inputModel.role !== userRoles.partner"></app-product-result>
              </div>


            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>{{'input.tabSafetyFactorsLabel' | translate }}</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col- col-5">
            <div class="card">
              <div class="card-body">
                <formly-form [form]="inputSafety" [fields]="inputSafetyFactor" [model]="inputModel" (modelChange)="modelChange($event)"></formly-form>
              </div>
            </div>
          </div>

          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div *ngIf="inputModel.role === userRoles.customer" style="text-align: center">
                  <img width="480" [src]="selectedSystemAndSubSystem | systemToImagePath" />
                  <div *ngIf="inputModel.selectedSystem !== 'Fortrac Panel'">
                    <p><b>{{subSystemName | translate}} </b><button style="margin-left: 12px;" class="btn btn-secondary" (click)="showSubsystemSelection(templatesubsystem)">{{ 'Change' | translate }}</button></p>
                  </div>
                </div>

                <app-overview-chart></app-overview-chart>
              </div>

              <div *ngIf="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin || inputModel.role === userRoles.partner">
                <h3 class="card-header">{{'productLabel' | translate}}</h3>
                <app-product-result [productToSelect]="selectedProductInTable"  (productSelected)="productSelected($event)" [productPrices]="productPrices" [showPrices]="inputModel.role !== userRoles.partner"></app-product-result>
              </div>


            </div>
          </div>
        </div>
      </ng-template>
    </li>
  <li *ngIf="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin" [ngbNavItem]="4">
      <a ngbNavLink>{{'input.tablSysConfigLabel' | translate }}</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col- col-5">
            <div class="card">
              <div class="card-body">
                <formly-form [form]="systemConfig" [fields]="systemConfigFields" [model]="inputModel" (modelChange)="modelChange($event)"></formly-form>
              </div>
            </div>
          </div>

          <div class="col-5">
            <div class="card">
              <div class="card-body">

                <app-overview-chart></app-overview-chart>
              </div>

              <div *ngIf="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin">
                <h3 class="card-header">{{'productLabel' | translate}}</h3>
                <app-product-result [productToSelect]="selectedProductInTable" (productSelected)="productSelected($event)" [productPrices]="productPrices" [showPrices]="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin"></app-product-result>
              </div>


            </div>

          </div>

        </div>

      </ng-template>
    </li>
    <li *ngIf="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin || inputModel.role === userRoles.partner" [ngbNavItem]="5">
      <a ngbNavLink>{{'input.tablGeneralInputAndPartial' | translate }}</a>
      <!--<a ngbNavLink>Advanced settings</a>-->
      <ng-template ngbNavContent>
        <div class="row">

          <div class="col- col-5">
            <div class="card">
              <!--<h3 class="card-header">General Input and Partial Safety Factors</h3>-->
              <div class="card-body">
                <formly-form [form]="generalInput" [fields]="generalInputFields" [model]="inputModel" (modelChange)="modelChange($event)"></formly-form>
              </div>
            </div>
          </div>

          <div class="col-5">
            <div class="card">
              <!--<h3 class="card-header"></h3>-->
              <div class="card-body">
                <div *ngIf="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin || inputModel.role === userRoles.partner" style="text-align: center">
                  <img width="480" [src]="selectedSystemAndSubSystem | systemToImagePath"/>
                </div>
                <app-overview-chart></app-overview-chart>
              </div>

              <div *ngIf="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin || inputModel.role === userRoles.partner">
                <h3 class="card-header">{{'productLabel' | translate}}</h3>
                <app-product-result [productToSelect]="selectedProductInTable" (productSelected)="productSelected($event)" [productPrices]="productPrices" [showPrices]="inputModel.role !== userRoles.partner"></app-product-result>
              </div>


            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <!--<li *ngIf="inputModel.role === 'hueskerInternal'" [ngbNavItem]="6">
    <a ngbNavLink>Geometry and Surcharge</a>
    <ng-template ngbNavContent>
      <div class="row">

        <div class="col-5">
          <div class="card">
            <h3 class="card-header">Geometry</h3>
            <div class="card-body">
              <form [formGroup]="form">
                <formly-form [form]="form" [fields]="geometryFields" [model]="inputModel" (modelChange)="modelChange($event)"></formly-form>
              </form>
            </div>
          </div>
        </div>

        <div class="col-5">
          <div class="card">
            <h3 class="card-header">Surcharge</h3>
            <div class="card-body">
              <form [formGroup]="form">
                <formly-form [form]="form" [fields]="surchargeFields" [model]="inputModel" (modelChange)="modelChange($event)"></formly-form>
              </form>
            </div>
          </div>
        </div>

      </div>
    </ng-template>
  </li>-->

    <li *ngIf="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin" [ngbNavItem]="7">
      <a ngbNavLink>{{'input.tabPricesLabel' | translate }}</a>
      <ng-template ngbNavContent>
        <app-prices></app-prices>
      </ng-template>
    </li>

    <li *ngIf="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin || inputModel.role === userRoles.partner" [ngbNavItem]="8">
      <a ngbNavLink>{{'input.tabResultsLabel' | translate }}</a>
      <ng-template ngbNavContent>
        <br/>
        <button (click)="copyToClipboard()">Copy</button>
        <br/>
        <ag-grid-angular style="width: 1460px; height: 768px;"
                         class="ag-theme-alpine"
                         [rowData]="rowData"
                         [columnDefs]="columnDefs"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </ng-template>
    </li>

    <li *ngIf="inputModel.role === userRoles.admin" [ngbNavItem]="9">
      <a ngbNavLink>{{'input.adminFactorsLabel' | translate }}</a>
      <ng-template ngbNavContent>
        <app-admin-factors></app-admin-factors>
      </ng-template>
    </li>

    <li *ngIf="!isLoggedIn" [ngbNavItem]="10">
      <a ngbNavLink>{{'signIn.signInMenuLabel' | translate}}</a>
    </li>

    <li *ngIf="isLoggedIn" [ngbNavItem]="11">
      <a ngbNavLink>{{'signIn.signOutMenuLabel' | translate}}</a>
    </li>
    <!--<li *ngIf="isLoggedIn" [ngbNavItem]="11">
     <a ngbNavLink>{{'signIn.signOutMenuLabel' | translate}}</a>
    </li>-->
    <!--<li *ngIf="inputModel.role === 'hueskerInternal'" [ngbNavItem]="9">
    <a ngbNavLink>Calculationlog</a>
    <ng-template ngbNavContent>
      <pre>
        {{calcResult | json}}
      </pre>
    </ng-template>
  </li>-->
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>

<ng-template #templatesubsystem>
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{'input.subSystemLabel' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="subSystemModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
  <div class="modal-body">
    <div class="container-fluid">
      <app-sub-system-selection [inputModel]="inputModel" (selectedSubSystemId)="selectedSubSystemId = $event"></app-sub-system-selection>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="setSubsystem()">{{ 'Accept' | translate}}</button>
      <button type="button" class="btn btn-secondary" (click)="subSystemModalRef.hide()" data-dismiss="modal">{{ 'Cancel' | translate}}</button>
    </div>
  </div>
</ng-template>

<ng-template  #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{'input.saveDialogHeadline' | translate}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        {{'input.saveDialogCodeLabel' | translate}}<br />
        <span style="color: blue">{{currentCalculationCode.value}}</span>
      </p>


      <p><b>{{'input.saveDialogPriceIndicationText' | translate}}</b></p>

      <formly-form [form]="contact" [fields]="inputContactData" [model]="inputModel" [options]="contactOptions" (modelChange)="inputContactModelChange()"></formly-form>

      <!--<button class="btn btn-primary" [disabled]="!canSaveConfig" (click)="saveOrUpdateConfig()">Save</button>-->

      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="flexCheckDefault" (change)="dataPrivacyChanged()">
        <label class="form-check-label" for="flexCheckDefault">
          <p>{{'dataPrivacy' | translate }} <a target="_blank" href="{{'dataPrivacyLink' | translate}}">{{'dataPrivacyText' | translate}}</a>{{'dataPrivacyPost' | translate}}</p>
        <p>{{'mandatory' | translate}}</p>
        </label>
      </div>


      <button [disabled]="isCreatingButtonProcessing || !dataPrivacyAccepted || !contact.valid" class="btn btn-primary" (click)="sendEmail()">
        <span *ngIf="isCreatingButtonProcessing" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        {{'input.saveDialogSendButtonLabel' | translate}}
      </button>
      <!--<div class="form-group col-md-8">
    <label class="form-check-label" for="moreInformationNeeded">
      Möchten Sie weiterführende Informationen?
    </label>
    <input class="form-check-input" type="checkbox" [(checked)]="sentMoreInformation" id="moreInformationNeeded">

  </div>
  <div class="form-group col-md-8">
    <label for="name" class="control-label">Line Height</label>
    <input type="text" value='' class="form-control" id="lineHeight">
  </div>-->
    </div>
  </ng-template>
  <!--<div>{{inputModel | json}}</div>-->
  <!--<p><button class="btn btn-primary" (click)="calculate()">Calculate</button></p>-->

</form>
<app-sign-in #signInComponent></app-sign-in>
