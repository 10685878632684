import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-tooltip-wrapper',
  templateUrl: './tooltip-wrapper.component.html',
  
})
export class TooltipWrapperComponent extends FieldWrapper {
  
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent: ViewContainerRef;
}
