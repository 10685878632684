import { FormlyFieldConfig } from "@ngx-formly/core";
import { TranslateService } from "@ngx-translate/core";

export class SignInModel {
  username: string;
  password: string;
  constructor() {

  }

  getFieldConfigs(translate: TranslateService): FormlyFieldConfig[] {
    return [
      {
        key: 'username',
        type: 'input',
        templateOptions: {
          translate: true,
          type: 'text',
          label: 'signIn.input.label.username',
          //placeholder: "signIn.input.placeholder.username",
          required: true,
          attributes: {
            autocomplete: 'off',
          }
        },
        validation: {
          messages: {
            required: (error, field: FormlyFieldConfig) => translate.get('signIn.messages.required.username')
          }
        }
      },
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          translate: true,
          type: 'password',
          label: 'signIn.input.label.password',
          //placeholder: "signIn.input.placeholder.password",
          required: true,
          attributes: {
            autocomplete: 'off',
          }
        },
        validation: {
          messages: {
            required: (error, field: FormlyFieldConfig) => translate.get('signIn.messages.required.password')
          }
        }
      }
    ]
  }
}
