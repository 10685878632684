import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { IPricePosition } from "../calculationModel/IPricePosition";
import { IProductPricedetails } from "../calculationModel/IProductPricedetails";
import { Product } from "../calculationModel/calculationEnums";

@Component({
  selector: 'app-product-result',
  templateUrl: './product-result.component.html',
  styleUrls: ['./product-result.component.css']
})



export class ProductResultComponent implements OnInit {
  @Input()
  set productPrices(val: IProductPricedetails[]) {
    if (!val) {
      this.nature = null;
      this.gabion = null;
      this.block = null;
      this.panel = null;
    } else {
      this.nature = val.find(x => x.product === Product.Nature);
      this.block = val.find(x => x.product === Product.Block);
      this.gabion = val.find(x => x.product === Product.Gabion);
      this.panel = val.find(x => x.product === Product.Panel);
    }
    this.onSelectionChanged(this.selectedProduct);
  }

  @Input()
  set showPrices(val: boolean) {
    this.shouldPricesShown = val;
  }

  @Input()
  set productToSelect(val: string) {
    this.onSelectionChanged(val);
  }

  @Output()
  productSelected = new EventEmitter<string>();

  shouldPricesShown:boolean;
  pricePositions: IPricePosition[];
  nature: IProductPricedetails;
  gabion: IProductPricedetails;
  block: IProductPricedetails;
  panel: IProductPricedetails;
  selectedProduct:string;

  constructor() {
    console.log(this.shouldPricesShown);
  }
  onSelectionChanged(val) {
    if (val === 'nature') {
      this.pricePositions = this.nature.positions;
    } else if (val === 'gabion') {
      this.pricePositions = this.gabion.positions;
    } else if (val === 'block') {
      this.pricePositions = this.block.positions;
    } else if (val === 'panel') {
      this.pricePositions = this.panel.positions;
    } else {
      this.pricePositions = [];
    }

    this.productSelected.emit(val);
    
  }

  getPriceLabel(details: IProductPricedetails): string {
    if (!details) {
      return "";
    }

    if (!details.isValid) {
      return details.errorMessage;
    }

    return details.sumPrice.toString();
  }

  ngOnInit(): void {

  }

}
