<div>
  <table class="table">
    <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">{{'systemLabel' | translate}}</th>
      <th *ngIf="shouldPricesShown" scope="col">{{'indicativePrice' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td><input type="radio" name="selected" [checked]="selectedProduct === 'nature'" (change)="onSelectionChanged('nature')"  ></td>
      <th scope="row">Fortrac Nature</th>
      <td *ngIf="shouldPricesShown">{{getPriceLabel(nature)| number: '1.2-2'}} €</td>
    </tr>
    <tr>
      <td><input type="radio" name="selected" [checked]="selectedProduct === 'gabion'" (change)="onSelectionChanged('gabion')" ></td>
      <th scope="row">Fortrac Gabion</th>
      <td *ngIf="shouldPricesShown">{{getPriceLabel(gabion)| number: '1.2-2'}} €</td>
    </tr>
    <tr>
      <td><input type="radio" name="selected" [checked]="selectedProduct === 'block'" (change)="onSelectionChanged('block')" ></td>
      <th scope="row">Fortrac Block</th>
      <td *ngIf="shouldPricesShown">{{getPriceLabel(block)| number: '1.2-2'}} €</td>
    </tr>
    <tr>
      <td><input type="radio" name="selected" [checked]="selectedProduct === 'panel'" (change)="onSelectionChanged('panel')"></td>
      <th scope="row">Fortrac Panel</th>
      <td *ngIf="shouldPricesShown">{{getPriceLabel(panel)| number: '1.2-2'}} €</td>
    </tr>
    </tbody>
  </table>
  <table>
    <thead>
    <tr>
      <th scope="col">{{ 'Position' | translate }}</th>
      <th scope="col">{{ 'Description' | translate }}</th>
      <th scope="col">{{ 'QuantityPerM2' | translate }}</th>
      <th *ngIf="shouldPricesShown" scope="col">{{ 'PriceEuro' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor='let pos of pricePositions'>
      <td>{{pos.position | translate}}</td>
      <td>{{pos.description | translate}}</td>
      <td>{{pos.quantity | number: '1.2-2'}}</td>
      <td *ngIf="shouldPricesShown">{{pos.price | number: '1.2-2'}} {{ 'posPriceUnit' | translate }}</td>
    </tr>
    </tbody>
  </table>
</div>

