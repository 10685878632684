import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PricesService } from "../prices.service";
import { FormGroup, FormControl  } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { Prices } from "../calculationModel/prices";
import { UserService } from "../user.service";
import { UserRoles } from '../user-roles.config';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.css']
})

export class PricesComponent implements OnInit, AfterViewInit {
  newCalculation = new FormGroup({
    calcName: new FormControl(),
  });

  isNewCalculationButtonBusy:boolean;

  form = new FormGroup({
    selectedCalculation:new FormControl(),
  });
  editPricesFrom = new FormGroup({});

  options: FormlyFormOptions = {};

  availablePrices: Prices[];

  selectedCalculation: Prices = null;

  newCalculationName: string = null;

  private selectedCalculationOrig: Prices = null;

  isAdmin = false;

  fortracProducts: FormlyFieldConfig[] = [
    {
      key: 'fortrac.MDT35',
      type: 'input',
      templateOptions: {
        label: 'MDT 35',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'fortrac.MDT55',
      type: 'input',
      templateOptions: {
        label: 'MDT 55',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'fortrac.MDT80',
      type: 'input',
      templateOptions: {
        label: 'MDT 80',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'fortrac.MDT110',
      type: 'input',
      templateOptions: {
        label: 'MDT 110',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'fortrac.MDT150',
      type: 'input',
      templateOptions: {
        label: 'MDT 150',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'fortrac.MDT200',
      type: 'input',
      templateOptions: {
        label: 'MDT 200',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },

    {
      key: 'fortrac.T35',
      type: 'input',
      templateOptions: {
        label: 'T 35',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'fortrac.T55',
      type: 'input',
      templateOptions: {
        label: 'T 55',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    }, {
      key: 'fortrac.T80',
      type: 'input',
      templateOptions: {
        label: 'T 80',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'fortrac.T110',
      type: 'input',
      templateOptions: {
        label: 'T 110',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'fortrac.T150',
      type: 'input',
      templateOptions: {
        label: 'T 150',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'fortrac.T200',
      type: 'input',
      templateOptions: {
        label: 'T 200',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    ];

  trickleAndSteelLattice: FormlyFieldConfig[] = [
    {
      key: 'trickleProtection.fortrac3D',
      type: 'input',
      templateOptions: {
        label: 'Fortrac 3D [€/m²]',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'trickleProtection._23142Gr',
      type: 'input',
      templateOptions: {
        label: '23142Gr [€/m²]',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'trickleProtection.NonWovenFabric',
      type: 'input',
      templateOptions: {
        translate: true,
        label: 'input.priceListNonWovenLabel',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'steelLattice.steel05',
      type: 'input',
      templateOptions: {
        translate: true,
        label: 'input.priceListSteel05Label',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'steelLattice.steel06',
      type: 'input',
      templateOptions: {
        translate: true,
        label: 'input.priceListSteel06Label',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    }, {
      key: 'steelLattice.galvanized',
      type: 'input',
      templateOptions: {
        translate: true,
        label: 'input.priceListGalvanizedLabel',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },


  ];

  gabions: FormlyFieldConfig[] = [
    {
      key: 'gabion._200x100x100',
      type: 'input',
      templateOptions: {
        label: 'Gabion [€/m²]',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'gabionAnchor.Type1Dach',
      type: 'input',
      templateOptions: {
        label: 'Gabion anchor Type 1 (DACH) [€/m²]',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'gabionAnchor.Type2EMEEA',
      type: 'input',
      templateOptions: {
        label: 'Gabion anchor Type 2 (EMEA) [€/m²]',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
  ];

  blockAndPanel: FormlyFieldConfig[] = [
    {
      key: 'block.inorBloc',
      type: 'input',
      templateOptions: {
        label: 'InorBlock [€/m²]',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'block.allanBlock',
      type: 'input',
      templateOptions: {
        label: 'AllanBlock [€/m²]',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    },
    {
      key: 'panel.passive',
      type: 'input',
      templateOptions: {
        label: 'Panel passive [€/m²]',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isDefault && !model.isAdmin',
      },
    }
    ];

  submit(): void {
    this.priceService.updatePrice(this.selectedCalculation, null);
  }

  createNewCalculation() {

    const newName = this.newCalculation.get('calcName').value as string;

    if (!newName || newName.length < 5) {
      alert("Name of new calculation has to be at least 5 characters.");
    } else {
      this.isNewCalculationButtonBusy = true;
      this.priceService.isNameExisting(newName).subscribe(createRes => {
        if (createRes.isExisting) {
          alert("A price calculation with the same name is already existing. Choose another name.");
        } else {
          this.newCalculation.get('calcName').setValue(null);
          this.priceIdToSet = createRes.id;
          this.priceService.refreshPrices();
        }
      });
    }
  }

  private priceIdToSet:string = null;

  hasCalculationValueChanged:boolean;

  onSelectedCalculationChanged(selectedPrices: Prices) {
    if (!selectedPrices) {
      this.selectedCalculationOrig = null;
      this.selectedCalculation = null;
      return;
    }

    this.selectedCalculation = selectedPrices;

    if (this.selectedCalculation) {
      this.selectedCalculation.isAdmin = this.isAdmin;
    }

    this.selectedCalculationOrig = (JSON.parse(JSON.stringify(selectedPrices)));;
  }

  onPriceChange(): void {
    const areEqual = JSON.stringify(this.selectedCalculationOrig) === JSON.stringify(this.selectedCalculation);

    this.hasCalculationValueChanged = !areEqual;
  }
  
  private setIsAdmin(role:number): void {
    this.isAdmin = role === UserRoles.admin;

    if (this.selectedCalculation) {
      this.selectedCalculation.isAdmin = this.isAdmin;
    }
  }


  constructor(public priceService: PricesService, userService:UserService) {
    userService.currentRole.subscribe(role => {
      this.setIsAdmin(role);
    });

    this.setIsAdmin(userService.currentRole.value);

    priceService.prices.subscribe(prices => {
      this.availablePrices = prices;

      const priceToSet = this.priceIdToSet
        ? this.availablePrices.find(x => x.id === this.priceIdToSet)
        : this.availablePrices.find(x => x.isDefault);

        this.form.get('selectedCalculation').setValue(priceToSet);
      this.onSelectedCalculationChanged(priceToSet);
      this.priceIdToSet = null;
    });

  }

  deleteSelectedCalculation() {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    
  }
}
