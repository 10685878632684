export class Prices {
  id: string;
  isDefault: boolean;
  name: string;
  isAdmin:boolean;

  fortrac = {
    MDT35: 0,
    MDT55: 0,
    MDT80: 0,
    MDT110: 0,
    MDT150: 0,
    MDT200: 0,

    T35: 0,
    T55: 0,
    T80: 0,
    T110: 0,
    T150: 0,
    T200: 0,
  };

  trickleProtection = {
    fortrac3D: 2.2,
    _23142Gr: 1.8,
    NonWovenFabric: 0.96
  };

  steelLattice = {
    steel06: 11.5,
    steel05: 13.8,
    galvanized: 30
  };

  gabion = {
    _200x100x100: 65
  };

  gabionAnchor = {
    Type1Dach: 70,
    Type2EMEEA: 50
  };

  block = {
    inorBloc: 95,
    allanBlock: 100
  };

  panel = {
    passive: 120
  }
}
