import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ISubSystemSelectionModel } from "../calculationModel/ISubSystemSelectionModel";
import { ISubSystemSelectionModelEntry } from "../calculationModel/ISubSystemSelectionModelEntry";
import { Product } from "../calculationModel/calculationEnums";
import { InputModel } from "../calculationModel/inputModel";
import { SubSystemMapper } from "../calculationModel/subSystemMapper";

@Component({
  selector: 'app-sub-system-selection',
  templateUrl: './sub-system-selection.component.html',
  styleUrls: ['./sub-system-selection.component.css']
})
export class SubSystemSelectionComponent implements OnInit {

  selectedSubsystem: any;

  private getBlockSubsystems() {
    const hollow = {
      id: 7,
      isSelected: false,
      bulletPoints: [
        "systemModels.FortracBlock.1",
        "systemModels.FortracBlock.2",
        "systemModels.FortracBlock.3",
        "systemModels.FortracBlock.4",
        "systemModels.FortracBlock.5",
      ],
      name: "systemModels.FortracBlock.hollowBlockFacing",
      image: "HUE_System_Block_Hochkammerstein.png"
    } as ISubSystemSelectionModelEntry;

    const solid = {
      id: 8,
      isSelected: false,
      bulletPoints: [
        "systemModels.FortracBlock.6",
        "systemModels.FortracBlock.7",
        "systemModels.FortracBlock.8"
      ],
      name: "systemModels.FortracBlock.solidBlockFacing",
      image: "HUE_System_Block_Vollsteinen.png"
      
    } as ISubSystemSelectionModelEntry;

    return {
      name: "Fortrac Block",
      entries: [hollow, solid]
    }
  }

  private getGabionSubsystem() {
    const tieRod = {
      id: 4,
      isSelected: false,
      bulletPoints: [
        "systemModels.FortracGabion.1",
        "systemModels.FortracGabion.2",
        "systemModels.FortracGabion.3",
        "systemModels.FortracGabion.4"
      ],
      name: "systemModels.FortracGabion.tierodSystem",
      image: "HUE_System_Gabion_Ankerstabsystem.png"
    } as ISubSystemSelectionModelEntry;

    const halfGabion = {
      id: 5,
      isSelected: false,
      bulletPoints: [
        "systemModels.FortracGabion.5",
        "systemModels.FortracGabion.6",
        "systemModels.FortracGabion.7"
      ],
      name: "systemModels.FortracGabion.halfGabionFacing",
      image: "HUE_System_Gabion_Halb.png",
    } as ISubSystemSelectionModelEntry;

    const fullGabion = {
      id: 6,
      isSelected: false,
      bulletPoints: [
        "systemModels.FortracGabion.8",
        "systemModels.FortracGabion.9",
        "systemModels.FortracGabion.10",
        "systemModels.FortracGabion.11"
      ],
      name: "systemModels.FortracGabion.fullgabionFacing",
      image: "HUE_System_Gabion_Voll.png"
    } as ISubSystemSelectionModelEntry;
    return {
      name: "Fortrac Gabion",
      entries: [tieRod, halfGabion, fullGabion]
    }
  }

  private getNatureSubsystems() {
    const integratedFramework = {
      id: 1,
      isSelected: false,
      bulletPoints: [
        "systemModels.FortracNature.1",
        "systemModels.FortracNature.2",
        "systemModels.FortracNature.3"
      ],
      name: "systemModels.FortracNature.withIntegratedCorrosionProtectedFormwork",
      image: "HUE_System_Nature_integrierte_Schalung.png"
  } as ISubSystemSelectionModelEntry;

    const withoutIntegratedFramework = {
      id: 2,
      isSelected: false,
      bulletPoints: [
        "systemModels.FortracNature.7",
        "systemModels.FortracNature.8",
        "systemModels.FortracNature.9"
      ],
      name: "systemModels.FortracNature.withoutIntegratedFormWork",
      image: "HUE_System_Nature_ohne_Schalung.png"
    } as ISubSystemSelectionModelEntry;

    const integratedLostFramework = {
      id: 3,
      isSelected: false,
      bulletPoints: [
        "systemModels.FortracNature.4",
        "systemModels.FortracNature.5",
        "systemModels.FortracNature.6"
      ],
      name: "systemModels.FortracNature.withIntegratedLosFormwork",
      image: "HUE_System_Nature_verlorene_Schalung.png"
    } as ISubSystemSelectionModelEntry;

    return {
      name: "Fortrac Nature",
      entries:[integratedFramework, integratedLostFramework, withoutIntegratedFramework]
    }
  }

  constructor() {
    
  }

  selectSubsystem($event): void {
    console.log($event);
    this.selectedSubSystemId.emit($event);
    this.selectedSubId = $event;
    
  }

  selectedSubId: number;

  //@Input() system: Product;
  @Input() inputModel: InputModel;
  @Output() selectedSubSystemId = new EventEmitter<number>();

  ngOnInit(): void {
    if (this.inputModel.selectedSystem === Product.Gabion) {
      this.selectedSubsystem = this.getGabionSubsystem();
      this.selectedSubId = SubSystemMapper.getGabionSubsystemId(this.inputModel.systemModelGabion);
    } else if (this.inputModel.selectedSystem === Product.Block) {
      this.selectedSubsystem = this.getBlockSubsystems();
      this.selectedSubId = SubSystemMapper.getBlockSubsystemId(this.inputModel.systemModelBlock);
    } else if (this.inputModel.selectedSystem === Product.Nature) {
      this.selectedSubsystem = this.getNatureSubsystems();
      this.selectedSubId = SubSystemMapper.getNatureSubsystemId(this.inputModel.systemModelNature);
    }
    this.selectedSubSystemId.emit(this.selectedSubId);
  }


}
