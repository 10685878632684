export enum NatureSystemModel {
  IntegratedCorrosinProtectedFormWork = "integrated corrosion-protected formwork",
  IntegratedLostFormwork = "integrated lost formwork",
  WithoutIntegratedFormWork = "without integrated formwork"
}

export enum NatureSeedingMethod {
  NotNecessary = "Not necessary",
  HydroSeeding = 'Hydroseeding',
  Conventional =  'Conventional'
}

export enum GabionSystemModel {
  TieRodSystem = 'Tie rod system',
  HalfGabionFacing = 'Half-Gabion facing',
  FullGabionFacing = 'Full Gabion facing',
}

export enum BlockOptions{
  HollowBlockFacing = 'Hollow block facing',
  SolidBlockFacing = 'Solid block facing'
}

export enum PricePositionLabel {
  Geogrid = 'Geogrid',
  Steel = 'Steel',
  ErosionProtection = 'Erosion Protection',
  GabionBasket = 'Gabion Basket',
  SteelElements = 'Steel elements',
  Block = 'Block',
  ConcretePanels = 'Concrete Panels',
}

export enum Product {
  Nature = 'Fortrac Nature',
  Gabion = 'Fortrac Gabion',
  Block = 'Fortrac Block',
  Panel = 'Fortrac Panel'
}

export enum GabionConstructionMethod {
  IntegratedLostFormwork = 'integrated lost formwork',
  WithoutIntegratedFormwork = 'without integrated formwork'
}

export enum GabionSubsystem {
  T1Dach = 'T1 DACH',
  T2EEMEA = 'T2 EEMEA'
}
