import { ReductionFactorTableLine } from "./reductionFactorTableLine";

export class ReductionFactorTables {
  tableLines: Array<ReductionFactorTableLine>;

  constructor() {
    this.tableLines = [
      { id: 1, label:"R 300/50-30 T",  strength: 300, A1_1year:1.39, A1_10year: 1.45, A1_60year: 1.5, A1_120year: 1.52, A2_FineGrain: 1.01, A2_MixGrain:1.02, A2_CoarseGrain: 1.07, A3: 1, A4_Acid: 1.15, A4_Neutral:1.03, A4_Alkaline:1.1, A5: 1 } as ReductionFactorTableLine,
      { id: 2, label:"Fortrac® 200 T", strength: 200, A1_1year:1.39, A1_10year: 1.45, A1_60year: 1.5, A1_120year: 1.52, A2_FineGrain: 1.01, A2_MixGrain:1.02, A2_CoarseGrain: 1.07, A3: 1, A4_Acid: 1.15, A4_Neutral:1.03, A4_Alkaline:1.1, A5: 1 } as ReductionFactorTableLine,
      { id: 3, label:"Fortrac® 150 T", strength: 150, A1_1year:1.39, A1_10year: 1.45, A1_60year: 1.5, A1_120year: 1.52, A2_FineGrain: 1.01, A2_MixGrain:1.02, A2_CoarseGrain: 1.07, A3: 1, A4_Acid: 1.15, A4_Neutral:1.03, A4_Alkaline:1.1, A5: 1 } as ReductionFactorTableLine,
      { id: 4, label:"Fortrac® 110 T", strength: 110, A1_1year:1.39, A1_10year: 1.45, A1_60year: 1.5, A1_120year: 1.52, A2_FineGrain: 1.01, A2_MixGrain:1.02, A2_CoarseGrain: 1.07, A3: 1, A4_Acid: 1.15, A4_Neutral:1.03, A4_Alkaline:1.1, A5: 1 } as ReductionFactorTableLine,
      { id: 5, label:"Fortrac® 80 T",  strength: 80,  A1_1year:1.39, A1_10year: 1.45, A1_60year: 1.5, A1_120year: 1.52, A2_FineGrain: 1.01, A2_MixGrain:1.02, A2_CoarseGrain: 1.07, A3: 1, A4_Acid: 1.15, A4_Neutral:1.03, A4_Alkaline:1.1, A5: 1 } as ReductionFactorTableLine,
      { id: 6, label:"Fortrac® 55 T",  strength: 55,  A1_1year:1.39, A1_10year: 1.45, A1_60year: 1.5, A1_120year: 1.52, A2_FineGrain: 1.09, A2_MixGrain:1.11, A2_CoarseGrain: 1.14, A3: 1, A4_Acid: 1.15, A4_Neutral:1.03, A4_Alkaline:1.1, A5: 1 } as ReductionFactorTableLine,
      { id: 7, label: "Fortrac® 35 T", strength: 35,  A1_1year: 1.39, A1_10year: 1.45, A1_60year: 1.5, A1_120year: 1.52, A2_FineGrain: 1.10, A2_MixGrain: 1.12, A2_CoarseGrain: 1.20, A3: 1, A4_Acid: 1.15, A4_Neutral: 1.03, A4_Alkaline: 1.1, A5: 1 } as ReductionFactorTableLine,


      { id: 8, label: "R 300/30-30 MPT", strength: 300, A1_1year: 1.32, A1_10year: 1.36, A1_60year: 1.39, A1_120year: 1.4, A2_FineGrain: 1.06, A2_MixGrain: 1.06, A2_CoarseGrain: 1.08, A3: 1, A4_Acid: 1.05, A4_Neutral: 1.00, A4_Alkaline: 1.05, A5: 1 } as ReductionFactorTableLine,
      { id: 9, label: "R 200/30-30 MPT", strength: 200, A1_1year: 1.32, A1_10year: 1.36, A1_60year: 1.39, A1_120year: 1.4, A2_FineGrain: 1.06, A2_MixGrain: 1.06, A2_CoarseGrain: 1.11, A3: 1, A4_Acid: 1.05, A4_Neutral: 1.00, A4_Alkaline: 1.05, A5: 1 } as ReductionFactorTableLine,
      { id: 10, label: "R 150/30-30 MPT", strength: 150, A1_1year: 1.32, A1_10year: 1.36, A1_60year: 1.39, A1_120year: 1.4, A2_FineGrain: 1.06, A2_MixGrain: 1.06, A2_CoarseGrain: 1.13, A3: 1, A4_Acid: 1.05, A4_Neutral: 1.00, A4_Alkaline: 1.05, A5: 1 } as ReductionFactorTableLine,
      { id: 11, label: "R 110/25-20/30 MPT", strength: 110, A1_1year: 1.32, A1_10year: 1.36, A1_60year: 1.39, A1_120year: 1.4, A2_FineGrain: 1.06, A2_MixGrain: 1.06, A2_CoarseGrain: 1.15, A3: 1, A4_Acid: 1.05, A4_Neutral: 1.00, A4_Alkaline: 1.05, A5: 1 } as ReductionFactorTableLine,
      { id: 12, label: "R 80/25-20/30 MPT", strength: 80, A1_1year: 1.32, A1_10year: 1.36, A1_60year: 1.39, A1_120year: 1.4, A2_FineGrain: 1.16, A2_MixGrain: 1.18, A2_CoarseGrain: 1.25, A3: 1, A4_Acid: 1.05, A4_Neutral: 1.00, A4_Alkaline: 1.05, A5: 1 } as ReductionFactorTableLine,
      { id: 13, label: "55/25-20/30 MPT", strength: 55, A1_1year: 1.32, A1_10year: 1.36, A1_60year: 1.39, A1_120year: 1.4, A2_FineGrain: 1.18, A2_MixGrain: 1.21, A2_CoarseGrain: 1.31, A3: 1, A4_Acid: 1.05, A4_Neutral: 1.00, A4_Alkaline: 1.05, A5: 1 } as ReductionFactorTableLine,
      { id: 14, label: "35/25-20/30 MPT", strength: 35, A1_1year: 1.32, A1_10year: 1.36, A1_60year: 1.39, A1_120year: 1.4, A2_FineGrain: 1.20, A2_MixGrain: 1.25, A2_CoarseGrain: 1.35, A3: 1, A4_Acid: 1.05, A4_Neutral: 1.00, A4_Alkaline: 1.05, A5: 1 } as ReductionFactorTableLine
    ];
  }

}
