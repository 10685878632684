import { InputModel } from "./inputModel";
import { IPriceTableLine } from "./IPriceTableLine";
import { Prices } from "./prices";
import { IProductPricedetails } from "./IProductPricedetails";
import { BlockOptions, PricePositionLabel, Product } from './calculationEnums';
import { IPricePosition } from "./IPricePosition";

export class FortracBlockCalculator {
  constructor(private frontArea, private inputModel: InputModel, private geoGridsSummary: Array<IPriceTableLine>, private prices: Prices) {

  }

  private getGeoGridLine(): IPricePosition {
    const quantity = this.geoGridsSummary.reduce((sum, current) => sum + current.frictionConnecPlus6Perc, 0);
    const price = this.geoGridsSummary.reduce((sum, current) => sum + current.frictionConnecTotal, 0);

    const returnValue = {
      quantity: quantity / this.frontArea,
      price: price / this.frontArea,
      description: "Friction connec.",
      position:PricePositionLabel.Geogrid
    } as IPricePosition;

    return returnValue;
  }

  private getBlockLine(): IPricePosition {
    const isHollowBlock = this.inputModel.systemModelBlock === BlockOptions.HollowBlockFacing;

    const returnValue = {
      quantity: 1,
      description: isHollowBlock ? "h=0.2" : "h=0.125",
      price: isHollowBlock ? this.prices.block.allanBlock : this.prices.block.inorBloc,
      position: PricePositionLabel.Block
    } as IPricePosition;

    return returnValue;
  }

  private getErosionProtection(): IPricePosition {
    const quantity = 0.4 + this.inputModel.metricInputValues.verticalLayerDistance;

    const returnValue = {
      price:quantity * this.prices.trickleProtection.NonWovenFabric,
      quantity: quantity,
      description: "Non woven GRK5",
      position: PricePositionLabel.ErosionProtection
    } as IPricePosition;

    return returnValue;
  }

  private getValidationResult(): [boolean, string] {
    return [true, ""];

    let isValid = false;
    let errorMessage = "";

    if (this.inputModel.metricInputValues.verticalLayerDistance === 0.6) {
      errorMessage = "Please change vertical layer distance to 0.4 or 0.5 and select corresponding System model";
    } else if (this.inputModel.metricInputValues.verticalLayerDistance === 0.4 && this.inputModel.systemModelBlock === BlockOptions.SolidBlockFacing){
      errorMessage = "Please change vertical layer distance to 0.5 for 'Solid block facing'";
    } else if (this.inputModel.metricInputValues.verticalLayerDistance === 0.5 && this.inputModel.systemModelBlock === BlockOptions.HollowBlockFacing){
      errorMessage = "Please change vertical layer distance to 0.4 for 'Hollow block facing'";
    }else{
      isValid = true;
    }
    
    return [isValid, errorMessage];
  }

  calculate(): IProductPricedetails {
    const details = {
      product: Product.Block,
    } as IProductPricedetails;

    const res = this.getValidationResult();

    if(!res[0]){
      details.errorMessage = res[1];
      details.isValid = false;
      details.positions = [];
      return details;
    }

    const geoGrid = this.getGeoGridLine();
    const erosion = this.getErosionProtection();
    const block = this.getBlockLine();

    const positions = [geoGrid, erosion, block];

    details.positions = positions;
    details.isValid = true;
    details.sumPrice = positions.reduce((sum, current) => sum + current.price, 0);


    return details;
  }
}
