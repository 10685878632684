<div class="card">
  <div class="card-body">
    <div *ngIf="inputModel.role === userRoles.customer" style="text-align: center">
      <img width="480" [src]="inputModel.selectedSystem | systemToImagePath" />
      <div *ngIf="inputModel.selectedSystem !== 'Fortrac Panel'">
        <!--<p><b>{{getSubsystemName()}} </b><button style="margin-left: 12px;" class="btn btn-secondary" (click)="showSubSystemSelectionRequested.emit()">Change</button></p>-->
      </div>
    </div>

    <app-overview-chart></app-overview-chart>
  </div>

  <div *ngIf="inputModel.role === userRoles.hueskerInternal || inputModel.role === userRoles.admin || inputModel.role === userRoles.partner">
    <h3 class="card-header">Produkt</h3>
    <app-product-result [productPrices]="productPrices"></app-product-result>
  </div>
</div>
