<ng-template #signInTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'signIn.title' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="errorMsg !== undefined && errorMsg !== ''" class="alert alert-danger text-center">{{errorMsg}}</div>
    <form [formGroup]="signInForm" (ngSubmit)="signInHandler($event)">
      <formly-form [form]="signInForm" [options]="formOptions" [fields]="signInFields" [model]="signInModel">
        <button class="btn btn-primary" type="submit" [disabled]="!signInForm.valid">
          <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          {{'signIn.submitBtnLabel' | translate}}
        </button>
      </formly-form>
    </form>
  </div>
</ng-template>
