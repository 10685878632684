  <div *ngFor="let item of selectedSubsystem.entries" class="row  row-bordered">
    <!--<app-sub-system-option [subSystem]="item"></app-sub-system-option>-->
    <div class="col-1">
      <input type="radio" [value]="item.id" [checked]="selectedSubId === item.id" (change)="selectSubsystem(item.id)" />
    </div>
    <div class="col-6">
    <p><b>{{ item.name | translate}}</b></p>
    <ul>
      <li *ngFor="let itm of item.bulletPoints">{{itm | translate}}</li>
    </ul>
    </div>
    <div class="col-4">
      <img width="420" [src]="('./assets/' + item.image)" />
    </div>
  </div>

  <!--<ng-template ngFor let-item [ngForOf]="selectedSubsystem.entries" let-i="index">
    <div class="row">
      <app-sub-system-option [subSystem]="item"></app-sub-system-option>
    </div>
  </ng-template>-->
