import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { tap, map } from 'rxjs/operators';
import { AdminFactors } from './calculationModel/adminFactors';

@Injectable({
  providedIn: 'root'
})
export class AdminFactorsService {
  //private configurationsUrl = "api/AdminFactors";
  private configurationsUrl = "AdminFactors";

  currentAdminFactors: BehaviorSubject<AdminFactors>;

  refreshAdminFactors():void{
    const queryUrl = `${this.configurationsUrl}`;

    this.http.get(queryUrl).pipe(tap(res =>{

    }),
    map(res =>{
      return res as AdminFactors;
    })).subscribe(adminFactors =>{
      this.currentAdminFactors.next(adminFactors);
    })
  }

  updateAdminFactors(newAdminFactors:AdminFactors):void{
    const putUrl = `${this.configurationsUrl}`;

    this.http.put(putUrl, newAdminFactors)
      .pipe(tap(res =>{
      }),
      map(res =>{
        return res as AdminFactors;
      }))
      .subscribe(res => {
        this.currentAdminFactors.next(res)
      });
  }

  constructor(private http:HttpClient) { 
    this.currentAdminFactors = new BehaviorSubject(null);
    this.refreshAdminFactors();
  }
}
