<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<!--<app-user-input></app-user-input>-->




<!--<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li [ngbNavItem]="0">
    <a ngbNavLink>Benutzeroberfläche</a>
    <ng-template ngbNavContent>
      
    </ng-template>
  </li>
  <li [ngbNavItem]="1">
    <a ngbNavLink>General Input and filling soil</a>
    <ng-template ngbNavContent>
      <div class="row">

        <div class="col- col-5">
          <div class="card">
            <h3 class="card-header">General Input and Partial Safety Factors</h3>
            <div class="card-body">
              <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
                <formly-form [form]="form" [fields]="generalInputFields" [model]="model" (modelChange)="modelChange($event)"></formly-form>
              </form>
            </div>
          </div>
        </div>

        <div class="col-5">
          <div class="card">
            <h3 class="card-header">Filling soil</h3>
            <div class="card-body">
              <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
                <formly-form [form]="form" [fields]="fillingSoilFields" [model]="model" (modelChange)="modelChange($event)"></formly-form>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>Geometry and Surcharge</a>
    <ng-template ngbNavContent>
      <div class="row">

        <div class="col-5">
          <div class="card">
            <h3 class="card-header">Geometry</h3>
            <div class="card-body">
              <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
                <formly-form [form]="form" [fields]="geometryFields" [model]="model" (modelChange)="modelChange($event)"></formly-form>
              </form>
            </div>
          </div>
        </div>

        <div class="col-5">
          <div class="card">
            <h3 class="card-header">Surcharge</h3>
            <div class="card-body">
              <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
                <formly-form [form]="form" [fields]="surchargeFields" [model]="model" (modelChange)="modelChange($event)"></formly-form>
              </form>
            </div>
          </div>
        </div>

      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink>Result</a>
    <ng-template ngbNavContent>
      <ag-grid-angular style="width: 1460px; height: 768px;"
                       class="ag-theme-alpine"
                       [rowData]="rowData"
                       [columnDefs]="columnDefs">
      </ag-grid-angular>
    </ng-template>
  </li>
  <li [ngbNavItem]="4">
    <a ngbNavLink>Calculationlog</a>
    <ng-template ngbNavContent>
      <pre>
        {{calcResult | json}}
      </pre>
    </ng-template>
  </li>


</ul>


<div [ngbNavOutlet]="nav" class="mt-2"></div>-->

<!--<div class="row">

  <div class="col-5">
    <div class="card">
      <h3 class="card-header">General Input and Partial Safety Factors</h3>
      <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
          <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
        </form>
      </div>
    </div>
  </div>

  <div class="col-5">
    <div class="card">
      <h3 class="card-header">Filling soil</h3>
      <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
          <formly-form [form]="form" [fields]="fieldsSecond" [model]="model"></formly-form>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="row">

  <div class="col-5">
    <div class="card">
      <h3 class="card-header">Geometry</h3>
      <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
          <formly-form [form]="form" [fields]="fieldsThird" [model]="model"></formly-form>
        </form>
      </div>
    </div>
  </div>

  <div class="col-5">
    <div class="card">
      <h3 class="card-header">Surcharge</h3>
      <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
          <formly-form [form]="form" [fields]="fieldsFourth" [model]="model"></formly-form>
        </form>
      </div>
    </div>
  </div>

</div>-->


<router-outlet></router-outlet>
